import React from 'react';
import useHistory from "../../../hooks/useHistory";
import QRCode from 'react-qr-code';
import constants from "../../../utils/constants";
import useAuth from "../../../hooks/useAuth";
import useSocket from "../../../hooks/useSocket";

const CustomerPaymentSummaryScreen = props => {
    const history = useHistory();
    const {username} = useAuth();

    const onmessage = (data) => {
        if (!data) return;
        history.push(`/payment/${data}`)
    }

    useSocket({onmessage});

    return (
        <div style={{margin: 16, height: '100%'}} className="center">
            <div id="user-qr-code">
                <QRCode value={username}
                        bgColor={"transparent"}
                        fgColor={constants.mainColor}/>
            </div>
        </div>

    )
}

export default CustomerPaymentSummaryScreen;
