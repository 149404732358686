const localIp = "192.168.1.33";
//const localIp = "192.168.1.33"
const appPort = "5000";

export const appURLs = {
  HTTP_REST_SERVER: {
    development: `http://${localIp}:${appPort}/api`,
    production: "https://api-kuponya.appysode.com/api",
  },
  WS_REST_SERVER: {
    development: `ws://${localIp}:${appPort}`,
    production: "wss://api-kuponya.appysode.com",
  },
  CUSTOMER: {
    development: "http://localhost:3005",
    production: "http://kuponya-app.appysode.com",
  },
  COMPANY: {
    development: "http://localhost:3006",
    production: "http://kuponya-company.appysode.com",
  },
  WEBSITE: {
    development: "http://localhost:3007",
    production: "http://kuponya.appysode.com",
  },
};

const getAppURL = (appname) => {
  const { NODE_ENV } = process.env;
  return appURLs[appname][NODE_ENV];
};

export const APP_NAMES = {
  CUSTOMER: "CUSTOMER",
  COMPANY: "COMPANY",
  WEBSITE: "WEBSITE",
  HTTP_REST_SERVER: "HTTP_REST_SERVER",
  WS_REST_SERVER: "WS_REST_SERVER",
};

const constants = {
  mainColor: "#002171",
  successColor: "green",
  themeMainColor: "#1890ff",
  appHeaderColor:
    "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,95,255,1) 100%)",
  mainColorAlpha: (alpha) => `rgba(0,33,113, ${alpha})`,
  mainDangerColor: "#EF5350",
  REST_SERVER: "/api", //getAppURL(APP_NAMES.HTTP_REST_SERVER),
  WS_SERVER: getAppURL(APP_NAMES.WS_REST_SERVER),

  OrderStatus: {
    Prepare: 0,
    Ready: 1,
  },

  PurchaseStatus: {
    ApprovedOrPaid: -2,
    Any: -1,
    NotApproved: 0,
    Approved: 1,
    Paid: 2,
  },

  CompanyTypes: {
    restaurant: "Restaurant",
    "self-service": "Self Servis",
  },
  CompanyTypeEnums: {
    Restaurant: "restaurant",
    SelfService: "self-service",
  },
};

export const authEndpoints = {
  get:
    process.env.REACT_APP_APP_ID === "CUSTOMER"
      ? "/users/getUserInfo"
      : "/companies/getUserInfo",
  signin:
    process.env.REACT_APP_APP_ID === "CUSTOMER"
      ? "/users/signin"
      : "/companies/signin",
  signup:
    process.env.REACT_APP_APP_ID === "CUSTOMER"
      ? "/users/signup"
      : "/companies/signup",
  forgotPassword:
    process.env.REACT_APP_APP_ID === "CUSTOMER"
      ? "/users/forgot-password"
      : "/companies/forgot-password",
  resetPassword:
    process.env.REACT_APP_APP_ID === "CUSTOMER"
      ? "/users/reset-password"
      : "/companies/reset-password",
};

export const FormLayout = {
  labelCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
  wrapperCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
};

export const TailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { offset: 3, span: 21 },
    md: { offset: 3, span: 21 },
    lg: { offset: 3, span: 21 },
    xl: { offset: 3, span: 21 },
  },
};

export default constants;
