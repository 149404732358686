import React from 'react';
import appStyles from "../../utils/styles";
import AttachmentImage from "../AttachmentImage";
import DescriptionRow from "../DescriptionRow";
import Shimmer from "../Shimmer";
import {Col, Row} from "antd";
import {MailOutlined, PhoneOutlined} from '@ant-design/icons'
import LogoutButton from "../LogoutButton";
import Authorized from "../Authorized";
import {getFirstLetters} from "../../utils/functions";
import ListItem from "../ListItem";

const UserCard = props => {
    const {user, isLoading} = props;
    const {image, email, fullName, phoneNumber, username} = user || {}

    if (!user || isLoading)
        return <Shimmer/>

    return (
        <Row style={{...appStyles.card, padding: 16}}>
            <Col xs={24} sm={24} md={8}>
                <div className="center" style={{height: '100%'}}>
                    <AttachmentImage id={(image || {}).id}
                                     placeholder={getFirstLetters(fullName)}
                                     size={120}
                                     style={{
                                         backgroundColor: '#eee',
                                         overflow: 'hidden',
                                         resizeMode: 'cover',
                                     }}
                    />
                </div>
            </Col>

            <Col xs={24} sm={24} md={16} className="center" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <div style={{marginLeft: 8, width: '100%'}}>
                    <ListItem title={fullName}
                              titleContainerStyle={{margin: 0, padding: 0}}
                              style={{padding: 0}}
                              titleStyle={{margin: 0, fontSize: 18, fontWeight: '500'}}
                    />
                    <div style={{width: '100%'}}>
                        <DescriptionRow titleSpan={2} title={
                            <MailOutlined/>
                        } style={{marginTop: 8}}
                                        description={email}/>
                        <DescriptionRow titleSpan={2} title={
                            <PhoneOutlined/>
                        } style={{marginTop: 8}}
                                        description={phoneNumber}/>
                    </div>
                    <Authorized type="customer-profile" params={{username}}>
                        <LogoutButton style={{marginTop: 8}}/>
                    </Authorized>
                </div>
            </Col>
        </Row>
    )
}

export default UserCard
