import React from 'react';
import AttachmentImage from "../../../components/AttachmentImage";
import PostCard from "../../../components/PostCard";
import constants from "../../../utils/constants";
import DateDescription from "../../../components/DateDescription";
import InfiniteScrollView from "../../../components/InfiniteScroll";
import EmptyResult from "../../../components/EmptyResult";
import PurchaseProductListItem from "../../../components/ListItem/PurchaseProductListItem";
import PurchaseCheckListItem from "../../../components/ListItem/PurchaseCheckListItem";
import {getAddressText} from "../../../utils/functions";
import Shimmer from "../../../components/Shimmer";
import {ShoppingCartOutlined} from '@ant-design/icons'
import useHistory from "../../../hooks/useHistory";

const CustomerPurchases = (props) => {
    const history = useHistory();
    const pushToStoreInfo = id => {
        history.push(`/stores/${id}`);
    }

    return (
        <InfiniteScrollView
            endpoint={`/users/purchases`}
            shimmer={({...e}) => <div style={{margin: 16}} {...e}>
                <Shimmer isLoading style={{marginTop: 8}}/>
            </div>}
            render={(item, index, pageProps) => {
                return (
                    <PostCard
                        key={index}
                        style={{marginTop: 8}}
                        avatar={<AttachmentImage id={(item.store.company.image || {}).id} size={40}/>}
                        title={item.store.company.name}
                        subtitle={getAddressText(item.store.address)}
                        onHeaderClick={() => pushToStoreInfo(item.store.id)}
                        description={<DateDescription date={item.createdDate}/>}>
                        {item.purchaseProducts.map((purchaseProduct, sindex) => {
                            return (
                                <PurchaseProductListItem purchaseProduct={purchaseProduct} key={sindex}/>
                            )
                        })}
                        <PurchaseCheckListItem purchaseProducts={item.purchaseProducts}/>
                    </PostCard>
                )
            }}
            empty={<EmptyResult icon={<ShoppingCartOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                title={"Henüz alışveriş yapmadınız.\n Kupon kazanmak için hemen alışveriş yapmaya başlayın!"}
            />}
        />
    )
}

export default CustomerPurchases
