import React from "react";
import useHistory from "../../hooks/useHistory";
import {Button, Form, Input} from "antd";
import PostCard from "../../components/PostCard";
import useApi from "../../hooks/useApi";
import {authEndpoints} from "../../utils/constants";

const ForgotMyPasswordCard = props => {
    const history = useHistory();
    const {fetching, load} = useApi();

    const pushToSignIn = () => {
        history.push("/sign/")
    }

    const onSendResetMail = (values) => {
        const {email} = values;
        load({
            endpoint: authEndpoints.forgotPassword,
            method: "POST",
            params: {
                email
            },
            onSuccess: pushToSignIn
        })
    }

    return (
        <PostCard style={{
            width: 400,
        }} childrenContainerStyle={{padding: 32}}>
            <h1 style={{margin: 0}}>Parolamı Sıfırla</h1>
            <Form onFinish={onSendResetMail} style={{marginTop: 16}}>
                <Form.Item label="E-posta" name="email">
                    <Input placeholder="email@example.com"/>
                </Form.Item>
                <div style={{marginTop: 16}}>
                    <div className="spread-horizontally">
                        <Button type="link"
                                onClick={pushToSignIn}
                                style={{width: 'calc(50% - 8px)'}}>
                            Geri
                        </Button>
                        <Button type="primary"
                                loading={fetching}
                                htmlType="submit"
                                style={{width: 'calc(50% - 8px)'}}>
                            Parolamı Sıfırla
                        </Button>
                    </div>
                </div>
            </Form>
        </PostCard>
    )
}

export default ForgotMyPasswordCard;
