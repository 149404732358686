import React, {useEffect} from 'react';
import useApi from "../../../hooks/useApi";
import Header from "../../../components/Header";
import StoreCard from "../../../components/StoreCard";
import useHistory from "../../../hooks/useHistory";

const WorkPlaces = (props) => {
    const {username} = props;
    const history = useHistory();

    const workPlacesApi = useApi({
        endpoint: `/users/workplaces/1/5`,
        initial: true,
        initialValue: [],
    })

    const {load, response, fetched} = workPlacesApi;

    useEffect(() => {
        load()
    }, [load, username])

    const pushToAllWorkPlaces = () => {
    }

    const pushToStore = ({id}) => {
        history.push(`/stores/${id}`)
    }

    if (!((response.data || {}).results || []).length)
        return null

    return (
        <div>
            <Header title="İş Yerlerim"
                    extra={<span/>
                        // <Button type="link" onClick={pushToAllWorkPlaces}>
                        //     Tümünü Gör
                        // </Button>
                    }
            />
            {workPlacesApi.response.data.results.map((workPlace, index) => {
                return (
                    <WorkPlaceCard onHeaderClick={() => pushToStore(workPlace)}
                                   key={index}
                                   workPlace={workPlace}
                                   isLoading={!fetched}
                    />
                )
            })}
        </div>
    )
}

const WorkPlaceCard = props => {
    const {workPlace, onHeaderClick} = props;

    return <StoreCard store={workPlace} onHeaderClick={onHeaderClick}/>
}

export default WorkPlaces
