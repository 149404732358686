import React from 'react';
import Profile from "./Profile";
import Stores from "./Stores";
import {Col, Row} from "antd";
import PostCard from "../../../components/PostCard";
import Header from "../../../components/Header";
import {CompanyFavouriteProduct} from "../../Dashboard/Company";
import useAuth from "../../../hooks/useAuth";

const CompanyProfileScreen = props => {
    const {match: {params}} = props;
    const {company_id: propsId} = params || {};
    const company = useAuth();
    const {id: stateId} = company || {}
    const id = propsId || stateId

    return (
        <Row gutter={8} style={{padding: 16}}>
            <Col xs={24} sm={24} md={12}>
                <Profile id={id}/>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <PostCard title={<Header title="Favori Ürünler"/>}
                          titleContainerStyle={{margin: 0, padding: 0}}
                          headerStyle={{margin: 0, padding: 0}}
                >
                    <CompanyFavouriteProduct id={id}/>
                </PostCard>
            </Col>
            <Col xs={24} sm={24} md={24}>
                <Stores id={id}/>
            </Col>
        </Row>
    )
}

export default CompanyProfileScreen
