import {useCallback, useContext} from 'react';
import {StoreContext} from "../store";
import useLocalStorage from "./useLocalStorage";

const useStore = () => {
    const [state, dispatch] = useContext(StoreContext);
    const {setItem: setLocalStore} = useLocalStorage("store");
    const {setItem: setLocalCompany} = useLocalStorage("company");
    const {store} = state || {};

    const setStore = useCallback((data) => {
        dispatch({type: "set-store", payload: {data}})
        setLocalStore(data)
        if (data.company) {
            dispatch({type: "set-company", payload: {data: data.company}})
            setLocalCompany(data.company)
        } else {
            dispatch({type: "deactivate-company", payload: {}})
            setLocalCompany({})
        }
    }, [dispatch, setLocalCompany, setLocalStore])

    const logout = useCallback(() => {
        dispatch({type: "deactivate-store", payload: {}})
        setLocalStore({})
    }, [dispatch, setLocalStore])

    return {...store, setStore, logout}
}

export default useStore;
