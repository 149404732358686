import React, {useEffect} from 'react';
import Button from "../../../components/Button";
import constants from "../../../utils/constants";
import useApi from "../../../hooks/useApi";
import EmptyResult from "../../../components/EmptyResult";
import Header from "../../../components/Header";
import Authorized from "../../../components/Authorized";
import Shimmer from "../../../components/Shimmer";
import useAuthorize from "../../../hooks/useAuthorize";
import useHistory from "../../../hooks/useHistory";
import {PlusOutlined, ShopOutlined} from '@ant-design/icons'
import {Row} from "antd";
import ProductGridList from "../../../components/ProductGridList";
import useStore from "../../../hooks/useStore";
import useAuth from "../../../hooks/useAuth";

const CategoryScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const {company_id: propsId} = params || {}
    const company = useAuth();
    const {id: stateCompanyId} = company
    const {company: storeCompany} = useStore()
    const stateId = stateCompanyId || (storeCompany || {}).id || {}
    const companyId = propsId || stateId;
    const {canEditCompanyProfile} = useAuthorize();

    const {fetched, load, response, firstTimeFetched} = useApi()

    useEffect(() => {
        if (companyId)
            load({
                endpoint: `/companies/${companyId}/categories`,
            })
    }, [load, companyId])

    const onCategoryAdd = (category) => {
        if (category) {
            history.push(`/menu/categories/${category.id}/edit`)
        } else {
            history.push("/menu/categories/new")
        }
    }

    const onProductAdd = (category) => {
        history.push(`/menu/categories/${category.id}/products/new`)
    }

    const pushToCategoryMoveScreen = ({id: categoryId}) => {
        history.push(`/menu/categories/${categoryId}/move`)
    }

    const hideEmptyCateagoriesFromCustomers = (category) => {
        return canEditCompanyProfile || category.productsCount
    }

    const data = (response.data || []).filter(hideEmptyCateagoriesFromCustomers)
    const hasData = !!data.length;

    return (
        <div>
            <Authorized type="company-profile" params={{company: {...company, ...storeCompany}}}>
                <Button icon={<PlusOutlined style={{fontSize: 32}}/>}
                        shape="circle"
                        type="primary"
                        style={{
                            width: 64,
                            height: 64,
                            position: 'fixed',
                            bottom: 64,
                            right: 16,
                            zIndex: 3,
                        }}
                        onClick={() => onCategoryAdd()}
                />
            </Authorized>
            <div>
                <div style={{marginHorizontal: 16, paddingBottom: 80}}>
                    {
                        !firstTimeFetched ?
                            <Shimmer isLoading/>
                            : fetched && !hasData ?
                            <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                         title={"Henüz menünüzde kategori bulunmamaktadır.\n Kategori eklemek için sağ alttaki \"+\" işaretine dokunun!"}
                            /> :
                            data.map((item, index) => {
                                const {id, name} = item;
                                return (
                                    <div key={index}>
                                        <Header title={name}
                                                extra={
                                                    <Authorized type="company-profile"
                                                                params={{company: {...company, ...storeCompany}}}>
                                                        <Row>
                                                            <Button type="link"
                                                                    onClick={() => onProductAdd(item)}>
                                                                Yeni
                                                            </Button>
                                                            {
                                                                id === ({...company, ...storeCompany}).unCategorizedCategoryId ?
                                                                    null :
                                                                    <Button type="link"
                                                                            onClick={() => onCategoryAdd(item)}>
                                                                        Düzenle
                                                                    </Button>
                                                            }
                                                            <Button type="link"
                                                                    onClick={() => pushToCategoryMoveScreen(item)}>
                                                                Ekle
                                                            </Button>
                                                        </Row>
                                                    </Authorized>
                                                }
                                        />
                                        <ProductGridList parentId={id}
                                                         type="category"
                                                         history={history}
                                                         refresh={fetched}
                                                         ref={null}
                                                         company={{...company, ...storeCompany}}
                                        />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>

        </div>
    )
}

export default CategoryScreen
