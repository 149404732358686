import React, {useCallback, useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import SignUpCard from "./SignUp";
import ForgotMyPasswordCard from "./ForgotMyPassword";
import SignInCard from "./SignIn";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import useHistory from "../../hooks/useHistory";
import FacebookSignInLogo from '../../assets/images/facebook.png'
import GoogleSignInLogo from '../../assets/images/google.png'
import ResetPasswordCard from "./ResetPassword";

const Login = (props) => {
    return (
        <div style={{
            width: '100%',
            minHeight: '100vh',
            background: " linear-gradient(to right, #4facfe 0%, #00f2fe 100%)"
        }}
             className="center">
            <Switch>
                <Route exact path="/sign/up" component={SignUpCard}/>
                <Route exact path="/sign/forgot-password" component={ForgotMyPasswordCard}/>
                <Route exact path="/sign/reset-password/:activation_key" component={ResetPasswordCard}/>
                <Route exact path={"/sign*"} component={SignInCard}/>
            </Switch>
        </div>
    );
}

export const FacebookSignIn = props => {
    const {login} = useAuth()
    const {setItem: setToken} = useLocalStorage("token")
    const {load} = useApi()
    const history = useHistory()

    const onSuccess = useCallback((response) => {
        console.log("sign in success", response);
        history.push("/")
        setToken(response.data.token)
        login(response.data)
    }, [history, login, setToken])

    const statusChangeCallback = useCallback((response) => {
        console.log("response", response);
        if (response) {
            if (response.authResponse) {
                const {userID, accessToken} = response.authResponse;
                load({
                    endpoint: `/users/signin-facebook`,
                    method: "POST",
                    params: {
                        userID,
                        accessToken
                    },
                    onSuccess
                })

                window.FB.api(`/me`, "GET", {"fields": "id,name,email,first_name,last_name,picture.type(large)"}, function (response) {
                    if (response && !response.error) {
                        /* handle the result */
                        console.log("api response", response)
                    } else {
                        console.log("api error", response)
                    }
                })
            }
        }
    }, [load, onSuccess])

    const checkLoginState = useCallback(() => {
        const FB = window.FB;
        if (!FB) return;
        FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
        });
    }, [statusChangeCallback])

    useEffect(() => {
        checkLoginState()
    }, [checkLoginState])

    const fb_login = () => {
        const FB = window.FB;
        if (!FB) return;
        FB.login(function (response) {
            statusChangeCallback(response)
        }, {
            scope: 'public_profile,email'
        });
    }

    return (
        <div id="facebook-signin-button" onClick={fb_login}>
            <img src={FacebookSignInLogo} alt="Facebook Sign In" height={32} width={32}/>
        </div>

    )
}

export const GoogleSignIn = () => {
    const {login} = useAuth()
    const {setItem: setToken} = useLocalStorage("token")
    const {load} = useApi()
    const history = useHistory()

    const onSuccess = useCallback((response) => {
        history.push("/")
        setToken(response.data.token)
        login(response.data)
    }, [history, setToken, login])

    const onGoogleSignin = useCallback((googleUser) => {
        if (!googleUser) return;
        // Useful data for your client-side scripts:
        const profile = googleUser.getBasicProfile();
        const firstName = profile.getGivenName();
        const lastName = profile.getFamilyName();
        const imageURL = profile.getImageUrl();
        const username = profile.getEmail();

        console.log("ID: " + profile.getId()); // Don't send this directly to your server!
        console.log('Full Name: ' + profile.getName());
        console.log('Given Name: ' + firstName);
        console.log('Family Name: ' + lastName);
        console.log("Image URL: " + imageURL);
        console.log("Email: " + username);

        // The ID token you need to pass to your backend:
        var authResponse = googleUser.getAuthResponse()
        const token = authResponse.id_token;
        //console.log("ID Token: " + token, authResponse);

        const params = {
            firstName,
            lastName,
            imageURL,
            username,
            token,
        }

        load({
            endpoint: `/users/signin-google`,
            params,
            onSuccess
        })
    }, [load, onSuccess])

    const attachSignin = useCallback((element) => {
        const auth2 = window.auth2;
        auth2.attachClickHandler(element, {},
            function (googleUser) {
                onGoogleSignin(googleUser)
            }, function (error) {
                alert(JSON.stringify(error, undefined, 2));
            });
    }, [onGoogleSignin])

    useEffect(() => {
        const gapi = window.gapi;
        gapi.load('auth2', function () {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            window.auth2 = gapi.auth2.init({
                client_id: '644667454029-28sf9vo2tgdkdh5p9581r0krmomtg8cb.apps.googleusercontent.com',
            });
            const signInButton = document.getElementById('google-signin-button')
            if (signInButton)
                attachSignin(signInButton);
        });
    }, [attachSignin, onGoogleSignin])


    return (
        <div id="google-signin-button">
            <img src={GoogleSignInLogo} alt="Facebook Sign In" height={32} width={32}/>
        </div>
    )

    //return <div className="g-signin2" data-onsuccess="onGoogleSignin" data-theme="dark"/>

}


export default Login;
