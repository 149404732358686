import React, {useEffect} from "react";
import useApi from "../../hooks/useApi";
import StoreCard from "./index";

const StoreCardLoader = props => {
    const {id} = props;
    const {fetching, response, load} = useApi();

    useEffect(() => {
        load({
            endpoint: `/stores/${id}`
        })
    }, [id, load])

    return <StoreCard store={response.data}
                      isLoading={fetching}
    />
}

export default StoreCardLoader
