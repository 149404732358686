import React from 'react';
import useCompany from "../../../hooks/useCompany";
import constants from "../../../utils/constants";
import SelfServiceDashboard from "./SelfService";
import RestaurantDashboard from "./Restaurant";

const StoreDashboard = props => {
    const {companyType} = useCompany();
    if (companyType === constants.CompanyTypeEnums.SelfService)
        return <SelfServiceDashboard {...props}/>
    return <RestaurantDashboard {...props}/>
}

export default StoreDashboard;
