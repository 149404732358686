import React, {useEffect} from 'react';
import useApi from "../../../hooks/useApi";
import StoreCard from "../../../components/StoreCard";
import Header from "../../../components/Header";

const Invitations = (props) => {
    const {username} = props;

    const invitationsApi = useApi({
        endpoint: `/users/invitations`,
        initialValue: [],
    })

    const {response, load} = invitationsApi;

    useEffect(() => {
        load()
    }, [load, username])

    if (!(invitationsApi.response.data || []).length)
        return null

    const onDecideInvite = () => {
        load()
    }

    return (
        <div>
            <Header title="Davetiyelerim"/>
            {response.data.map((invitation, index) => {
                return (
                    <InvitationCard key={index}
                                    onDecideInvite={onDecideInvite}
                                    invitationsApi={invitationsApi}
                                    invitation={invitation}
                    />
                )
            })}
        </div>
    )
}

const InvitationCard = props => {
    const {invitation,onDecideInvite} = props;

    return <StoreCard invitation={invitation}
                      onDecideInvite={onDecideInvite}
                      store={invitation.store}/>
}

export default Invitations
