import React from 'react';
import AttachmentImage from "../../../components/AttachmentImage";
import PostCard from "../../../components/PostCard";
import constants from "../../../utils/constants";
import DateDescription from "../../../components/DateDescription";
import InfiniteScrollView from "../../../components/InfiniteScroll";
import EmptyResult from "../../../components/EmptyResult";
import PurchaseProductListItem from "../../../components/ListItem/PurchaseProductListItem";
import PurchaseCheckListItem from "../../../components/ListItem/PurchaseCheckListItem";
import {getFirstLetters} from "../../../utils/functions";
import Shimmer from "../../../components/Shimmer";
import {ShoppingCartOutlined} from '@ant-design/icons'
import useStore from "../../../hooks/useStore";
import useHistory from "../../../hooks/useHistory";

const StorePurchases = (props) => {
    const store = useStore();
    const {id: storeId} = store || {}
    const history = useHistory();

    const pushToCollaboratorProfile = ({username}) => {
        history.push(`/collaborators/${username}`)
    }

    const getFilterOptions = () => {
        return {
            startDateProps: {show: true},
            endDateProps: {show: true},
        }
    }

    return (
        <>
            <InfiniteScrollView
                endpoint={`/stores/${storeId}/purchases/filter`}
                filterOptions={getFilterOptions()}
                shimmer={({...e}) => <div style={{margin: 16}} {...e}>
                    <Shimmer isLoading style={{marginTop: 8}}/>
                </div>}
                render={(item, index, pageProps) => {
                    return (
                        <PostCard
                            key={index}
                            style={{marginTop: 8}}
                            avatar={<AttachmentImage id={(item.seller.image || {}).id} size={40}
                                                     placeholder={getFirstLetters(item.seller.fullName)}/>}
                            title={item.seller.fullName}
                            onHeaderClick={() => pushToCollaboratorProfile(item.seller)}
                            description={<DateDescription date={item.createdDate}/>}>
                            {item.purchaseProducts.map((purchaseProduct, sindex) => {
                                return (
                                    <PurchaseProductListItem purchaseProduct={purchaseProduct} key={sindex}/>
                                )
                            })}
                            <PurchaseCheckListItem purchaseProducts={item.purchaseProducts}/>
                        </PostCard>
                    )
                }}
                empty={<EmptyResult icon={<ShoppingCartOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                    title={"Henüz alışveriş yapmadınız.\n Kupon kazanmak için hemen alışveriş yapmaya başlayın!"}
                />}
            />
        </>
    )
}

export default StorePurchases
