import React from 'react';
import Profile from "./Profile";
import WorkPlaces from "./WorkPlaces";
import Invitations from "./Invitations";
import Authorized from "../../../components/Authorized";
import useAuth from "../../../hooks/useAuth";
import {Col, Row} from "antd";

const CustomerProfile = props => {
    const {match: {params}} = props;
    const {username: propsUsername} = params || {}
    const auth = useAuth();
    const {username: stateUsername} = auth || {};
    const username = propsUsername || stateUsername;

    return (
        <div style={{padding: 16}}>
            <Row gutter={8}>
                <Col xs={24} sm={24} md={12}>
                    <Col xs={24} sm={24} md={24}>
                        <Profile username={username}/>
                    </Col>
                    <Authorized type="customer-profile" params={{username}}>
                        <Col xs={24} sm={24} md={24}>
                            <Invitations username={username}/>
                        </Col>
                    </Authorized>
                </Col>
                <Authorized type="customer-profile" params={{username}}>
                    <Col xs={24} sm={24} md={12}>
                        <WorkPlaces username={username}/>
                    </Col>
                </Authorized>
            </Row>
        </div>
    )
}

export default CustomerProfile
