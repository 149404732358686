import React, {useState} from 'react';
import {Avatar, Button, Col, Layout, Menu, Popconfirm, Row} from 'antd';
import {FullscreenExitOutlined, FullscreenOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {useHistory, withRouter} from 'react-router-dom';
import useLocalStorage from "../hooks/useLocalStorage";
import constants from "../utils/constants";
import useAuth from "../hooks/useAuth";
import useRoutes from "../hooks/useRoutes";
import Show from "../components/DisplayConditions/Show";
import useStore from "../hooks/useStore";

const {Header, Content, Footer} = Layout
const {SubMenu} = Menu

const AppLayout = withRouter(props => {
    const {children} = props;
    return (
        <div>
            <AppHeader/>
            <Layout style={{minHeight: 'calc(100vh - 64px)'}}>
                <LeftNavigation/>
                <Layout style={{paddingBottom: 48}}>
                    <Content id="layoutContent">
                        {children}
                    </Content>
                </Layout>
                <div style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    zIndex: 1,
                    width: '100%',
                    boxShadow: '0px 0px 20px -12px black'
                }}
                     className="show-mobil">
                    <AppMenu mode="horizontal" style={{width: '100%'}} className='center'/>
                </div>
            </Layout>
        </div>
    )
});

const LeftNavigation = props => {
    const [collapse, setCollapse] = useState(true)
    return (
        <Layout.Sider
            trigger={null}
            collapsible
            className="show-desktop"
            collapsed={collapse}
            breakpoint="lg"
            style={{boxShadow: '0px 5px 5px'}}
            onCollapse={collapse => {
                setCollapse(collapse);
            }}
            theme={"light"}>
            <AppMenu/>
        </Layout.Sider>
    )
}


const AppMenu = (props) => {
    const history = useHistory();
    const {location: {pathname}} = history
    const {routes} = useRoutes()


    const pushTo = (path) => {
        history.push(path)
    }

    const menuRoutes = routes.filter(i => i.name && i.icon)

    const getSubroutes = (routes, depth) => {
        return routes.map((route, index) => {
            const subroutes = (route.routes || []).filter(i => i.name && i.icon)
            const icon = route ? React.createElement(route.icon) : undefined
            const title = route.name
            if (subroutes.length) {
                return (
                    <SubMenu key={`${depth}-${index}`}
                             icon={icon}
                             title={title}
                    >
                        {getSubroutes(subroutes, depth + 1)}
                    </SubMenu>
                )
            } else if (route.path) {
                return (
                    <Menu.Item key={route.path}
                               onClick={() => pushTo(route.path)}
                               icon={React.createElement(route.icon)}>
                        {route.name}
                    </Menu.Item>
                )
            } else {
                return null
            }
        })
    }
    return (
        <Menu mode={"inline"} defaultSelectedKeys={[pathname]} {...props}>
            {getSubroutes(menuRoutes, 0)}
        </Menu>
    )
}

const HeaderRightContent = props => {
    const history = useHistory();
    const {removeItem: removeToken} = useLocalStorage("token")
    const auth = useAuth();
    const {id: storeId, company: storeCompany} = useStore();
    const company = {...(storeCompany || {})};
    const {id: companyId} = company || {}

    const onUserProfileClick = () => {
        history.push('/profile')
    };

    const onCompanyProfileClick = () => {
        history.push(`/stores/${storeId}`)
    };

    const onLogOut = () => {
        auth.logout()
        removeToken();
    };

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100%',
        }}>
            <FullScreenButton/>
            <div onClick={onUserProfileClick} style={{cursor: 'pointer', marginLeft: 12, lineHeight: 'normal'}}>
                <PPAvatar user={auth}/>
            </div>
            <Show condition={companyId}>
                <div onClick={onCompanyProfileClick}
                     style={{cursor: 'pointer', marginLeft: 12, lineHeight: 'normal'}}>
                    <PPAvatar user={company}/>
                </div>
            </Show>
            <Popconfirm
                title="Çıkış yapmak istediğinize emin misiniz?"
                onConfirm={onLogOut}
                //onCancel={cancel}
                placement="bottomRight"
                okText="Çıkış Yap"
                cancelText="Vazgeç"
            >
                <Button
                    icon={<LogoutOutlined style={{fontSize: 24}}/>}
                    style={{border: 'none', color: 'white', fontWeight: 'bold', marginLeft: 12}}
                    size="small"
                    type="ghost"
                />
            </Popconfirm>
        </div>
    );
};

const FullScreenButton = () => {
    const [fullScreen, setFullScreen] = useState(false);
    const onFullScreenClick = () => {
        const body = document.getElementsByTagName('body')[0];
        if (fullScreen) {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        } else {
            if (body.requestFullScreen) {
                body.requestFullScreen();
            } else if (body.mozRequestFullScreen) {
                body.mozRequestFullScreen();
            } else if (body.webkitRequestFullScreen) {
                body.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        }
        setFullScreen(!fullScreen);
    };

    return (
        <Button style={{
            color: 'white',
            backgroundColor: 'transparent',
            marginLeft: 12
        }}
                className="center show-desktop"
                icon={
                    <>
                        <Show condition={!fullScreen}>
                            <FullscreenOutlined
                                style={{
                                    fontSize: 12,
                                }}
                            />
                        </Show>
                        <Show condition={fullScreen}>
                            <FullscreenExitOutlined/>
                        </Show>
                    </>
                }
                size="small"
                onClick={onFullScreenClick}
        />
    );
};

const AppHeader = (props) => {
    const {dispatch} = props;
    return (
        <Header style={{display: 'inline-block', width: '100%', zIndex: 2}}>
            <div style={{width: '100%'}}>
                <Row>
                    <Col span={12}>
                    </Col>
                    <Col span={12} style={{height: 58}}>
                        <HeaderRightContent dispatch={dispatch}/>
                    </Col>
                </Row>
            </div>
        </Header>
    );
};

const PPAvatar = props => {
    const {imgSrc, url: _url, size: _size, shape, user, minSize, ...rest} = props;
    const size = _size || 32;
    const {image} = user;
    const imageId = (image || {}).id;
    if (!imageId)
        return <UserOutlined style={{backgroundColor: '#eee', color: 'white', padding: 8, borderRadius: '50%'}}/>
    const src = `${constants.REST_SERVER}/attachments/${imageId}`;
    return (
        <Avatar {...rest}
                src={src}
                shape={shape}
                style={{width: size, height: size, minWidth: minSize, minHeight: minSize}}
                icon={!src ? <UserOutlined/> : undefined}
                alt="Profil resmi"
        />
    );
};

export default AppLayout;
