import React, {useEffect} from 'react';
import Button from "../../../components/Button";
import useApi from "../../../hooks/useApi";
import useHistory from "../../../hooks/useHistory";
import {Form, Input, Modal, PageHeader} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Show from "../../../components/DisplayConditions/Show";
import {FormLayout} from "../../../utils/constants";
import Shimmer from "../../../components/Shimmer";
import PostCard from "../../../components/PostCard";
import useAuth from "../../../hooks/useAuth";

const CategoryEditScreen = (props) => {
    const {match: {params}} = props;
    const {category_id} = params || {}
    const company = useAuth();
    const companyId = (company || {}).id;
    const history = useHistory();

    const categoryUpdateApi = useApi({onSuccess: history.goBack});

    const categoryGetApi = useApi();

    const categoryDeleteApi = useApi({onSuccess: history.goBack});

    useEffect(() => {
        if (category_id) {
            categoryGetApi.load({
                endpoint: `/categories/${category_id}`,
                method: "GET"
            })
        }
    }, [category_id, companyId])

    const onSave = (values) => {
        categoryUpdateApi.load({
            endpoint: `/categories`,
            method: "POST",
            params: {
                id: category_id,
                name: values.name,
            }
        })
    }

    const onDeletePressed = () => {
        const {products: _products} = categoryGetApi.response.data || {}
        const products = _products || []

        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            title: `Silmek istediğinize emin misiniz?`,
            content: products && products.length && `Kategorinin altındaki ${products.length} tane ürün "Kategorisiz" kategorisinin altına taşınacak. Silmek istediğinize emin misiniz?`,
            onOk: onDelete,
        });
    }

    const onDelete = () => {
        categoryDeleteApi.load({
            endpoint: `/categories/${category_id}`,
            method: "DELETE",
        })
    }

    const isLoading = category_id && companyId && !categoryGetApi.fetched

    return (
        <div style={{margin: 16}}>
            <Show condition={isLoading}>
                <Shimmer/>
            </Show>
            <Show condition={!isLoading}>
                <PageHeader title="Kategori Form" onBack={history.goBack}/>
                <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                    <CategoryForm initialValues={categoryGetApi.response.data} onSave={onSave}
                                  onDeletePressed={onDeletePressed}
                                  category_id={category_id}/>
                </PostCard>
            </Show>
        </div>
    );
}

const CategoryForm = (props) => {
    const {initialValues, category_id, onDeletePressed, onSave} = props;

    return (
        <Form {...FormLayout}
              onFinish={onSave}
              initialValues={initialValues}>
            <Form.Item name={"name"} label="Kategori">
                <Input/>
            </Form.Item>
            <Show condition={category_id}>
                <Button type="danger"
                        onClick={onDeletePressed}>
                    Sil
                </Button>
            </Show>
            <Button type="primary"
                    htmlType="submit">
                Kaydet
            </Button>
        </Form>
    )
}

export default CategoryEditScreen;
