import appStyles from "../../utils/styles";
import {Button, DatePicker} from "antd";
import QuerySelect from "../QuerySelect";
import React, {useCallback, useState} from "react";
import {SearchOutlined} from '@ant-design/icons'
import Show from "../DisplayConditions/Show";

const FilterBar = props => {
    const {filterOptions, onFilter: _onFilter, style} = props;
    const [filter, setFilter] = useState({});
    const {
        startDateProps: _startDateProps,
        endDateProps: _endDateProps,
        collaboratorSelectProps: _collaboratorSelectProps,
        storeSelectProps: _storeSelectProps,
        productSelectProps: _productSelectProps,
        companySelectProps: _companySelectProps
    } = filterOptions || {}

    const startDateProps = _startDateProps || {};
    const endDateProps = _endDateProps || {};
    const collaboratorSelectProps = _collaboratorSelectProps || {};
    const storeSelectProps = _storeSelectProps || {};
    const productSelectProps = _productSelectProps || {};
    const companySelectProps = _companySelectProps || {};

    const onFilter = () => {
        _onFilter(filter);
    }

    const shouldShow = useCallback(() => {
        return startDateProps.show || endDateProps.show || collaboratorSelectProps.show || storeSelectProps.show || productSelectProps.show || companySelectProps.show
    }, [collaboratorSelectProps.show, companySelectProps.show, endDateProps.show, productSelectProps.show, startDateProps.show, storeSelectProps.show])

    const onFilterChange = (name, value) => {
        setFilter(oldFilter => ({...oldFilter, [name]: value}))
    }

    return (
        <Show
            condition={shouldShow()}>
            <div style={{...appStyles.row, ...(style || {})}}>
                <Show condition={startDateProps.show}>
                    <DatePicker placeholder="Başlangıç Tarihi"
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                                size="large"
                                onChange={(e) => onFilterChange("startDate", e)}/>
                </Show>
                <Show condition={endDateProps.show}>
                    <DatePicker placeholder="Bitiş Tarihi"
                                style={{width: '100%'}}
                                format="DD-MM-YYYY"
                                size="large"
                                onChange={(e) => onFilterChange("endDate", e)}/>
                </Show>
                <Show condition={productSelectProps.show}>
                    <QuerySelect
                        title="Ürün"
                        placeholder="Ürün Bulunmamaktadır"
                        onChange={(e) => onFilterChange("productId", e)}
                        {...productSelectProps}
                    />
                </Show>
                <Show condition={storeSelectProps.show}>
                    <QuerySelect
                        title="Mağaza"
                        placeholder="Mağaza Bulunmamaktadır"
                        onChange={(e) => onFilterChange("storeId", e)}
                        {...storeSelectProps}
                    />
                </Show>
                <Show condition={companySelectProps.show}>
                    <QuerySelect
                        title="Firma"
                        placeholder="Firma Bulunmamaktadır"
                        onChange={(e) => onFilterChange("companyId", e)}
                        {...companySelectProps}
                    />
                </Show>
                <Show condition={collaboratorSelectProps.show}>
                    <QuerySelect
                        title="Çalışan"
                        placeholder="Çalışan Bulunmamaktadır"
                        onChange={(e) => onFilterChange("collaboratorId", e)}
                        {...collaboratorSelectProps}
                    />
                </Show>
                <Button icon={<SearchOutlined/>}
                        type="primary"
                        style={{width: 80}}
                        size="large"
                        onClick={onFilter}/>
            </div>
        </Show>
    )
}

export default FilterBar;
