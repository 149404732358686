const storeReducer = (state, action) => {
    const {data} = action.payload || {}
    switch (action.type) {
        case "set-store":
            return {...state, ...data};
        case "deactivate-store":
            return {};
        default:
            return state;
    }
}

export default storeReducer;
