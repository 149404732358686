import React, {useState} from 'react';
import {Button, Form, Input, PageHeader} from "antd";
import useApi from "../../../hooks/useApi";
import useAuth from "../../../hooks/useAuth";
import useHistory from "../../../hooks/useHistory";
import {FormLayout} from "../../../utils/constants";
import Show from "../../../components/DisplayConditions/Show";
import PostCard from "../../../components/PostCard";
import ImageUploadFormItem from "../../../components/ImageUploadFormItem";

const CustomerProfileEdit = props => {
    const {username, update} = useAuth();
    const history = useHistory();

    const onSuccess = response => {
        delete response.data.token;
        update(response.data)
        history.goBack();
    }

    const usersApi = useApi({
        onSuccess
    })

    const onSave = (data) => {
        usersApi.load({
            endpoint: "/users",
            method: "POST",
            params: {
                username,
                ...data
            }
        })
    }
    return (
        <div style={{flex: 1, padding: 16}}>
            <UserProfileEditForm onSave={onSave}/>
        </div>
    )
}

const UserProfileEditForm = props => {
    const {isSignup, onSave, onPushToSignInScreen} = props;
    const auth = useAuth()
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const history = useHistory();

    return (
        <div>
            <PageHeader title="Profil Düzenle" onBack={history.goBack}/>
            <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                <Form {...FormLayout}
                      initialValues={auth}
                      onFinish={onSave}>
                    <ImageUploadFormItem name="image" buttonText="Profil Fotoğrafı"/>
                    <Form.Item label="Ad" name="firstName">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Soyad" name="lastName">
                        <Input/>
                    </Form.Item>
                    <Show condition={isSignup}>
                        <Form.Item label="E-mail" name="email">
                            <Input/>
                        </Form.Item>
                    </Show>
                    <Form.Item label="Telefon" name="phoneNumber">
                        <Input/>
                    </Form.Item>
                    <Show condition={!isSignup}>
                        <Form.Item label="TC No" name="identityNumber" help="Online ödeme işlemlerinde zorunludur!">
                            <Input/>
                        </Form.Item>
                    </Show>
                    <Show condition={isSignup}>
                        <Form.Item label="Şifre" name="password">
                            <Input type="password"/>
                        </Form.Item>
                        <Form.Item label="Şifre Tekrar">
                            <Input title="Sifre Tekrar"
                                   value={passwordConfirm}
                                   onChange={setPasswordConfirm}
                            />
                        </Form.Item>
                    </Show>
                    <Form.Item style={{marginTop: 8}}>
                        <Show condition={isSignup}>
                            <Button type="link"
                                    title="Zaten Üyeyim"
                                    onClick={onPushToSignInScreen}
                                    style={{justifyContent: 'flex-end'}}

                            />
                        </Show>
                        <Show condition={!isSignup}>
                            <Button onClick={history.goBack} style={{marginRight: 8}}>
                                Vazgeç
                            </Button>
                        </Show>
                        <Button type="primary"
                                htmlType="submit">
                            {isSignup ? "Kaydol" : "Kaydet"}
                        </Button>
                    </Form.Item>
                </Form>
            </PostCard>
        </div>
    )
}

export {UserProfileEditForm};
export default CustomerProfileEdit;
