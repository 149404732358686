import React, {useEffect} from 'react';
import appStyles from "../../../utils/styles";
import useApi from "../../../hooks/useApi";
import {Button} from "antd";
import constants from "../../../utils/constants";
import Header from "../../../components/Header";
import ListItem from "../../../components/ListItem";
import EmptyResult from "../../../components/EmptyResult";
import Authorized from "../../../components/Authorized";
import {getAddressText} from "../../../utils/functions";
import {ShopOutlined} from '@ant-design/icons'
import Show from "../../../components/DisplayConditions/Show";
import useHistory from "../../../hooks/useHistory";
import useLocalStorage from "../../../hooks/useLocalStorage";
import useStore from "../../../hooks/useStore";
import Shimmer from "../../../components/Shimmer";
import Tag from "../../../components/Tag";
import useAuth from "../../../hooks/useAuth";

const Stores = (props) => {
    const {id} = props;
    const {username} = useAuth();
    const {setStore} = useStore();
    const history = useHistory();
    const {fetched, firstTimeFetched, load, response} = useApi({
        endpoint: `/companies/${id}/stores`,
    })

    const {setItem: setLocalStore} = useLocalStorage("store");

    useEffect(() => {
        load()
    }, [id, load])

    const pushToStoreScreen = ({id}) => {
        history.push(`/stores/${id}`)
    }

    const pushToNewStore = (store) => {
        history.push(`/stores/new`)
    }

    const activateStore = (store) => {
        setLocalStore(store)
        setStore(store)
    }

    const stores = response.data || []

    return (
        <div style={{height: '100%'}}>
            <Header title="Mağazalar" extra={
                <Authorized type="company-profile" params={{username}}>
                    <Button
                        icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 18}}/>}
                        type={"link"}
                        onClick={() => pushToNewStore()}>
                        Yeni
                    </Button>
                </Authorized>
            }
            />

            {!(firstTimeFetched || fetched) ?
                <Shimmer/>
                :
                !(response.data || []).length ?
                    null :
                    <div style={{...appStyles.card, padding: 8, paddingBottom: 0}}>

                        {
                            stores.map((item, index, data) => {
                                const {isDeleted} = item;
                                return (
                                    <ListItem
                                        onTitleClick={() => pushToStoreScreen(item)}
                                        key={index}
                                        style={{flex: 1, marginHorizontal: 4, paddingBottom: 12}}
                                        subtitleRenderer={
                                            <div>
                                                <Show condition={isDeleted}>
                                                    <Tag color={constants.mainDangerColor}
                                                         type="filled"
                                                         style={{width: 100}}
                                                         description={<p style={{
                                                             margin: 0,
                                                             fontWeight: 'bold',
                                                             color: 'white'
                                                         }}>KAPALI</p>}
                                                    />
                                                </Show>
                                                <Show condition={!isDeleted}>
                                                    <Button type="link"
                                                            style={{paddingLeft: 0}}>{item.phoneNumber}</Button>
                                                </Show>
                                            </div>
                                        }
                                        lastItem
                                        title={getAddressText(item.address)}
                                        titleStyle={{fontWeight: 'bold'}}
                                        description={
                                            <Authorized type="store-change-account"
                                                        params={{store: item}}>
                                                <Button type="link"
                                                        style={{paddingLeft: 0}}
                                                        onClick={() => activateStore(item)}>
                                                    Hesap Değiştir
                                                </Button>
                                            </Authorized>
                                        }
                                    />
                                )
                            })
                        }
                    </div>
            }

            <Show condition={fetched && !(stores || []).length}>
                <div style={{height: '100%'}} className="center-column">
                    <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                 title={"Henüz hiç mağaza yok."}

                    />
                </div>
            </Show>
        </div>
    )
}

export default Stores
