import React, {useCallback, useEffect, useState} from 'react';
import appStyles from "../../../utils/styles";
import Button from "../../../components/Button";
import useApi from "../../../hooks/useApi";
import {sum} from "../../../utils/functions";
import PostCard from "../../../components/PostCard";
import PostCardShimmer from "../../../components/Shimmer";
import PurchaseProductListItem from "../../../components/ListItem/PurchaseProductListItem";
import Loading from "../../../components/Loading";
import useSocket from "../../../hooks/useSocket";
import {Modal, notification} from "antd";
import useHistory from "../../../hooks/useHistory";
import constants from "../../../utils/constants";
import Show from "../../../components/DisplayConditions/Show";

const StorePaymentSummaryScreen = props => {
    const {match: {params}} = props;
    const {purchase_id} = params;
    const [id, setId] = useState(purchase_id);
    const history = useHistory();

    const onmessage = useCallback(message => {
        if (message === "deleted") {
            notification.info({
                title: "İptal",
                message: "İşlem iptal edildi.",
                duration: 5
            })
            history.goBack();
        } else {
            setId(message)
        }

    }, [history])

    useSocket({onmessage})

    const {load, response, fetched} = useApi()

    const onSuccess = useCallback((response) => {
        history.goBack();
    }, [history])

    const paymentApproveApi = useApi()
    const paymentCancelApi = useApi()
    const {load: paymentStatusApiLoad, response: paymentStatusResponse} = useApi()

    const onApprove = () => {
        paymentApproveApi.load({
            endpoint: `/purchases/${id}/paid`,
            method: "POST",
            onSuccess
        })
    }

    const onCancel = () => {
        const title = "İptal etmek istediğinize emin misiniz?"
        Modal.confirm({
            title,
            okp: "Kaldır",
            okType: "danger",
            onOk: cancel
        })
    }

    const cancel = () => {
        paymentCancelApi.load({
            endpoint: `/purchases/${id}`,
            method: "DELETE",
        })
    }

    const {data: _data} = response;
    const data = _data || {};

    const loadPayment = useCallback((response = {}) => {
        if (response.data && isApproved(response)) {
            load({
                endpoint: `/purchases/${id}`,
                method: "GET",
                onSuccess: response => {
                    if (!response.data) {
                        setId()
                    }
                }
            })
        } else {
            setId()
        }
    }, [id, load])

    useEffect(() => {
        if (id) {
            paymentStatusApiLoad({
                endpoint: `/purchases/${id}/status`,
                method: "GET",
                onSuccess: loadPayment
            })
        } else {
            loadPayment()
        }

    }, [id, load, loadPayment, paymentStatusApiLoad])

    const canApprove = (response) => {
        if (response.data)
            return response.data.purchaseStatus === constants.PurchaseStatus.Approved
        return false;
    }

    const isApproved = (response) => {
        if (response.data)
            return response.data.purchaseStatus === constants.PurchaseStatus.Approved || response.data.purchaseStatus === constants.PurchaseStatus.Paid
        return false;
    }

    const getResultPrice = () => {
        const couponGroupStatuses = data.couponGroupStatuses || [];
        return sum(couponGroupStatuses.map(couponGroupStatus => {
            const purchaseProducts = couponGroupStatus.purchaseProducts || []
            return sum(purchaseProducts.map(i => {
                return i.product.price * (i.purchaseQuantity - i.couponQuantity)
            }))
        })) || 0
    }

    if (!id)
        return <div style={{...appStyles.center, height: '100%'}}>
            <Loading
                tip="Müşteri onayı bekleniyor..."/>
        </div>
            ;
    const isLoading = !fetched;

    if (isLoading || !data.store) {
        return (
            <div style={{flex: 1}}>
                <div style={{flex: 1, margin: 16}}>
                    <PostCardShimmer style={{marginTop: 16}}
                                     headerDescription={false}
                                     headerAvatar={false}
                                     headerSubtitle={false}
                                     contentSubtitle={true}/>
                </div>
            </div>
        )
    }

    const resultPrice = getResultPrice().toFixed(2);

    const {couponGroupStatuses} = data;

    return (
        <div style={{flex: 1}}>
            <div style={{
                backgroundColor: '#eee', ...appStyles.defaultShadow,
                padding: 16, ...appStyles.center
            }}>
                <p style={{fontWeight: 'bold'}}>Hesap: {resultPrice}₺</p>
            </div>
            <div>
                <div style={{flex: 1, padding: 16}}>
                    {
                        (couponGroupStatuses || []).map((couponGroupStatus, index) => {
                                return (
                                    <PostCard
                                        key={index}
                                        title={couponGroupStatus.couponGroup.name}
                                        descriptionFlex={2}>
                                        {
                                            (couponGroupStatus.purchaseProducts || []).map((purchaseProduct, ppindex) => {
                                                return (
                                                    <PurchaseProductListItem
                                                        key={ppindex}
                                                        purchaseProduct={{
                                                            ...purchaseProduct,
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </PostCard>
                                )
                            }
                        )
                    }
                </div>
            </div>
            <Show condition={canApprove(paymentStatusResponse)}>
                <div style={{...appStyles.spreadHorizontally}}>
                    <Button type="danger" onClick={onCancel} style={{margin: 16, flex: 1}}>İptal</Button>
                    <Button type="primary" onClick={onApprove} style={{margin: 16, flex: 1}}>Onayla</Button>
                </div>
            </Show>
        </div>

    )
}

export default StorePaymentSummaryScreen;
