const authReducer = (state, action) => {
    const {data} = action.payload || {}
    switch (action.type) {
        case 'set-token':
            return {...state, ...(data || {})};
        case 'update-auth':
            return {...state, ...(data || {})};
        case "login":
            return {...state, ...(data || {}), checked: true, isLoggedIn: true};
        case "logout":
            return {checked: true, isLoggedIn: false};
        default:
            return state;
    }
}

export default authReducer;
