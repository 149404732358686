import React, {useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import useHistory from "../../hooks/useHistory";
import {Button, Form, Input, notification} from "antd";
import PostCard from "../../components/PostCard";
import {authEndpoints, FormLayout} from "../../utils/constants";
import Show from "../../components/DisplayConditions/Show";
import {isCompanyApp, isCustomerApp} from "../../utils/functions";
import appStyles from "../../utils/styles";
import ImageUploadFormItem from "../../components/ImageUploadFormItem";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";

const SignUpCard = props => {
    const {login, isLoggedIn} = useAuth()
    const {setItem: setToken} = useLocalStorage("token")
    const [passwordConfirm, setPasswordConfirm] = useState();
    const signUpApi = useApi();
    const {load: loadSignIn} = useApi();
    const history = useHistory();

    const onSuccessSignIn = response => {
        setToken(response.data.token)
        login(response.data)
    }

    useEffect(() => {
        if (isLoggedIn)
            history.push("/")
    }, [history, isLoggedIn])

    const onSignIn = (params) => {
        loadSignIn({
            endpoint: authEndpoints.signin,
            method: "POST",
            params,
            onSuccess: onSuccessSignIn
        })
    }

    const signup = (values) => {
        const {password} = values;
        if (password === passwordConfirm) {
            signUpApi.load({
                endpoint: authEndpoints.signup,
                method: "POST",
                onSuccess: (response) => {
                    const {username} = response.data;
                    onSignIn({
                        username,
                        password
                    })
                },
                params: {
                    ...values
                }
            })
        } else {
            notification.error({
                message: "Şifre tekrarı tutarsız",
                duration: 5,
            })
        }
    }

    const pushToSignin = () => {
        history.push("/sign/")
    }

    return (
        <PostCard style={{
            width: 400,
            marginTop: 16,
            marginBottom: 16
        }}
                  childrenContainerStyle={{
                      padding: 32,
                  }}
        >
            <h1 style={{margin: 0}}>Üye Ol</h1>
            <Form {...FormLayout}
                  onFinish={signup}
                  style={{marginTop: 16}}>
                <Show condition={isCompanyApp()}>
                    <div style={{...appStyles.center}}>
                        <ImageUploadFormItem name="image"
                                             size={150}
                                             buttonText="Firma Fotoğrafı"/>
                    </div>
                    <Form.Item label="Firma Adı" name="name" rules={[{required: true}]}>
                        <Input placeholder="Firma adı"
                        />
                    </Form.Item>
                </Show>
                <Show condition={isCustomerApp()}>
                    <Form.Item label="İsim" name="firstName" rules={[{required: true}]}>
                        <Input placeholder="İsim"
                        />
                    </Form.Item>
                    <Form.Item label="Soyad" name="lastName" rules={[{required: true}]}>
                        <Input placeholder="Soyad"
                        />
                    </Form.Item>
                </Show>
                <Form.Item label="Telefon" name="phoneNumber">
                    <Input placeholder="05051234567"
                    />
                </Form.Item>
                <Form.Item label="E-mail" name="email" rules={[{required: true}]}>
                    <Input placeholder="E-mail"
                    />
                </Form.Item>
                <Form.Item label="Şifre" name="password" rules={[{required: true}]}>
                    <Input placeholder="Şifrem" type="password"
                    />
                </Form.Item>
                <Form.Item label="Şifre Tekrar" rules={[{required: true}]}>
                    <Input.Password value={passwordConfirm}
                                    placeholder="Şifre Tekrar"
                                    onChange={e => setPasswordConfirm(e.target.value)}
                    />
                </Form.Item>
                <div style={{marginTop: 16}}>
                    <div className="spread-horizontally">
                        <Button type="link"
                                onClick={pushToSignin}
                                style={{width: 'calc(50% - 8px)'}}>
                            Zaten üyeyim
                        </Button>
                        <Button type="primary"
                                htmlType="submit"
                                style={{width: 'calc(50% - 8px)'}}>
                            Üye Ol
                        </Button>
                    </div>
                </div>
            </Form>

        </PostCard>
    )
}

export default SignUpCard
