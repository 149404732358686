import React, {useCallback, useEffect, useState} from 'react';

import {getFirstLetters, JSONToArray, sum} from "../../../utils/functions";
import Button from "../../../components/Button";
import constants from "../../../utils/constants";
import appStyles from "../../../utils/styles";
import useApi from "../../../hooks/useApi";

import EmptyResult from "../../../components/EmptyResult";
import QuantityBadge from "../../../components/QuantityBadge";
import Header from "../../../components/Header";
import MenuCategoryShimmer from "../../../components/Shimmer";
import MenuProductShimmer from "../../../components/Shimmer";
import ListItem from "../../../components/ListItem";
import useStore from "../../../hooks/useStore";
import Show from "../../../components/DisplayConditions/Show";
import useHistory from "../../../hooks/useHistory";
import useAuth from "../../../hooks/useAuth";
import {Col, notification, Row} from "antd";
import AttachmentImage from "../../../components/AttachmentImage";

import {MinusOutlined, PlusOutlined, ScanOutlined, ShopOutlined} from '@ant-design/icons'
import PurchaseModal from "../../../components/Modals/PurchaseModal";
import Tag from "../../../components/Tag";

export const getTotalPrice = (purchases, quantityKey = "quantity") => {
    let totalPrice = 0;
    const prices = (purchases || []).map(i => (i.price || 0) * (i[quantityKey] || 0))
    if (prices.length) totalPrice = sum(prices);
    return totalPrice.toFixed(2)
}

const MenuScanAddScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const [data, setData] = useState({})
    const auth = useAuth();
    const store = useStore();
    const {username} = auth;
    const {table_id} = params || {};
    const {isDeleted} = store || {}
    const {id} = (store || {}).company || {}
    const {defaultTableId} = store;
    const [purchaseModalOptions, setPurchaseModalOptions] = useState({visible: false, data: []});

    const categoryApi = useApi({
        endpoint: `/companies/${id}/categories`,
    })

    const newOrderApi = useApi();

    const {load: categoryApiLoad} = categoryApi
    useEffect(() => {
        setData({})
        categoryApiLoad()
    }, [table_id, categoryApiLoad])


    const onOrderSuccess = useCallback((response) => {
        const {data: order} = response;
        if (table_id) {
            history.goBack()
        } else if (order) {
            setPurchaseModalOptions({
                visible: true,
                data: (order.orderProducts || []).map(i => ({
                    ...i,
                    quantity: data[i.product.id].quantity
                }))
            })
        }
    }, [data, history, table_id])

    const onMenuAdd = () => {
        const _data = JSONToArray(data, 'productId').filter(i => i.quantity > 0)

        if (table_id || defaultTableId) {
            newOrderApi.load({
                endpoint: `/stores/${store.id}/tables/${table_id || defaultTableId}/orders/${username}`,
                method: "POST",
                onSuccess: onOrderSuccess,
                params: {
                    orderProducts: _data
                }
            })
        } else {
            notification.error({
                title: 'Hata!',
                message: "Bir hata meydana geldi!",
                duration: 5
            })
        }
    }

    const hasProductData = JSONToArray(data, 'id').filter(i => i.quantity > 0).length;

    const onQuantityChange = (item, value) => {
        setData(oldData => {
            const newQuantity = ((oldData[item.id] || {}).quantity || 0) + value
            if (newQuantity < 0) return oldData;
            return {
                ...oldData,
                [item.id]: {
                    ...item,
                    quantity: newQuantity
                }
            }
        })
    }

    const filterEmptyCategories = (category) => {
        return !!(category || {})._ProductsCount
    }

    const onPurchase = (response) => {
        setPurchaseModalOptions({visible: false, data: []})
        history.push(`/payment/${response.data.id}`)
    }

    const onPaid = () => {
        setPurchaseModalOptions({visible: false, data: []})
    }

    const categories = (categoryApi.response.data || []).filter(filterEmptyCategories)
    const hasData = categories.length
    const purchaseData = JSONToArray(purchaseModalOptions.data, 'productId').filter(i => i.quantity > 0)

    if (!categoryApi.firstTimeFetched)
        return <MenuCategoryShimmer isLoading={true} style={{margin: 16}}/>

    return (
        <div style={{flex: 1, margin: 16}}>
            <PurchaseModal data={purchaseData}
                           visible={purchaseModalOptions.visible}
                           tableId={table_id || defaultTableId}
                           onPurchase={onPurchase}
                           onPaid={onPaid}
                           onClose={() => setPurchaseModalOptions({visible: false, data: []})}/>
            <Button disabled={!hasProductData || isDeleted}
                    type="primary"
                    shape="circle"
                    icon={
                        <>
                            <Show condition={table_id}>
                                <PlusOutlined style={{fontSize: 32}}/>
                            </Show>
                            <Show condition={!table_id}>
                                <ScanOutlined style={{fontSize: 32}}/>
                            </Show>
                        </>
                    }
                    style={{
                        width: 64,
                        height: 64,
                        position: 'fixed',
                        bottom: 64,
                        right: 16,
                        zIndex: 3,
                    }}
                    onClick={() => onMenuAdd()}
            />
            <div style={{
                backgroundColor: '#eee', ...appStyles.defaultShadow,
                padding: 16, ...appStyles.spreadHorizontally
            }}>
                <p style={{
                    margin: 0,
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}>Hesap: {getTotalPrice(JSONToArray(data, 'id'))}₺</p>
                <Button type="primary" onClick={() => setData({})} style={{paddingVertical: 8}}>Sıfırla</Button>
            </div>
            <div>
                <div style={{marginTop: 16, marginHorizontal: 16, paddingBottom: 80}}>
                    {
                        hasData ?
                            categories.map((item, index) => {
                                return (
                                    <div key={index} style={{flex: 1}}>
                                        <Header title={item.name}/>
                                        <ProductList categoryId={item.id}
                                                     onQuantityChange={onQuantityChange}
                                                     data={data}
                                                     history={history}
                                                     refresh={categoryApi.fetched}
                                        />
                                    </div>
                                )
                            })
                            :
                            <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                         title={"Henüz menünüzde kategori bulunmamaktadır.\n Kategori eklemek için sağ alttaki \"+\" işaretine dokunun!"}
                            />
                    }
                </div>
            </div>
        </div>
    )
}

const ProductList = props => {
    const {categoryId, refresh, data, onQuantityChange} = props;
    const {firstTimeFetched, response, load} = useApi();

    useEffect(() => {
        if (refresh)
            load({endpoint: `/categories/${categoryId}/products`})
    }, [categoryId, refresh, load])

    const hasData = (response.data || []).length;

    if (!firstTimeFetched)
        return <MenuProductShimmer style={{width: '100%'}} isLoading/>
    return (
        <Row gutter={8}>
            {
                hasData ?
                    response.data.map((product, index) => {
                        const {ingredients, description} = product;
                        return (
                            <Col xs={12} sm={8} md={6} lg={6} xl={4} key={index}
                                 className="fade-in"
                                 style={{animationDelay: `${index * 0.2}s`}}>
                                <div style={{
                                    ...appStyles.card,
                                    ...appStyles.defaultShadow,
                                    flex: undefined
                                }}>
                                    <div style={{position: "relative"}}>
                                        <OverlayButton icon={<PlusOutlined style={{color: 'white', fontSize: 24}}/>}
                                                       className="clickable"
                                                       style={{
                                                           top: 0,
                                                           borderBottom: "1px solid white",
                                                       }}
                                                       onClick={() => onQuantityChange(product, 1)}/>
                                        <OverlayButton icon={<MinusOutlined style={{color: 'white', fontSize: 24}}/>}
                                                       style={{bottom: 0}}
                                                       className="clickable"
                                                       onClick={() => onQuantityChange(product, -1)}/>
                                        <div style={{overflow: 'hidden'}}>
                                            <AttachmentImage
                                                id={product.image.id}
                                                placeholder={getFirstLetters(product.name)}
                                                style={{
                                                    resizeMode: 'cover',
                                                    borderRadius: 0,
                                                    width: '100%',
                                                    minHeight: 200,
                                                }}/>
                                        </div>
                                    </div>
                                    <ListItem title={product.name}
                                              style={{marginHorizontal: 8}}
                                              subtitle={product.price + "₺"}
                                              titleStyle={{fontWeight: 'bold'}}
                                              description={
                                                  <QuantityBadge quantity={(data[product.id] || {}).quantity || 0}/>
                                              }
                                    />
                                    <div style={{marginLeft: 8}}>
                                        <Show condition={description}>
                                            <p style={{marginBottom: 8}}>
                                                {description}
                                            </p>
                                        </Show>
                                        <div style={{...appStyles.grid}}>
                                            {ingredients.map((ingredient, index) => <Tag
                                                style={{marginBottom: 4, marginRight: 4}}
                                                description={ingredient.name} key={index}
                                                textStyle={{fontSize: 10}}
                                                type="filled"
                                                color={ingredient.color}
                                                generatedColor/>)}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                    :
                    <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                 title={"Henüz menünüzde ürün bulunmamaktadır."}
                    />
            }
        </Row>
    )
}

const OverlayButton = props => {
    const {onClick, icon, style} = props;
    return (
        <div style={{
            position: 'absolute',
            width: '100%',
            height: '50%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 1,
            left: 0,
            ...appStyles.center,
            ...(style || {})
        }}
             className="clickable no-select"
             onClick={onClick}
        >
            {icon}
        </div>
    )
}


export default MenuScanAddScreen
