import React, {createRef, useEffect, useState} from 'react';
import Button from "../../../components/Button";
import constants from "../../../utils/constants";
import appStyles from "../../../utils/styles";
import useApi from "../../../hooks/useApi";
import EmptyResult from "../../../components/EmptyResult";
import Header from "../../../components/Header";
import Authorized from "../../../components/Authorized";
import ProductGridList from "../../../components/ProductGridList";
import PostCardShimmer from "../../../components/Shimmer";
import {destructArray, JSONToArray} from "../../../utils/functions";
import useHistory from "../../../hooks/useHistory";
import {Row} from 'antd';
import {ShopOutlined} from '@ant-design/icons'
import useAuth from "../../../hooks/useAuth";

const CouponGroupMoveScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const {coupon_group_id} = params || {}
    const company = useAuth();
    const {id: companyId} = company || {}
    const [selectedProducts, setSelectedProducts] = useState({})
    const [productListRefs, setProductListRefs] = useState({});

    const {fetched, load, response, firstTimeFetched} = useApi({
        endpoint: `/companies/${companyId}/coupongroups/products`,
    })

    const couponGroupMoveApi = useApi({
        onSuccess: history.goBack
    });


    useEffect(() => {
        setProductListRefs((old) => {
            const refs = {};
            (response.data || []).forEach(couponGroup => {
                refs[couponGroup.id] = old[couponGroup.id] || createRef()
            })
            return refs;
        })
    }, [(response.data || []).length])

    useEffect(() => {
        load()
    }, [load])

    const hideEmptyCateagoriesAndOwnerCouponGroup = (couponGroup) => {
        return !!couponGroup.productsCount && couponGroup.id !== coupon_group_id
    }

    const onSave = () => {
        const data = destructArray(JSONToArray(selectedProducts, "coupon_group_id", "products").map(i => {
                return JSONToArray(i.products || {}, "id", "selected").filter(i => i.selected).map(i => i.id)
            }
        ))
        couponGroupMoveApi.load({
            endpoint: `/coupongroups/${coupon_group_id}/moveProducts`,
            method: "POST",
            params: {
                products: data
            }
        })
    }

    const getProductListRef = (item) => {
        return (productListRefs[item.id] || {}).current;
    }

    const selectAllProducts = (item) => {
        const productList = getProductListRef(item)
        if (productList) {
            productList.getData().filter(product => !isSelected(item, product)).forEach(product => {
                onClick(item, product);
            })
        }
    }

    const isSelected = (couponGroup, product) => {
        return !!(selectedProducts[couponGroup.id] || {})[product.id];
    }

    const deSelectAllProducts = (item) => {
        const productListJSON = selectedProducts[item.id];
        const productListJSONArray = JSONToArray(productListJSON, "id", "selected");
        productListJSONArray.filter(i => i.selected).forEach(product => {
            onClick(item, {id: product.id})
        })
    }

    const anySelectedProduct = (item) => {
        const productListJSON = selectedProducts[item.id];
        const productListJSONArray = JSONToArray(productListJSON, "id", "selected");
        return productListJSONArray.filter(i => i.selected)
    }

    const onClick = (couponGroup, product) => {
        setSelectedProducts(oldSelectedProducts => {
            if (!oldSelectedProducts[couponGroup.id])
                oldSelectedProducts[couponGroup.id] = {}
            oldSelectedProducts[couponGroup.id][product.id] = !oldSelectedProducts[couponGroup.id][product.id]
            return {...oldSelectedProducts}
        })
    }

    const data = (response.data || []).filter(hideEmptyCateagoriesAndOwnerCouponGroup)
    const hasData = !!data.length;

    return (
        <div style={{margin: 16}}>
            <Authorized type="company-profile" params={{company}}>
                <Button title={"Kaydet"}
                        type="primary"
                        style={{
                            backgroundColor: constants.mainColor,
                            position: 'absolute',
                            padding: 16,
                            bottom: 16,
                            right: 16,
                            left: 16,
                            zIndex: 3,
                        }}
                        onClick={onSave}
                />
            </Authorized>
            <div>
                <div style={{marginTop: 16, marginHorizontal: 16, paddingBottom: 80}}>
                    {
                        !firstTimeFetched ?
                            <PostCardShimmer isLoading/>
                            : fetched && !hasData ?
                            <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                         title={"Henüz menünüzde ürün içeren kategori bulunmamaktadır!"}
                            /> :
                            data.map((item, index) => {
                                const {id, name} = item;
                                return (
                                    <div key={index}>
                                        <Header title={name}
                                                extra={
                                                    <Row>
                                                        <Button type="link"
                                                                onClick={() => selectAllProducts(item)}>
                                                            Tümünü Seç
                                                        </Button>
                                                        {
                                                            anySelectedProduct(item) ?
                                                                <Button type="link"
                                                                        onClick={() => deSelectAllProducts(item)}>
                                                                    Tümünü Kaldır
                                                                </Button>
                                                                : null
                                                        }
                                                    </Row>
                                                }
                                        />
                                        <ProductGridList parentId={id}
                                                         type="coupon-group-move"
                                                         onClick={(product, _item) => onClick(_item || item, product)}
                                                         values={selectedProducts[id] || {}}
                                                         history={history}
                                                         refresh={fetched}
                                                         ref={productListRefs[id]}
                                                         companyId={companyId}
                                        />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <div style={{...appStyles.row}}>
                <Button onClick={history.goBack} style={{width: '100%'}}>
                    Vazgeç
                </Button>
                <Authorized type="company-profile" params={{company}}>
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: constants.mainColor,
                            width: '100%',
                            zIndex: 3,
                        }}
                        onClick={onSave}
                    >
                        Kaydet
                    </Button>
                </Authorized>
            </div>
        </div>
    )
}

export default CouponGroupMoveScreen
