import useAuth from "../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import useHistory from "../../hooks/useHistory";
import useApi from "../../hooks/useApi";
import PostCard from "../../components/PostCard";
import {Button, Checkbox, Divider, Form, Input} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {FacebookSignIn, GoogleSignIn} from "./index";
import appStyles from "../../utils/styles";
import {APP_NAMES, authEndpoints} from "../../utils/constants";
import Show from "../../components/DisplayConditions/Show";
import {getAppURL, isCustomerApp} from "../../utils/functions";

const SignInCard = props => {
    const {login, isLoggedIn} = useAuth()
    const {setItem: setToken} = useLocalStorage("token")
    const [rememberMe, setRememberMe] = useState(true)
    const history = useHistory();

    const onSuccess = response => {
        setToken(response.data.token)
        login(response.data)
    }

    useEffect(() => {
        if (isLoggedIn)
            history.push("/")

    }, [isLoggedIn])

    const {fetching, load} = useApi()

    const onSignIn = (params) => {
        load({
            endpoint: authEndpoints.signin,
            method: "POST",
            params,
            onSuccess
        })
    }

    const pushToSignUp = () => {
        history.push("/sign/up")
    }

    const pushToForgotPassword = () => {
        history.push("/sign/forgot-password")
    }

    const pushToHome = () => {
        window.location.href = `${getAppURL(APP_NAMES.WEBSITE)}`
    }

    return (
        <PostCard style={{
            width: 400,
        }}
                  childrenContainerStyle={{
                      padding: 32,
                  }}
        >
            <div style={{...appStyles.spreadHorizontally}}>
                <h1 style={{margin: 0}}>Giriş</h1>
                <Button type="link" onClick={pushToHome}>
                    Ana Sayfa
                </Button>
            </div>
            <Show condition={isCustomerApp()}>
                <Divider plain>
                    Sosyal Medya
                </Divider>
                <div style={{...appStyles.center}}>
                    <FacebookSignIn/>
                    <div style={{marginLeft: 4}}>
                        <GoogleSignIn/>
                    </div>
                </div>
                <Divider plain>
                    Veya
                </Divider>
            </Show>
            <Form onFinish={onSignIn} style={{marginTop: 16}}>
                <Form.Item name="username" rules={[{required: true}]}>
                    <Input placeholder="Kullanıcı Adı"
                           prefix={<UserOutlined/>}
                    />
                </Form.Item>
                <Form.Item name="password" rules={[{required: true}]}>
                    <Input.Password placeholder="Şifre"
                                    prefix={<LockOutlined/>}
                    />
                </Form.Item>
                <div style={{marginTop: 16}}>
                    <div className="spread-horizontally">
                        <Checkbox checked={rememberMe}
                                  onChange={e => setRememberMe(e.target.checked)}>
                            Beni Hatırla
                        </Checkbox>
                        <Button type="link" onClick={pushToForgotPassword}>
                            Parolamı Unuttum?
                        </Button>
                    </div>
                </div>
                <div style={{marginTop: 16}}>
                    <div className="spread-horizontally">
                        <Button type="ghost"
                                onClick={pushToSignUp}
                                style={{width: 'calc(50% - 8px)'}}>
                            Üye Ol
                        </Button>
                        <Button type="primary"
                                htmlType='submit'
                                loading={fetching}
                                style={{width: 'calc(50% - 8px)'}}>
                            Giriş
                        </Button>
                    </div>
                </div>
            </Form>
        </PostCard>
    )
}

export default SignInCard;
