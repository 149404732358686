import React, {useEffect} from 'react';
import {Button} from "antd";
import Header from "../../../components/Header";
import useApi from "../../../hooks/useApi";
import Authorized from "../../../components/Authorized";
import useHistory from "../../../hooks/useHistory";
import StoreCard from "../../../components/StoreCard";

const Profile = (props) => {
    const {id} = props;

    const {load, firstTimeFetched, response} = useApi();
    const store = response.data || {};
    const history = useHistory()

    useEffect(() => {
        load({
            endpoint: `/stores/${id}`,
        })
    }, [id, load])

    const pushToEditProfile = () => {
        history.push(`/stores/${id}/edit`);
    }

    return (
        <div style={{flex: 1}}>
            <Header title="Profil"
                    extra={
                        <Authorized type="store-profile" params={{store}}>
                            <Button type="link" onClick={pushToEditProfile}>Düzenle</Button>
                        </Authorized>
                    }/>
            <StoreProfileCard store={store}
                              isLoading={!firstTimeFetched}
            />
        </div>
    )
}

const StoreProfileCard = props => {
    const {store, isLoading} = props;
    return <StoreCard store={store} isLoading={isLoading}/>
}

export default Profile
