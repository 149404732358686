import React from 'react';
import appStyles from "../../utils/styles";
import DescriptionRow from "../DescriptionRow";
import {Button} from "antd";
import AttachmentImage from "../AttachmentImage";
import PostCard from "../PostCard";
import StoreProfileCardShimmer from "../Shimmer";
import constants from "../../utils/constants";
import useApi from "../../hooks/useApi";
import {getAddressText, getFirstLetters} from "../../utils/functions";
import {EnvironmentOutlined, MailOutlined, PhoneOutlined, UserSwitchOutlined} from '@ant-design/icons'
import Show from "../DisplayConditions/Show";
import Authorized from "../Authorized";
import LogoutButton from "../LogoutButton";
import useLocalStorage from "../../hooks/useLocalStorage";
import useHistory from "../../hooks/useHistory";
import useStore from "../../hooks/useStore";

const StoreCard = props => {
    const {isLoading, store, onDecideInvite, invitation, onHeaderClick} = props;
    const history = useHistory();
    const decideInvitationApi = useApi()
    const {setItem: setLocalStore} = useLocalStorage("store")
    const {setStore} = useStore();

    const {company} = store || {};
    const {id: companyId} = company || {};

    const pushToMenu = () => {
        if (companyId)
            history.push(`/companies/${companyId}/menu`)
    }

    const activateStore = (store) => {
        setLocalStore(store);
        setStore(store)
    }

    const decideInvitation = (decide) => {
        decideInvitationApi.load({
            endpoint: `/invites/${invitation.id}`,
            method: "POST",
            onSuccess: onDecideInvite,
            params: {
                decide
            },
        })
    }

    const pushToCompanyScreen = () => {
        const {id} = store.company;
        history.push(`/companies/${id}`)
    }

    const {address, company: _company, phoneNumber, isDeleted} = store || {};
    const {name: companyName, image: companyImage, email: companyEmail, isDeleted: isCompanyDeleted} = _company || {}
    const {description: addressDescription} = address || {}
    if (!!isLoading) {
        return <StoreProfileCardShimmer isLoading={!store || true}/>
    }

    return (
        <PostCard
            avatar={<AttachmentImage id={(companyImage || {}).id} placeholder={getFirstLetters(companyName)}
                                     size={40}/>}
            title={companyName}
            description={
                <Show condition={!invitation}>
                    <Authorized type="store-change-account" params={{store, x: 'a'}}>
                        <Button type="link"
                                style={{paddingLeft: 0}}
                                className="grid-item"
                                icon={<UserSwitchOutlined/>}
                                onClick={() => activateStore(store)}>
                            Hesap Değiştir
                        </Button>
                    </Authorized>
                    <Authorized type="store-logout" params={{store}}>
                        <LogoutButton className="grid-item"/>
                    </Authorized>
                </Show>
            }
            subtitle={getAddressText(address)}
            onTitleClick={onHeaderClick || pushToCompanyScreen}>
            <div style={{padding: 8}}>
                <DescriptionRow title={<MailOutlined/>} titleSpan={2} style={{marginTop: 16}}
                                description={companyEmail}/>
                <DescriptionRow title={<PhoneOutlined/>} titleSpan={2} style={{marginTop: 16}}
                                descriptionRenderer={phoneNumber}/>
                <DescriptionRow title={<EnvironmentOutlined/>} titleSpan={2} style={{marginTop: 16}}
                                description={addressDescription || "-"}/>

                <Show condition={companyId}>
                    <Button type="link" onClick={pushToMenu} style={{paddingLeft: 0}}
                            className="grid-item">Menü</Button>
                </Show>

                <Show condition={isDeleted || isCompanyDeleted}>
                    <div style={{
                        borderRadius: 10,
                        width: '100%',
                        backgroundColor: constants.mainDangerColor, ...appStyles.center,
                        padding: 12,
                        marginTop: 8
                    }}>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'white'}}>KAPALI</p>
                    </div>
                </Show>
                <div style={{...appStyles.grid}}>
                    <div style={{...appStyles.grid}}>
                        <Show condition={invitation}>
                            <Button type="danger"
                                    className="grid-item"
                                    onClick={() => decideInvitation(-1)}>
                                Reddet
                            </Button>
                            <Button className="grid-item" type="primary"
                                    onClick={() => decideInvitation(1)}>
                                Kabul Et
                            </Button>
                        </Show>
                    </div>
                </div>
            </div>
        </PostCard>
    )
}

export default StoreCard
