import React, {useEffect} from 'react';
import useApi from "../../hooks/useApi";
import {Button, Form, Input, Modal, PageHeader} from "antd";
import appStyles from "../../utils/styles";
import {JSONToArray} from "../../utils/functions";
import Authorized from "../../components/Authorized";
import constants, {FormLayout} from "../../utils/constants";
import ImageUploadFormItem from "../../components/ImageUploadFormItem";
import SelectItemsRenderer from "../../components/SelectItemsRenderer";
import Show from "../../components/DisplayConditions/Show";
import useHistory from "../../hooks/useHistory";
import PostCard from "../../components/PostCard";
import Shimmer from "../../components/Shimmer";
import useAuth from "../../hooks/useAuth";

const CompanyEditScreen = props => {
    const {match: {params}} = props;
    const {company_id: propsCompanyId} = params || {};
    const {id: stateCompanyId} = useAuth();
    const company_id = propsCompanyId || stateCompanyId;
    const companyInfoApi = useApi();
    const {response: companyInfoResponse, load: loadCompanyInfo} = companyInfoApi;
    const {data: company} = companyInfoResponse || {}

    useEffect(() => {
        if (company_id) {
            loadCompanyInfo({
                endpoint: `/companies/getUserInfo`,
                method: "GET",
                pushNotification: false
            })
        }
    }, [loadCompanyInfo, company_id])

    const history = useHistory();

    return (
        <div style={{padding: 16}}>
            <PageHeader title={company_id ? "Firma Düzenle" : "Yeni Firma"} onBack={history.goBack}/>
            <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                <Show condition={company_id && !companyInfoApi.fetched}>
                    <Shimmer/>
                </Show>
                <Show condition={!company_id || companyInfoApi.fetched}>
                    <CompanyForm company={company} company_id={company_id}/>
                </Show>
            </PostCard>
        </div>
    )
}

const CompanyForm = props => {
    const {company, company_id} = props;
    const {isDeleted} = company || {};
    const history = useHistory();

    const companyTypes = JSONToArray(constants.CompanyTypes, "value", "description")

    const onSuccess = ({data}) => {
        history.goBack()
    }

    const companiesApi = useApi({
        endpoint: '/companies',
        method: "POST",
        onSuccess
    })

    const onSave = (values) => {
        companiesApi.load({
            params: {
                id: company_id,
                ...values
            }
        });
    }

    const onRecover = () => {
        companyRecoverApi.load({
            endpoint: `/companies/${company_id}/recover`,
            method: "POST",
        })
    }


    const onConfirm = () => {
        companyDeleteApi.load({
            endpoint: `/companies/${company_id}`,
            method: "DELETE",
        })
    }

    const showRecoverPrompt = () => {
        Modal.confirm({
            title: "Firmayı terkar açmak istediğinize emin misiniz?",
            okText: "Aç",
            onOk: onRecover
        })
    }

    const showPrompt = () => {
        Modal.confirm({
            title: "Firmayı kapatmak istediğinize emin misiniz?",
            okText: "Aç",
            okType: 'danger',
            onOk: onConfirm
        })
    }

    const companyDeleteApi = useApi({
        onSuccess: history.goBack
    })

    const companyRecoverApi = useApi({
        onSuccess: ({data}) => {
            history.goBack()
        }
    })


    return (
        <Form {...FormLayout}
              initialValues={company || {}}
              onFinish={onSave}>
            <div style={{...appStyles.center}}>
                <ImageUploadFormItem name="image" buttonText="Firma Fotoğrafı"/>
            </div>
            <Form.Item label="Firma Adı" name="name">
                <Input/>
            </Form.Item>
            <Form.Item label="E-mail" name="email">
                <Input/>
            </Form.Item>
            <Form.Item label="Telefon" name="phoneNumber">
                <Input/>
            </Form.Item>
            <Form.Item name="companyType" label="Firma Tipi">
                <SelectItemsRenderer items={companyTypes}
                                     valueField="value"
                                     descriptionField="description"
                />
            </Form.Item>
            {/*<Form.Item label="API Key" name="apiKey" help="Online ödeme işlemleri için zorunlu alandır">*/}
            {/*    <Input/>*/}
            {/*</Form.Item>*/}
            {/*<Form.Item label="Secret Key" name="secretKey" help="Online ödeme işlemleri için zorunlu alandır">*/}
            {/*    <Input/>*/}
            {/*</Form.Item>*/}
            <Form.Item>
                <div style={{...appStyles.row, marginTop: 16}}>
                    <Button onClick={history.goBack} style={{marginRight: 8}}>
                        Vazgeç
                    </Button>
                    <Authorized type="company-profile" params={{company}}>
                        <Button type="danger"
                                style={{marginRight: 8}}
                                onClick={showPrompt}>
                            Kaldır
                        </Button>
                    </Authorized>
                    <Authorized type="company-profile" params={{company}}>
                        <Show condition={isDeleted}>
                            <Button type="success"
                                    style={{marginRight: 8}}
                                    onClick={showRecoverPrompt}>
                                Yeniden Aç
                            </Button>
                        </Show>
                    </Authorized>
                    <Button
                        type={"primary"}
                        htmlType="submit">
                        {company ? "Güncelle" : "Oluştur"}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    )
}


export default CompanyEditScreen
