import React, {useEffect} from 'react';
import appStyles from "../../../utils/styles";
import {Button} from "antd";
import useApi from "../../../hooks/useApi";
import CompanyCard from "../../../components/CompanyCard";
import Header from "../../../components/Header";
import {ShopOutlined} from "@ant-design/icons";
import constants from "../../../utils/constants";
import useHistory from "../../../hooks/useHistory";
import Shimmer from "../../../components/Shimmer";
import Show from "../../../components/DisplayConditions/Show";
import Authorized from "../../../components/Authorized";

const Companies = (props) => {
    const {username} = props;
    const {load, response, firstTimeFetched} = useApi({
        endpoint: `/users/${username}/companies/${1}/${5}`,
    })
    const history = useHistory();

    useEffect(() => {
        load()
    }, [load, username])

    const pushToAllCompanies = () => {
    }

    const pushToCompanyScreen = ({id}) => {
        history.push(`/companies/${id}`)
    }

    const pushToNewCompany = () => {
        history.push("/companies/new")
    }

    const hasData = (((response || {}).data || {}).results || []).length

    if (!firstTimeFetched)
        return <div>
            <Header title=""/>
            <Shimmer/>
        </div>

    if (!hasData)
        return (
            <Authorized type="customer-profile" params={{username}}>
                <Header title=""/>
                <div className="center"
                     style={{flexDirection: 'column'}}>
                    <div style={{
                        borderRadius: 75,
                        width: 75,
                        height: 75,
                        backgroundColor: 'white',
                        ...appStyles.center
                    }}>
                        <ShopOutlined style={{color: constants.mainColorAlpha(0.4), fontSize: 25}}/>
                    </div>
                </div>
                <div className="center"
                     style={{flexDirection: 'column'}}>
                    <p style={{margin: "8px 0", color: '#a0a0a0', fontSize: 18}}>
                        Firma sahibi misiniz?
                    </p>
                    <Button type="primary" onClick={pushToNewCompany} style={{margin: "8px 0"}}>
                        Firma oluştur
                    </Button>
                </div>
            </Authorized>
        )

    return (
        <div>
            <Header title="Firmalarım"
                    extra={<Button type="link" onClick={pushToNewCompany}>Yeni</Button>}
            />
            {response.data.results.map((company, index) => {
                return (
                    <CompanySectionCard onClick={() => pushToCompanyScreen(company)}
                                        key={index}
                                        style={{marginTop: index ? 8 : 0}}
                                        company={company}/>
                )
            })}
        </div>
    )
}

const CompanySectionCard = props => {
    const {company, style} = props;
    return <CompanyCard company={company} isLoading={false} style={style}/>
}

export default Companies
