import {useEffect, useState} from 'react';
import useAuth from "./useAuth";
import useStore from "./useStore";
import {isCompanyApp} from "../utils/functions";

const useAuthorize = (props = {}) => {
    const {params} = props;
    const [authorize, setAuthorize] = useState({
        canEditCustomerProfile: false,
        canEditCompanyProfile: false,
        canEditStoreProfile: false,

        canChangeCompanyAccount: false,
        canChangeStoreAccount: false,

        canSeeStoreCollaborators: false,

        canLogoutCustomer: false,
        canLogoutCompany: false,
        canLogoutStore: false
    });

    const auth = useAuth();
    const store = useStore();

    const {username, id: companyId, isDeleted: isCompanyDeleted, collaborators, stores} = auth || {};
    const {id: storeId, company: storeCompany, isDeleted: isStoreDeleted} = store;
    const {username: storeCompanyUsername} = storeCompany || {}

    const {username: paramsUsername, company: paramsCompany, store: paramsStore, companyId: _paramsCompanyId} = params || {};
    const {company: paramsStoreCompany, isDeleted: paramsIsStoreDeleted, id: paramsStoreId, collaborators: _paramsStoreCollaborators} = paramsStore || {}
    const {username: paramsStoreCompanyUsername} = paramsStoreCompany || {};
    const paramsStoreCollaborators = _paramsStoreCollaborators || [];

    const {username: paramsCompanyUsername, isDeleted: paramsIsCompanyDeleted} = paramsCompany || {}
    const paramsCompanyId = _paramsCompanyId || (paramsCompany || {}).id;

    const isCompanyActive = !(isCompanyDeleted || paramsIsCompanyDeleted)
    const isStoreActive = isCompanyActive && !(isStoreDeleted || paramsIsStoreDeleted);
    //console.log("useAuthorize", paramsStoreCompanyUsername, storeCompanyUsername, username)
    //console.log("[useAuthorize] username", username, "paramsCompanyUsername", paramsCompanyUsername, "storeCompanyUsername", storeCompanyUsername, "company", company)

    useEffect(() => {
        setAuthorize({
            canEditCustomerProfile: username && username === paramsUsername,
            canEditCompanyProfile: username && isCompanyApp(),
            canEditStoreProfile: (username && isCompanyApp()),

            canSeeStoreCollaborators: (username && ((paramsStoreCompanyUsername === storeCompanyUsername && storeCompanyUsername) || isCompanyApp())),

            canChangeCompanyAccount: username && username === (paramsCompanyUsername || username) && (!companyId || (companyId !== paramsCompanyId) || !!storeId),
            canChangeStoreAccount: username && ((username === storeCompanyUsername) || ((stores || collaborators || []).map(i => i.storeId || i.id).indexOf(paramsStoreId) > -1 && (!storeId || storeId !== paramsStoreId))),

            canSell: username && username === (storeCompanyUsername) && isStoreActive,

            canLogoutCustomer: username && username === paramsUsername && !!username,
            canLogoutCompany: username && username === (paramsCompanyUsername || username) && (companyId === paramsCompanyId && !storeId),
            canLogoutStore: storeId === paramsStoreId
        })
    }, [
        companyId,
        isStoreActive,
        paramsCompanyId,
        paramsCompanyUsername,
        paramsUsername,
        storeCompanyUsername,
        storeId,
        paramsStoreId,
        username
    ])

    return authorize
}

export default useAuthorize;
