import React, {useEffect} from 'react';
import constants from "../../../utils/constants";
import EmptyResult from "../../../components/EmptyResult";
import useApi from "../../../hooks/useApi";
import ListItem from "../../../components/ListItem";
import AttachmentImage from "../../../components/AttachmentImage";
import PostCard from "../../../components/PostCard";
import PostCardShimmer from "../../../components/Shimmer";
import StarCount from "../../../components/StarCount";
import {ShopOutlined} from '@ant-design/icons'
import {PageHeader} from "antd";
import useHistory from "../../../hooks/useHistory";

const CouponGroupProductsScreen = (props) => {
    const {match: {params}} = props;
    const {coupon_group_id} = params
    const {response, load, firstTimeFetched} = useApi()
    const history = useHistory();

    useEffect(() => {
        load({
            endpoint: `/coupongroups/${coupon_group_id}/products`
        })
    }, [coupon_group_id, load])

    const data = response.data || {}
    const {name, star} = data;
    const products = data.products || []
    const hasData = products.length

    return (
        <div className="fade-in">
            <PageHeader title={name}
                        onBack={history.goBack}
                        subTitle={<StarCount quantity={star}/>}
            />
            {
                !firstTimeFetched ?
                    <PostCardShimmer isLoading={true} headerSubtitle={false} style={{margin: 16}}/>
                    :
                    hasData ?
                        <PostCard style={{margin: 8, padding: 8, marginTop: 0, paddingTop: 0}}

                        >
                            {
                                products.map((item, index) => {
                                    const {name, image, category, price} = item;
                                    return (
                                        <ListItem
                                            key={index}
                                            style={{flex: 1, width: '100%'}}
                                            lastItem={index === products.length - 1 ? 0 : 1}
                                            avatar={<AttachmentImage id={(image || {}).id} size={40}/>}
                                            title={name}
                                            titleStyle={{fontWeight: 'bold'}}
                                            subtitle={`${price}₺`}
                                            description={<p
                                                style={{margin: 0, fontWeight: 'bold'}}>{(category || {}).name}</p>}
                                        />
                                    )
                                })
                            }
                        </PostCard>
                        :
                        <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                     title={"Henüz mağazanız bulunmamaktadır.\n Mağaza eklemek için sağ alttaki \"+\" işaretine dokunun!"}
                        />
            }
        </div>
    )
}

export default CouponGroupProductsScreen
