import React, {useEffect} from 'react';
import useApi from "../../../hooks/useApi";
import Shimmer from "../../../components/Shimmer";
import {ShoppingCartOutlined} from '@ant-design/icons'
import EmptyResult from "../../../components/EmptyResult";
import constants from "../../../utils/constants";
import appStyles from "../../../utils/styles";
import {Rate} from "antd";
import DescriptionOverflowImages from "../../../components/DescriptionOverflowImages";
import InfiniteScrollView from "../../../components/InfiniteScroll";
import useAuth from "../../../hooks/useAuth";
import AttachmentImage from "../../../components/AttachmentImage";
import UsedCouponQuantity from "../../../components/UsedCouponQuantity";
import PostCard from "../../../components/PostCard";
import ListItem from "../../../components/ListItem";
import useHistory from "../../../hooks/useHistory";

const CustomerDashboard = (props) => {
    const {username} = useAuth();
    const history = useHistory();

    const pushToCompanyProfile = ({id}) => {
        history.push(`/companies/${id}`);
    }

    const pushToCouponGroupProducts = ({couponGroup}) => {
        const {company: {id: companyId}, id} = couponGroup
        history.push(`/companies/${companyId}/menu/coupon-groups/${id}`);
    }

    return (
        <InfiniteScrollView
            endpoint={`/users/${username}/coupons/status`}
            shimmer={({...e}) => <div style={{margin: 16}}><Shimmer/></div>}
            render={(item, index, pageProps) => {
                const couponGroupStatuses = item.couponGroupStatuses || []
                return (
                    <PostCard
                        key={index}
                        style={{width: '100%'}}
                        childrenContainerStyle={{margin: '0 8px'}}
                        onHeaderClick={() => pushToCompanyProfile(item.company)}
                        avatar={<AttachmentImage id={(item.company.image || {}).id} size={40}/>}
                        title={item.company.name}>
                        {couponGroupStatuses.map((statusItem, sindex) => {
                            return (
                                <ListItem
                                    key={sindex}
                                    titleRenderer={
                                        <CouponGroupProductsOverflowImages
                                            couponGroup={statusItem.couponGroup}/>
                                    }
                                    lastItem
                                    onTitleClick={() => pushToCouponGroupProducts(statusItem)}
                                    description={
                                        <div style={{...appStyles.row, alignItems: 'center'}}>
                                            {statusItem.couponQuantity ?
                                                <UsedCouponQuantity style={{marginRight: 8}}
                                                                    count={statusItem.couponQuantity}
                                                />
                                                : null}
                                            <Rate value={statusItem.couponStatusQuantity}
                                                  disabled
                                                  style={{width: 160}}
                                                  count={statusItem.couponGroupStar}/>
                                        </div>
                                    }
                                />
                            )
                        })}
                    </PostCard>
                )
            }}
            empty={
                <EmptyResult icon={<ShoppingCartOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                             title={"Henüz alışveriş yapmadınız.\n Kupon kazanmak için hemen alışveriş yapmaya başlayın!"}

                />
            }
        />
    );
}

export const CouponGroupProductsOverflowImages = props => {
    const {couponGroup} = props;
    const couponGroupProductsApi = useApi();

    useEffect(() => {
        couponGroupProductsApi.load({
            endpoint: `/coupongroups/${couponGroup.id}/products`
        })
    }, [couponGroup])


    const data = couponGroupProductsApi.response.data || {};
    const products = data.products || []
    const images = products.map(i => (i.image || {}).id)

    return (
        <DescriptionOverflowImages title={couponGroup.name}
                                   images={images}/>
    )
}

export default CustomerDashboard;
