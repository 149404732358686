import React from 'react';
import Profile from "./Profile";
import Collaborators from "./Collaborators";
import {Col, Row} from "antd";
import useStore from "../../../hooks/useStore";
import Authorized from "../../../components/Authorized";
import PostCard from "../../../components/PostCard";
import {StoreFavouriteProduct} from "../../Dashboard/Store/SelfService";
import Header from "../../../components/Header";

const StoreProfileScreen = props => {
    const {match: {params}} = props;
    const {store_id: propsId} = params || {};
    const store = useStore();
    const {id: stateId} = store || {}
    const id = propsId || stateId
    return (
        <Row gutter={8} style={{padding: 16}}>
            <Col xs={24} sm={24} md={12}>
                <Profile id={id}/>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <PostCard title={<Header title="Favori Ürünler"/>}
                          titleContainerStyle={{margin: 0, padding: 0}}
                          headerStyle={{margin: 0, padding: 0}}
                >
                    <StoreFavouriteProduct id={id}/>
                </PostCard>
            </Col>
            <Authorized type="store-collaborators">
                <Col xs={24} sm={24} md={24}>
                    <Collaborators id={id}/>
                </Col>
            </Authorized>
        </Row>
    )
}

export default StoreProfileScreen
