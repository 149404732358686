import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.min.css'
import App from './App';
import {ConfigProvider} from 'antd'
import trTR from 'antd/es/locale/tr_TR';
import * as serviceWorker from './serviceWorker';
import StoreProvider from "./store";

window.env = process.env

ReactDOM.render(
    <ConfigProvider locale={trTR}>
        <StoreProvider>
            <App/>
        </StoreProvider>
    </ConfigProvider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
