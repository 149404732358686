import React, {useCallback, useState} from "react";
import PostCard from "../../components/PostCard";
import {Button, Checkbox, Col, Form, Input, PageHeader, Row} from "antd";
import useHistory from "../../hooks/useHistory";
import {FormLayout} from "../../utils/constants";
import useApi from "../../hooks/useApi";
import appStyles from "../../utils/styles";
import Show from "../../components/DisplayConditions/Show";

const CreditCardEdit = props => {
    const {match: {params}} = props;
    const {credit_card_id} = params;
    const history = useHistory();

    const onSuccess = useCallback(() => {
        history.goBack();
    }, [history])

    const onCancel = useCallback(() => {
        history.goBack();
    }, [history])

    return (
        <div style={{padding: 16}}>
            <PageHeader title={credit_card_id ? "Firma Düzenle" : "Yeni Kart"} onBack={history.goBack}/>
            <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                <CreditCardForm credit_card_id={credit_card_id}
                                onSuccess={onSuccess}
                                register
                                onCancel={onCancel}
                />
            </PostCard>
        </div>
    )
}

export const CreditCardForm = props => {
    const {onCancel, onSuccess, register} = props;
    const [saveCard, setSaveCard] = useState(!!register);

    const creditCardApi = useApi({
        endpoint: '/users/credit-cards',
        method: "POST",
        onSuccess
    })

    const onSave = (values) => {
        if (saveCard) {
            creditCardApi.load({
                params: {
                    ...values
                }
            });
        } else {
            onSuccess(values)
        }
    }

    return (
        <Form {...FormLayout}
              onFinish={onSave}>
            <Show condition={!register}>
                <Checkbox onChange={e => setSaveCard(e.target.checked)}
                          checked={saveCard}>
                    Bu kartı sonraki alışverişlerim için kaydet
                </Checkbox>
            </Show>
            <Show condition={saveCard}>
                <Form.Item label="Kart İsmi "
                           help="Kaydedilecek kart için bir isim giriniz."
                           name="cardAlias" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
            </Show>
            <Form.Item label="Kart Üzerindeki İsim" name="cardHolderName" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Kart Numarası" name="cardNumber" rules={[{required: true}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Son Kullanım Tarihi">
                <Row>
                    <Col span={4}>
                        <Form.Item noStyle help="Ay" name="expireMonth" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <div style={{marginLeft: 8, marginRight: 8, ...appStyles.center}}>/</div>
                    <Col span={4}>
                        <Form.Item noStyle help="Yıl" name="expireYear" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item label="Cvc" name="cvc" wrapperCol={{span: 4}} rules={[{required: true}]}>
                <Input/>
            </Form.Item>
            <div style={{...appStyles.row}}>
                <Button onClick={onCancel} style={{marginRight: 8}}>
                    İptal
                </Button>
                <Button type={"primary"} htmlType="submit">
                    <Show condition={saveCard}>
                        Oluştur
                    </Show>
                    <Show condition={!saveCard}>
                        Öde
                    </Show>
                </Button>
            </div>
        </Form>
    )
}

export default CreditCardEdit;
