import React, {useEffect, useState} from "react";
import {Button, Col, Row, Tabs} from 'antd'
import CategoryScreen from "./Categories";
import CouponGroupsScreen from "./CouponGroups";
import Authorized from "../../components/Authorized";
import constants, {APP_NAMES} from "../../utils/constants";
import {downloadQRCodeBySVGElement, getAppURL, isWebsiteApp} from "../../utils/functions";
import QRCode from 'react-qr-code';
import useAuth from "../../hooks/useAuth";
import PostCard from "../../components/PostCard";
import {InboxOutlined} from '@ant-design/icons'
import Dragger from "antd/es/upload/Dragger";
import Show from "../../components/DisplayConditions/Show";
import useApi from "../../hooks/useApi";
import useHistory from "../../hooks/useHistory";

const Menu = props => {
    const history = useHistory();
    useEffect(() => {
        if (isWebsiteApp()) {
            const now = new Date().valueOf();
            setTimeout(function () {
                if (new Date().valueOf() - now > 100) return;
                //  window.location = "https://itunes.apple.com/appdir";
            }, 25);
            //window.location = "kuponya://";
        }
    }, [])

    return (
        <div style={{margin: 16}}>
            <Authorized type="company-profile">
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={8}>
                        <MenuQR/>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        <ExcelMenuImport/>
                    </Col>
                </Row>
            </Authorized>
            <Tabs>
                <Tabs.TabPane tab="Kategori" key="category">
                    <CategoryScreen {...props}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Kupon Grup" key="couponGroup">
                    <CouponGroupsScreen {...props} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

const ExcelMenuImport = props => {
    const [excelFile, setExcelFile] = useState();
    const {fetching, load} = useApi();

    const uploadExcelFile = () => {
        const formData = new FormData();
        formData.append(`menuExcel`, excelFile, excelFile.name);
        load({
            endpoint: '/companies/import/menu',
            method: 'POST',
            formData,
            onSuccess: () => {
                window.location.reload()
            }
        });
    }

    const draggerProps = {
        accept: '.xls, .xlsx',
        multiple: false,
        onRemove: file => {
            setExcelFile();
        },
        beforeUpload: () => false,
        onChange: (value) => {
            const {file} = value;
            setExcelFile(file)
        },
        fileList: excelFile ? [excelFile] : [],
    };

    return (
        <PostCard title="Menü Aktar" childrenContainerStyle={{padding: 16}}>
            <h4>Excel ile menü içe aktarmak için
                <Button type='link' style={{padding: 2}}>örnek excel</Button>i
                indirip kendinize göre inceleyebilirsiniz.
            </h4>
            <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Sürükleyin ya da tıklayın!</p>
                <div className="ant-upload-hint">
                    Menünüzü içeren doğru formattaki excel dosyasını sürükleyin ya da
                    <Button type="link"
                            style={{padding: 2}}>buraya</Button>
                    tıklayarak seçin.
                </div>
            </Dragger>
            <Show condition={!!excelFile}>
                <Button type="primary"
                        loading={fetching}
                        disabled={fetching}
                        onClick={uploadExcelFile}>
                    Yükle
                </Button>
            </Show>
        </PostCard>
    )
}

const MenuQR = props => {
    const {id} = useAuth();

    const downloadUserQRCode = () => {
        const QRCodeParentNode = document.getElementById("user-qr-code")
        const QRCodeSVGElement = QRCodeParentNode.firstChild;
        downloadQRCodeBySVGElement(QRCodeSVGElement, "png", {width: 256, height: 256});
    }

    const getMenuURL = () => {
        return `${getAppURL(APP_NAMES.WEBSITE)}/companies/${id}/menu`
    }

    return (
        <PostCard title="Menü QR Kodu">
            <div style={{padding: 16, height: '100%', display: 'flex', alignItems: 'center'}}>
                <div id="user-qr-code">
                    <QRCode value={getMenuURL()}
                            size={100}
                            bgColor={"transparent"}
                            fgColor={constants.mainColor}/>
                </div>
                <Button type="primary"
                        onClick={downloadUserQRCode}
                        style={{marginLeft: 16}}
                        size="large">
                    İndir
                </Button>
            </div>
        </PostCard>
    )
}

export default Menu
