import React, {useEffect, useState} from 'react';
import appStyles from "../../../utils/styles";
import useApi from "../../../hooks/useApi";
import {Button, Input, Modal, notification} from "antd";
import constants from "../../../utils/constants";
import Header from "../../../components/Header";
import ListItem from "../../../components/ListItem";
import EmptyResult from "../../../components/EmptyResult";
import Authorized from "../../../components/Authorized";
import {UserOutlined} from '@ant-design/icons'
import Show from "../../../components/DisplayConditions/Show";
import useHistory from "../../../hooks/useHistory";
import AttachmentImage from "../../../components/AttachmentImage";
import {getFirstLetters} from "../../../utils/functions";
import Shimmer from "../../../components/Shimmer";

const Collaborators = (props) => {
    const {id} = props;
    const [showInviteModal, setShowInviteModal] = useState(false);
    const history = useHistory();
    const {fetched, firstTimeFetched, load, response} = useApi({
        endpoint: `/stores/${id}/collaborators`,
    })

    useEffect(() => {
        load()
    }, [id, load])

    const pushToUserProfile = (user) => {
        history.push(`/collaborators/${user.username}`)
    }

    const showInvitationModal = (store) => {
        setShowInviteModal(true)
    }

    const collaborators = response.data || []

    return (
        <div style={{height: '100%'}}>
            <Header title="Çalışanlar" extra={
                <Authorized type="store-profile">
                    <Button
                        icon={<UserOutlined style={{fontSize: 18}}/>}
                        type={"link"}
                        onClick={() => showInvitationModal()}>
                        Yeni
                    </Button>
                </Authorized>
            }
            />
            <Show condition={!firstTimeFetched}>
                <Shimmer/>
            </Show>
            <CollaboratorInviteModal visible={showInviteModal}
                                     onClose={() => setShowInviteModal(false)}
                                     storeId={id}
            />
            {(firstTimeFetched || fetched) && (response.data || []).length
                ?
                <div style={{...appStyles.card, padding: 8, paddingBottom: 0}}>
                    {
                        collaborators.map((item, index, data) => {
                            const {user} = item;
                            return (
                                <ListItem
                                    onClick={() => pushToUserProfile(user)}
                                    key={index}
                                    avatar={<AttachmentImage id={(user.image || {}).id}
                                                             placeholder={getFirstLetters(user.fullName)} size={40}/>}
                                    style={{flex: 1, marginHorizontal: 4, paddingBottom: 12}}
                                    lastItem
                                    title={user.fullName}
                                    titleStyle={{fontWeight: 'bold'}}
                                />
                            )
                        })
                    }
                </div>
                : null
            }

            <Show condition={fetched && !(collaborators || []).length}>
                <div style={{height: '100%'}} className="center-column">
                    <EmptyResult icon={<UserOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                 title={"Henüz hiç çalışan yok."}

                    />
                </div>
            </Show>
        </div>
    )
}

const CollaboratorInviteModal = props => {
    const {visible, onClose, storeId} = props;
    const [customerUsername, setCustomerUsername] = useState("");
    const {load} = useApi();

    const onSend = () => {
        const endpoint = `/stores/${storeId}/invite/${customerUsername}`

        load({
            endpoint,
            method: "POST",
            onSuccess: () => {
                notification.success({
                    message: 'Davet gönderildi!',
                    duration: 5
                })
                onClose()
            },
            onError: onClose,
        })
    }

    return (
        <Modal
            title="Davet"
            centered
            visible={visible}
            onOk={onSend}
            okText="Gönder"
            onCancel={onClose}>
            <p>Kullanıcı adı</p>
            <Input placeholder="Çalışan kullanıcı adı..." value={customerUsername}
                   onChange={e => setCustomerUsername(e.target.value)}/>
        </Modal>
    )
}

export default Collaborators
