import React from 'react';
import {sum} from "../../utils/functions";
import ListItem from "../ListItem";

const PurchaseCheckListItem = props => {
    const {purchaseProducts} = props;

    const haveAnyCouponsBeenUsed = () => {
        return purchaseProducts.filter(i => !!i.couponQuantity).length
    }

    const purchaseCheckWithoutCoupon = () => {
        return sum(purchaseProducts.map(i => i.product.price * i.purchaseQuantity)) || 0
    }

    const purchaseCheckWithCoupon = () => {
        return sum(purchaseProducts.map(i => i.product.price * (i.purchaseQuantity - i.couponQuantity))) || 0
    }

    return (
        <ListItem
            titleRenderer={<p style={{fontWeight: 'bold', margin: 0}}>Hesap</p>}
            lastItem
            description={
                <div style={{alignItems: 'flex-end'}}>
                    {
                        haveAnyCouponsBeenUsed() ?
                            <p style={{fontWeight: 'bold', textDecorationLine: 'line-through', margin: 0}}>
                                {purchaseCheckWithoutCoupon().toFixed(2)}₺
                            </p>
                            : null
                    }
                    <p style={{fontWeight: 'bold', margin: 0}}>
                        {purchaseCheckWithCoupon().toFixed(2)}₺
                    </p>
                </div>
            }
        />
    )
}

export default PurchaseCheckListItem;
