import {useCallback, useEffect, useState} from 'react';
import {CompanyRoutes, CustomerRoutes, StoreRestaurantRoutes, StoreSelfServiceRoutes} from "../pages/routes";
import useCompany from "./useCompany";
import useStore from "./useStore";
import {isCompanyApp} from "../utils/functions";

const useRoutes = () => {
    const company = useCompany();
    const store = useStore()
    const [routes, setRoutes] = useState([]);
    const companyId = company.id;
    const storeId = store.id;
    const storeCompanyType = (store.company || {}).companyType

    const getRoutes = useCallback(() => {
        if (isCompanyApp()) {
            return CompanyRoutes
        } else if (storeId) {
            if (storeCompanyType === "restaurant") {
                return StoreRestaurantRoutes
            } else if (storeCompanyType === "self-service") {
                return StoreSelfServiceRoutes;
            }
        } else {
            return CustomerRoutes
        }
    }, [storeId, companyId, storeCompanyType])

    useEffect(() => {
        const newRoutes = getRoutes()
        setRoutes([...newRoutes])
    }, [getRoutes])


    return {routes}
}

export default useRoutes;
