import React, {useCallback, useEffect} from 'react';
import {BrowserRouter as Router, Link, Route, Switch, useHistory} from "react-router-dom";
import LoginPage from "./pages/Login";
import Layout from "./layout";
import {Button, Card, List, Result} from 'antd'
import 'moment/locale/tr'
import useApi from "./hooks/useApi";
import useLocalStorage from "./hooks/useLocalStorage";
import {createBrowserHistory} from 'history'
import useAuth from "./hooks/useAuth";
import useStore from "./hooks/useStore";
import {CompanyRoutes, CustomerRoutes, StoreRestaurantRoutes, StoreSelfServiceRoutes} from "./pages/routes";
import constants, {authEndpoints} from "./utils/constants";
import {isCompanyApp} from "./utils/functions";
import Redirect from "./components/Redirect";

const history = createBrowserHistory()

const App = () => {
    const {isLoggedIn, checked, logout, login, token, setToken} = useAuth();

    const {setStore} = useStore()

    const {getItem: getLocalToken, removeItem: removeToken} = useLocalStorage("token")
    const {getItem: getLocalStore} = useLocalStorage("store")

    const {fetched, fetching, load} = useApi({endpoint: authEndpoints.get})

    const localToken = getLocalToken()

    const localStore = getLocalStore()

    const depsLocalStore = JSON.stringify(localStore)

    useEffect(() => {
        if (localStore && localStore.id) {
            setStore(localStore)
        }
    }, [depsLocalStore, setStore])

    useEffect(() => {
        if (localToken) {
            if (token) {
                if (!fetching && !fetched) {
                    load({
                        onSuccess: (response) => {
                            if (response.data) {
                                login(response.data)
                            } else {
                                logout()
                            }
                        },
                        onError: () => {
                            removeToken();
                            logout()
                        }
                    })
                }
            } else {
                setToken(localToken)
            }
        } else {
            logout()
        }
    }, [localToken, removeToken, fetching, load, fetched, login, logout, token, setToken])

    if (!checked || (localToken && !fetched)) {
        return (
            <div style={{minHeight: "100vh"}} className="center">
                Yükleniyor...
            </div>
        );
    } else if (isLoggedIn) {
        return (
            <AppRouter/>
        )
    } else {
        return (
            <AppPublicRoute/>
        )
    }
}

const AppPublicRoute = props => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/sign*"
                       component={LoginPage}/>
                <Route exact path="*" component={() => <Redirect redirectURL="/sign/in"/>}/>
            </Switch>
        </Router>
    )
}


const AppRouter = props => {
    return (
        <Router history={history}>
            <Layout>
                <AppRoutesRenderer/>
            </Layout>
        </Router>
    )
};

const AppRoutesRenderer = () => {
    const auth = useAuth();
    const store = useStore();

    const {username} = auth;
    const {id: storeId, company: storeCompany} = store;
    const {companyType} = storeCompany || {}

    const getComponent = useCallback(route => {
        if (route.component) {
            return require(`./pages/${route.component}`).default
        } else if (route.routes && route.routes.length) {
            return () => <ChildRoutesList routes={route.routes}/>
        } else {
            return null
        }

    }, [])

    const getAppRoutes = (routes, oldRoutes = []) => {
        const newRoutes = []

        routes.forEach((route, index) => {
            const component = getComponent(route);

            if (component) {
                const _component = (props) => <ComponentRenderer component={component} route={route} {...props}/>
                newRoutes.push(<Route key={route.path} exact path={route.path} component={_component}/>)
            }

            if (route.routes) {
                const childRoutes = getAppRoutes(route.routes, [...oldRoutes, ...newRoutes])
                newRoutes.push(...childRoutes);
            }
        })

        return [...newRoutes]
    }

    const NotFoundRoute = () => <Route path="*" key={"404"} component={NotFoundPage}/>

    const getRoutes = () => {

        if (isCompanyApp()) {
            if (username) {
                return getAppRoutes(CompanyRoutes, [])
            } else {
                return null;
            }
        } else if (!!storeId) {
            if (companyType === constants.CompanyTypeEnums.Restaurant) {
                return getAppRoutes(StoreRestaurantRoutes, [])
            } else if (companyType === constants.CompanyTypeEnums.SelfService) {
                return getAppRoutes(StoreSelfServiceRoutes, [])
            }
        } else if (!!username && !storeId) {
            return getAppRoutes(CustomerRoutes, [])
        } else {
            return null;
        }
    }

    const routes = getRoutes()

    return (
        <Switch>
            {routes}
            <Route exact path="/sign*" component={() => <Redirect redirectURL="/"/>}/>
            <NotFoundRoute/>
        </Switch>
    )
}

const ComponentRenderer = props => {
    const {route} = props;
    useEffect(() => {
        document.title = route.name || "Kuponya";
    }, [route])
    return <div className="fade-in" style={{height: '100%'}}>
        <props.component {...props}/>
    </div>
}

const ChildRoutesList = props => {
    const {routes} = props;
    const history = useHistory();
    return (
        <Card title="Menüler" style={{margin: 16}} bodyStyle={{padding: 0}}>
            <List
                itemLayout="horizontal"
                dataSource={routes}
                renderItem={route => (
                    <List.Item className="clickable"
                               onClick={() => history.push(route.path)}
                               style={{paddingLeft: 24, paddingRight: 24}}>
                        <List.Item.Meta
                            avatar={route.icon ? React.createElement(route.icon) : undefined}
                            title={route.name}
                        />
                    </List.Item>
                )}
            />
        </Card>
    )
}

const UnauthorizedPage = () => {
    return (
        <Result
            status="403"
            title="403"
            subTitle="Bu sayfayı görmeye yetkiniz yok"
            extra={
                <Link to="/">
                    <Button type="primary">Ana Sayfaya Dön</Button>
                </Link>
            }
        />
    )
}

const NotFoundPage = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sayfa bulunamadı!"
            extra={
                <Link to="/">
                    <Button type="primary">Ana Sayfaya Dön</Button>
                </Link>
            }
        />
    )
}

export default App;
