import React, {useEffect, useState} from 'react';
import Button from "../../../components/Button";
import useApi from "../../../hooks/useApi";
import QuerySelect from "../../../components/QuerySelect";
import useHistory from "../../../hooks/useHistory";
import {Form, Input, Modal, PageHeader} from "antd";
import Show from "../../../components/DisplayConditions/Show";
import Shimmer from "../../../components/Shimmer";
import PostCard from "../../../components/PostCard";
import {FormLayout} from "../../../utils/constants";
import ImageUploadFormItem from "../../../components/ImageUploadFormItem";
import useStore from "../../../hooks/useStore";
import Tag from "../../../components/Tag";
import {CheckOutlined} from '@ant-design/icons'
import appStyles from "../../../utils/styles";
import useAuth from "../../../hooks/useAuth";

const MenuFormScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const {category_id, product_id} = params || {}
    const stateCompany = useAuth();
    const {company: storeCompany} = useStore();
    const {id: companyId} = {...stateCompany, ...storeCompany}

    const productGetApi = useApi()
    const productDeleteApi = useApi()

    const endpoint = product_id ? `/products/${product_id}` : `/categories/${category_id}/products`;

    const productUpdateApi = useApi()

    useEffect(() => {
        if (product_id && companyId) {
            productGetApi.load({
                endpoint: `/products/${product_id}`,
                method: "GET"
            })
        }
    }, [product_id, companyId])

    const onSave = (oldData) => {

        const newData = {...oldData};
        const price = ((newData.price || "0") + "").replace(",", ".");
        newData.price = parseFloat(price);

        productUpdateApi.load({
            endpoint,
            method: "POST",
            params: newData,
            onSuccess: history.goBack
        })
    }

    const onRemove = () => {
        Modal.confirm({
            title: "Ürünü silmek istediğinizden emin misiniz?",
            onOk: remove,
            okType: 'danger',
            okText: 'Sil'
        })
    }

    const remove = () => {
        productDeleteApi.load({
            endpoint: `/products/${product_id}`,
            method: "DELETE",
            onSuccess: history.goBack
        })
    }

    const isLoading = product_id && companyId && !productGetApi.fetched;

    return (
        <div style={{margin: 16}}>
            <Show condition={isLoading}>
                <Shimmer/>
            </Show>
            <Show condition={!isLoading}>
                <PageHeader title="Kategori Form" onBack={history.goBack}/>
                <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                    <ProductForm initialValues={productGetApi.response.data || {category: {id: category_id}}}
                                 onSave={onSave}
                                 onRemove={onRemove}
                                 product_id={product_id}
                                 companyId={companyId}
                                 category_id={category_id}/>
                </PostCard>
            </Show>
        </div>
    )
}

const ProductForm = props => {
    const {initialValues, companyId, onRemove, product_id, onSave} = props;
    const history = useHistory();

    const pushToCategoryEdit = () => {
        history.push('/menu/categories/new')
    }

    const pushToCouponGroupEdit = () => {
        history.push('/menu/coupon-groups/new')
    }

    return (
        <Form {...FormLayout}
              onFinish={onSave}
              initialValues={initialValues}>
            <ImageUploadFormItem name="image" buttonText="Ürün Fotoğrafı"/>
            <Form.Item name="name" label="Ürün Adı">
                <Input/>
            </Form.Item>
            <Form.Item name="price" label="Fiyat">
                <Input/>
            </Form.Item>
            <div style={{width: '100%', alignItems: 'flex-end'}}>
                <Button type="link"
                        style={{paddingBottom: 0}}
                        onClick={pushToCategoryEdit}>
                    Yeni
                </Button>
            </div>
            <Form.Item name={["category", "id"]} label="Kategori">
                <QuerySelect
                    title="Kategori"
                    placeholder="Kategori Bulunmamaktadır"
                    url={`/companies/${companyId}/categories`}
                />
            </Form.Item>
            <div style={{width: '100%', justifyContent: 'flex-end', display: 'flex'}}>
                <Button type="link"
                        style={{paddingBottom: 0}}
                        onClick={pushToCouponGroupEdit}>
                    Yeni
                </Button>
            </div>

            <Form.Item name={["couponGroup", "id"]}
                       label={"Kupon Grubu"}>
                <QuerySelect
                    title="Kategori"
                    placeholder="Kupon Grubu Bulunmamaktadır"
                    url={`/companies/${companyId}/coupongroups`}
                />
            </Form.Item>

            <Form.Item name="ingredients" label="Malzemeler">
                <TextListField/>
            </Form.Item>

            <Form.Item name="description" label="Açıklama">
                <Input.TextArea rows={5}/>
            </Form.Item>

            <Button onClick={() => history.goBack()}
                    style={{marginRight: 4}}
            >
                Vazgeç
            </Button>
            <Show condition={!!product_id}>
                <Button type={'danger'}
                        style={{marginRight: 4}}
                        onClick={onRemove}>
                    Sil
                </Button>
            </Show>
            <Button type={'primary'}
                    htmlType="submit">
                Kaydet
            </Button>

        </Form>
    )
}


const TextListField = props => {
    const {value: _value, onChange: _onChange} = props;
    const [value, setValue] = useState({});
    const values = _value || []

    const onSave = (e) => {
        e.preventDefault()
        if (!value.name) return;
        if (value.index !== undefined) {
            const {index} = value;
            delete value.index;
            values[index] = value;
            _onChange([...values])
        } else {
            _onChange([...values, value])
        }

        setValue({})
    }

    const onClear = index => {
        values.splice(index, 1)
        _onChange([...values])
    }

    const onColorChange = (index, {hex: color}) => {
        console.log('color', color);
        values[index] = {...values[index], color}
        _onChange([...values]);
    }

    return (
        <>
            <div style={{...appStyles.grid}}>
                {values.map((item, index) => <Tag style={{marginRight: 4, marginBottom: 4}}
                                                  description={item.name}
                                                  color={item.color}
                                                  type="filled"
                                                  onColorChange={color => onColorChange(index, color)}
                                                  onTextClick={() => setValue({...item, index})}
                                                  onClear={() => onClear(index)}
                                                  key={index} generatedColor/>)}
            </div>
            <div style={{...appStyles.row}}>
                <Input value={value.name}
                       onChange={e => setValue({...value, name: e.target.value})}
                       onPressEnter={onSave}
                       onBlur={onSave}
                />
                <Button icon={<CheckOutlined/>}
                        type="primary"
                        disabled={!value.name}
                        onClick={onSave}
                />
            </div>
        </>
    )
}


export default MenuFormScreen
