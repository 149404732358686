import {useCallback, useContext} from 'react';
import {StoreContext} from "../store";
import useLocalStorage from "./useLocalStorage";
import useHistory from "./useHistory";

const useAuth = () => {
    const [state, dispatch] = useContext(StoreContext);
    const {setItem: setLocalCompany} = useLocalStorage("company");
    const {setItem: setLocalStore} = useLocalStorage("store");
    const {setItem} = useLocalStorage("token")
    const {auth} = state || {};
    const history = useHistory();

    const logout = useCallback(() => {
        if (history)
            history.push("/sign")

        setItem("")
        setLocalStore({})
        setLocalCompany({})
        dispatch({type: 'deactivate-company'})
        dispatch({type: 'deactivate-store'})
        dispatch({type: 'logout'})

        const gapi = window.gapi;
        if (gapi)
            if (gapi.auth2) {
                var auth2 = gapi.auth2.getAuthInstance();
                if (auth2) {
                    auth2.signOut().then(function () {
                        console.log('User signed out.');
                    });
                }
            }

        const FB = window.FB;
        if (FB) {
            if (FB.logout) {
                FB.logout(function (response) {
                });
            }
        }

    }, [dispatch, history, setItem, setLocalCompany, setLocalStore])

    const setToken = useCallback((token) => {
        dispatch({type: 'set-token', payload: {data: {token}}})
    }, [dispatch])

    const update = useCallback((data) => {
        dispatch({type: 'update-auth', payload: {data}})
    }, [dispatch])

    const login = useCallback((data) => {
        setToken(data.token);
        delete data.token;
        dispatch({type: 'login', payload: {data, checked: true, isLoggedIn: true}})
        if (history)
            history.push("/")
    }, [dispatch, setToken, history])

    return {...auth, logout, setToken, login, update}
}

export default useAuth;
