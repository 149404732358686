import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import useApi from "../../hooks/useApi";
import useAuthorize from "../../hooks/useAuthorize";
import appStyles from "../../utils/styles";
import EmptyResult from "../EmptyResult";
import constants from "../../utils/constants";
import AttachmentImage from "../AttachmentImage";
import ListItem from "../ListItem";
import StarCount from "../StarCount";
import useHistory from "../../hooks/useHistory";
import Shimmer from "../Shimmer";
import {CheckCircleOutlined, MinusCircleOutlined, ShopOutlined, StarFilled, StarOutlined} from '@ant-design/icons'
import Show from "../DisplayConditions/Show";
import {Col, Row} from "antd";
import Tag from "../Tag";
import PostCard from "../PostCard";
import {getFirstLetters} from "../../utils/functions";

const ProductGridList = forwardRef((props, ref) => {
    const {parentId, type, company, refresh, ...rest} = props;
    const history = useHistory();
    const {response, load, firstTimeFetched} = useApi();
    const {canEditCompanyProfile} = useAuthorize({params: {company}});

    const emptyResultTitle = type === "category" ? "Henüz kategoride ürün bulunmamaktadır." : "Bu grupta ürün bulunmamaktadır.";

    useEffect(() => {
        if (refresh) {
            let endpoint = '';
            switch (type) {
                case "category":
                    endpoint = `/categories/${parentId}/products`
                    break;
                case "category-move":
                    endpoint = `/categories/${parentId}/products`
                    break;
                case "coupon-group":
                    endpoint = `/coupongroups/${parentId}/products`
                    break;
                case "coupon-group-move":
                    endpoint = `/coupongroups/${parentId}/products`
                    break;
            }

            load({endpoint})
        }
    }, [parentId, refresh])

    const hasData = () => {
        if (type === "category" || type === "category-move") {
            return (response.data || []).length
        }
        return ((response.data || []).products || []).length
    };

    const onMenuAdd = (item) => {
        const categoryId = type === "category" ? parentId : item.category.id
        const couponGroupId = type === "category" ? undefined : (item.couponGroup || {}).id
        if (categoryId)
            history.push(`/menu/categories/${categoryId}/products/${item.id}/edit`)
        else if (couponGroupId)
            history.push(`/menu/coupon-groups/${couponGroupId}/products/${item.id}/edit`)
    }

    const getData = () => {
        if (type === "category" || type === "category-move") {
            return response.data
        }
        return response.data.products
    }

    return (
        <Row gutter={8}>
            {
                !firstTimeFetched ?
                    (type === "category-move" || type === "coupon-group-move") ?
                        <Shimmer headerAvatar={false}
                                 headerTitle={false}
                                 headerSubtitle={false}
                                 headerDescription={false}
                                 isLoading={true}
                                 style={{width: '100%'}}
                        />
                        :
                        <Shimmer style={{width: '100%'}} isLoading={true}/>
                    : !hasData() ?
                    <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                 title={emptyResultTitle}
                                 style={{width: '100%'}}
                    />
                    :
                    (type === "category-move" || type === "coupon-group-move") ?
                        <ProductSelectListRenderer
                            data={getData()}
                            disabled={!canEditCompanyProfile}
                            ref={ref}
                            {...rest}
                        />
                        :
                        <ProductGridListRenderer data={getData()}
                                                 disabled={!canEditCompanyProfile}
                                                 onClick={onMenuAdd}
                                                 {...rest}
                        />
            }
        </Row>
    )
})

export const ProductSelectListRenderer = forwardRef((props, ref) => {
    const {data, onClick, values, disabled} = props;


    useImperativeHandle(ref, () => ({
        getData: () => {
            return data || [];
        },
    }));


    return (
        <PostCard style={{width: '100%'}}>
            {(data || []).map((product, index) => {
                const isSelected = !!values[product.id];
                return (
                    <div style={{...appStyles.row, alignItems: 'center', flex: 1, marginHorizontal: 8}}
                         key={index}
                         className={!disabled && onClick ? "clickable" : ""}
                         onClick={() => !disabled && onClick(product)}>
                        <div style={{overflow: 'hidden'}}>
                            <Show condition={isSelected}>
                                <CheckCircleOutlined style={{color: constants.mainColor, fontSize: 32}}/>
                            </Show>
                            <Show condition={!isSelected}>
                                <MinusCircleOutlined style={{color: constants.mainColor, fontSize: 32}}/>
                            </Show>
                        </div>
                        <ListItem
                            title={product.name}
                            avatar={<AttachmentImage id={(product.image || {}).id}
                                                     placeholder={getFirstLetters(product.name)}
                                                     size={40}
                            />}
                            titleStyle={{fontWeight: 'bold'}}
                            subtitle={!product.couponGroup ? (product.category || {}).name : product.price + "₺"}
                            style={{marginHorizontal: 8, flex: 1}}
                            description={
                                product.couponGroup ?
                                    <StarCount quantity={(product.couponGroup || {}).star || "-"}/>
                                    : <div
                                        style={{...appStyles.row, alignItems: 'center'}}>
                                        <p>{product.price + "₺"}</p>
                                    </div>
                            }
                        />
                    </div>
                )
            })
            }
        </PostCard>
    )
})

export const ProductGridListRenderer = props => {
    const {data, onClick, disabled} = props;
    return (
        (data || []).map((product, index) => {
            return (
                <Col xs={12} sm={8} md={8} lg={6} xl={4} key={index} style={{marginTop: 8}}>
                    <div style={{height: '100%'}}
                         key={index}
                         className={!disabled ? "clickable" : ""}
                         onClick={() => !disabled && onClick(product)}>
                        <div style={{
                            height: '100%',
                            ...appStyles.card,
                            ...appStyles.defaultShadow,
                        }}>
                            <ProductMenuRenderer product={product}/>
                        </div>
                    </div>
                </Col>
            )
        })
    )
}

export const ProductMenuRenderer = props => {
    const {product} = props;
    const {ingredients: _ingredients, description} = product || {}
    const ingredients = _ingredients || []
    return (
        <>
            <div style={{overflow: 'hidden'}}>
                <AttachmentImage id={(product.image || {}).id}
                                 placeholder={getFirstLetters(product.name)}
                                 style={{
                                     width: '100%',
                                     minHeight: 200,
                                     backgroundColor: "#eee",
                                     resizeMode: 'cover',
                                     borderRadius: 0
                                 }}
                />
            </div>
            <ListItem
                title={product.name}
                titleStyle={{fontWeight: 'bold'}}
                subtitle={product.price + "₺"}
                style={{marginHorizontal: 8}}
                description={
                    <div
                        style={{...appStyles.row, alignItems: 'center', marginLeft: 4}}>
                        <Show condition={(product.couponGroup || {}).star}>
                            <StarFilled style={{color: constants.mainColor}}/>
                        </Show>
                        <Show condition={!(product.couponGroup || {}).star}>
                            <StarOutlined style={{color: constants.mainColor}}/>
                        </Show>
                        {(product.couponGroup || {}).star ?
                            <p style={{margin: 0}}>{(product.couponGroup || {}).star}</p>
                            : null}
                    </div>
                }
            >
                <div style={{marginLeft: 8}}>
                    <Show condition={description}>
                        <p style={{marginBottom: 8}}>
                            {description}
                        </p>
                    </Show>
                    <div style={{...appStyles.grid}}>
                        {ingredients.map((ingredient, index) => <Tag style={{marginBottom: 4, marginRight: 4}}
                                                                     description={ingredient.name} key={index}
                                                                     textStyle={{fontSize: 10}}
                                                                     type="filled"
                                                                     color={ingredient.color}
                                                                     generatedColor/>)}
                    </div>
                </div>
            </ListItem>
        </>
    )
}

export default ProductGridList;
