import React, {useRef, useState} from 'react';
import Shimmer from "../../components/Shimmer";
import PostCard from "../../components/PostCard";
import {OrderProductListItem} from "../../components/ListItem/PurchaseProductListItem";
import AttachmentImage from "../../components/AttachmentImage";
import DateDescription from "../../components/DateDescription";
import ListItem from "../../components/ListItem";
import Tag from "../../components/Tag";
import {getFirstLetters} from "../../utils/functions";
import InfiniteScrollView from "../../components/InfiniteScroll";
import {ShopOutlined} from "@ant-design/icons";
import constants from "../../utils/constants";
import EmptyResult from "../../components/EmptyResult";
import useStore from "../../hooks/useStore";
import useHistory from "../../hooks/useHistory";
import {Button} from "antd";
import useApi from "../../hooks/useApi";
import Show from "../../components/DisplayConditions/Show";

const StoreOrdersScreen = (props) => {
    const {id: storeId} = useStore();
    const history = useHistory();
    const [refreshed, setRefreshed] = useState(false);
    const {load, fetching: updateStatusFetching} = useApi();
    const infinteScrollRef = useRef(null);

    const pushToCollaboratorPage = ({username}) => {
        history.push(`/users/${username}`)
    }

    const onUpdateOrderStatusSuccess = (index, response) => {
        console.log("response", response)
        if (infinteScrollRef.current) {
            infinteScrollRef.current.updateDataByIndex(index, response.data);
        } else {
            setRefreshed(true);
        }
    }

    const updateOrderStatus = (index, orderId) => {
        load({
            endpoint: `/stores/${storeId}/orders/${orderId}/status`,
            method: "POST",
            params: {
                status: 1
            },
            onSuccess: response => onUpdateOrderStatusSuccess(index, response)
        })
    }

    return (
        <InfiniteScrollView
            ref={infinteScrollRef}
            reload={refreshed}
            onReload={() => setRefreshed(false)}
            endpoint={`/stores/${storeId}/orders`}
            shimmer={({...e}) => <div style={{margin: 16}} {...e}>
                <Shimmer isLoading style={{marginTop: 8}}/>
            </div>}
            render={(order, index, pageProps) => {
                return (
                    <div key={index}>
                        <PostCard
                            key={index}
                            avatar={<AttachmentImage id={(order.seller.image || {}).id} size={40}
                                                     placeholder={getFirstLetters(order.seller.fullName)}/>}
                            subtitle={order.table.name}
                            onHeaderClick={() => pushToCollaboratorPage(order.seller.user)}
                            description={<DateDescription date={order.createdDate}/>}
                            title={order.seller.fullName}
                            descriptionFlex={2}>
                            {
                                (order.orderProducts || []).map((orderProduct, ppindex) => {
                                    return (
                                        <OrderProductListItem
                                            key={ppindex}
                                            index={ppindex + index}
                                            orderProduct={{
                                                ...orderProduct,
                                            }}
                                        />
                                    )
                                })
                            }

                            <ListItem title="Durum"
                                      titleStyle={{fontWeight: 'bold'}}
                                      lastItem
                                      description={
                                          <Tag color={order.orderStatus ? "green" : "orange"}
                                               style={{minWidth: 100}}
                                               description={order.orderStatus ? "Hazır" : "Hazırlanıyor"}/>
                                      }/>
                            <Show condition={order.orderStatus === constants.OrderStatus.Prepare}>
                                <ListItem title=""
                                          titleStyle={{fontWeight: 'bold'}}
                                          description={
                                              <Button type="primary"
                                                      loading={updateStatusFetching}
                                                      onClick={() => updateOrderStatus(index, order.id)}>
                                                  Hazırlandı
                                              </Button>
                                          }/>
                            </Show>
                        </PostCard>
                    </div>
                )
            }}
            empty={
                <EmptyResult
                    icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                    title={"Sipariş bulunmamaktadır."}
                />
            }
        />
    );
}

export default StoreOrdersScreen;
