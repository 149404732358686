import {useCallback, useEffect, useState} from 'react';
import {iFetch} from "../utils/functions";
import {notification} from "antd";
import useAuth from "./useAuth";

const useApi = (_payload = {}) => {
    const {token, logout} = useAuth()
    const [payload, setPayload] = useState(_payload)
    const [shouldFetch, setShouldFetch] = useState(false);

    const [data, setData] = useState({
        firstTimeFetched: false,
        fetched: false,
        fetching: false,
        response: _payload.initialValue || {}
    });

    const controller = new AbortController();
    const {signal} = controller;

    const {
        onSuccess: payloadOnSuccess,
        onError: payloadOnError,
        pushNotification: payloadPushNotification
    } = payload;

    const onSuccess = useCallback((response) => {
        const pushNotification = payloadPushNotification === undefined ? true : payloadPushNotification
        if (payloadOnSuccess) payloadOnSuccess(response)

        setData(oldData => ({
            ...oldData,
            response,
            fetching: false,
            fetched: true,
            firstTimeFetched: true
        }))

        if (response.message && pushNotification) {
            notification.success({
                duration: 5,
                title: "Bildirim",
                message: response.message
            })
        }
    }, [payloadOnSuccess, payloadPushNotification])

    const onError = useCallback((response, responseJSON = {}) => {
        console.log("onError", response, responseJSON)
        const pushNotification = payloadPushNotification === undefined ? true : payloadPushNotification
        const notificationObj = {}
        switch (response.status) {
            case 404:
                notificationObj.type = "error"
                notificationObj.message = "Sayfa bulunamadı!"
                break;
            case 403:
                notificationObj.type = "warning"
                notificationObj.message = "Bu işlemi yapmaya yetkiniz yoktur!"
                break;
            case 401:
                logout()
                notificationObj.type = "warning"
                notificationObj.message = "Oturumunuz zaman aşımına uğradı!"
                break;
            default:
                notificationObj.type = "error"
                notificationObj.message = "Bilinmeyen hata"
        }

        //console.log("[useApi].onError \nresponse", response, "\n responseJSON", responseJSON, "\n notificationObj", notificationObj, payload)

        const message = response.hataMesaji || responseJSON.hataMesaji || response.message || responseJSON.message || notificationObj.message

        if (message && pushNotification) {
            notification[notificationObj.type]({
                duration: 5,
                title: "Hata!",
                message
            })
        }

        setData(oldData => ({
            ...oldData,
            response: {...(responseJSON || response)},
            fetching: false,
            fetched: true,
            firstTimeFetched: true
        }))

        if (payloadOnError)
            payloadOnError(responseJSON || response)
    }, [payloadPushNotification, payload, payloadOnError, logout])

    const load = useCallback((__payload = {}) => {
        setPayload(oldPayload => {
            const newPayload = {...oldPayload, ...__payload};
            if (!newPayload.method && !newPayload.params) {
                newPayload.method = "GET"
            }
            return newPayload
        })

        setData(oldData => ({
            ...oldData,
            fetching: true,
            fetched: false,
        }))

        setShouldFetch(true)
    }, [])

    useEffect(() => {
        if (payload.initial) load()
    }, [payload.initial, load])

    useEffect(() => {
        if (shouldFetch) {
            iFetch({
                ...payload,
                onSuccess,
                onError,
                token,
                signal
            })
            setShouldFetch(false);
        }

    }, [onError, onSuccess, payload, shouldFetch, signal, token])

    useEffect(() => {
        //console.log("controller has changed")
        return () => {
            if (controller && controller.abort) {
                //console.log("aborting")
                //controller.abort()
            }
        }
    }, [controller])

    return {load, ...data}
}

export default useApi
