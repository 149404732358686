import React, {createContext, useMemo, useReducer} from 'react';
import {combineReducers} from "../utils/functions";
import authReducer from "../reducers/auth";
import companyReducer from "../reducers/company";
import storeReducer from "../reducers/store";

const initialState = {
    auth: {},
    company: {},
    store: {}
};

const rootReducer = combineReducers({auth: authReducer, company: companyReducer, store: storeReducer});
const StoreContext = createContext(initialState)

const StoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(rootReducer, initialState);
    const store = useMemo(() => [state, dispatch], [state]);
    return (
        <StoreContext.Provider value={store}>
            {children}
        </StoreContext.Provider>
    );
};

export {StoreContext}

export default StoreProvider
