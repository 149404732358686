import React, {useCallback, useEffect, useState} from 'react';
import PostCard from "../../../components/PostCard";
import {Button, Col, Row} from "antd";
import useApi from "../../../hooks/useApi";
import Shimmer from "../../../components/Shimmer";
import {DollarCircleOutlined, ShopOutlined, StarFilled} from "@ant-design/icons";
import EmptyResult from "../../../components/EmptyResult";
import PurchaseProductListItem, {
    DescriptionIcon,
    PurchasedProductQuantity
} from "../../../components/ListItem/PurchaseProductListItem";
import ListItem from "../../../components/ListItem";
import {getAddressText} from "../../../utils/functions";
import appStyles from "../../../utils/styles";
import moment from 'moment';
import Show from "../../../components/DisplayConditions/Show";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import useHistory from "../../../hooks/useHistory";
import useAuth from "../../../hooks/useAuth";

const Dashboard = (props) => {
    const [betweenDate, setBetweenDate] = useState({type: 'today'})
    const {id} = useAuth();

    const onChangeBetweenDate = useCallback((type) => {
        const startDate = moment()
        const endDate = moment()

        switch (type) {
            case 'today':
                break;
            case 'week':
                startDate.subtract(7, 'days')
                break;
            case 'month':
                startDate.subtract(30, 'days')
                break;
            default:
                break;
        }
        setBetweenDate({
            type,
            startDate: startDate.format("YYYY-MM-DDThh:mm:ss"),
            endDate: endDate.format("YYYY-MM-DDThh:mm:ss")
        })
    }, [])

    useEffect(() => {
        onChangeBetweenDate('today')
    }, [onChangeBetweenDate])

    const getBetweenDateButtonType = (_type) => {
        const {type} = betweenDate;
        if (_type === type)
            return "primary";
        return 'dashed';
    }

    return (
        <div style={{margin: 16}}>
            <Row gutter={8} style={{marginBottom: 16}}>
                <Col span={8}>
                    <Button style={{width: '100%'}}
                            type={getBetweenDateButtonType('today')}
                            onClick={() => onChangeBetweenDate('today')}
                    >Bugün</Button>
                </Col>
                <Col span={8}>
                    <Button style={{width: '100%'}}
                            type={getBetweenDateButtonType('week')}
                            onClick={() => onChangeBetweenDate('week')}

                    >Bu Hafta</Button>
                </Col>
                <Col span={8}>
                    <Button style={{width: '100%'}}
                            type={getBetweenDateButtonType('month')}
                            onClick={() => onChangeBetweenDate('month')}
                    >Bu Ay</Button>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={24} sm={24} md={12}>
                    <PostCard title="Çok Satılanlar">
                        <Show condition={betweenDate.startDate}>
                            <CompanyFavouriteProduct id={id} filters={{betweenDate}}/>
                        </Show>
                        <Show condition={!betweenDate.startDate}>
                            <Shimmer/>
                        </Show>
                    </PostCard>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <PostCard title="Çok Satanlar">
                        <Show condition={betweenDate.startDate}>
                            <CompanyMostSellerStores filters={{betweenDate}}/>
                        </Show>
                        <Show condition={!betweenDate.startDate}>
                            <Shimmer/>
                        </Show>
                    </PostCard>
                </Col>
                <Col xs={24} sm={24} md={24}>
                    <PostCard
                        title="Kazanç">
                        <CompanyTotalIncomeLineChart filters={{betweenDate}}/>
                    </PostCard>
                </Col>
            </Row>
        </div>
    );
}

export const CompanyTotalIncomeLineChart = props => {
    const {filters} = props;
    const {betweenDate} = filters || {}
    const {startDate, endDate} = betweenDate || {}

    const {fetched, response, load} = useApi();

    useEffect(() => {
        if (startDate) {
            load({
                endpoint: `/companies/total-income`,
                method: "POST",
                params: {
                    startDate,
                    endDate
                }
            })
        }
    }, [load, startDate, endDate])

    if (!fetched) return <Shimmer/>

    const totalIncomes = response.data || [];

    if (!totalIncomes.length)
        return (
            <EmptyResult title="Henüz ürün satışı gerçekleşmemiş."
                         style={{padding: '16px 0 8px 0'}}
                         icon={<StarFilled style={{color: 'orange', fontSize: 48}}/>}
            />
        )


    const data = totalIncomes.map((date) => ({
        name: moment(date.date).format("DD-MM-YYYY"),
        Gelir: date.totalIncome
    }))

    return (
        <div style={{padding: 16}}>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    data={data}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis dataKey="Gelir"/>
                    <Tooltip/>
                    <Line type="monotone" dataKey="Gelir" stroke="#82ca9d"/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export const CompanyFavouriteProduct = props => {
    const {id, filters} = props;
    const {betweenDate} = filters || {}
    const {startDate, endDate} = betweenDate || {}

    const {fetched, response, load} = useApi();

    useEffect(() => {
        load({
            endpoint: `/companies/${id}/favourite-products/1/3`,
            method: "POST",
            params: {
                startDate,
                endDate
            }
        })
    }, [id, load, startDate, endDate])

    if (!fetched)
        return <Shimmer/>

    const favouriteProducts = (response.data || {}).results || [];

    if (!favouriteProducts.length)
        return (
            <EmptyResult title="Henüz ürün satışı gerçekleşmemiş."
                         style={{padding: '16px 0 8px 0'}}
                         icon={<StarFilled style={{color: 'orange', fontSize: 48}}/>}
            />
        )

    return favouriteProducts.map((purchaseProduct, index) => <PurchaseProductListItem purchaseProduct={purchaseProduct}
                                                                                      hideQuantity
                                                                                      key={index}/>);
}

export const CompanyMostSellerStores = props => {
    const {filters} = props;
    const {betweenDate} = filters || {}
    const {startDate, endDate} = betweenDate || {}
    const history = useHistory();
    const {fetched, response, load} = useApi();

    const pushToStoreProfile = id => {
        history.push(`/stores/${id}/dashboard`);
    }

    useEffect(() => {
        load({
            endpoint: `/companies/most-seller-stores/1/3`,
            method: "POST",
            params: {
                startDate,
                endDate
            }
        })
    }, [endDate, load, startDate])

    if (!fetched) return <Shimmer/>

    const mostSellerStores = (response.data || {}).results || [];

    if (!mostSellerStores.length)
        return (
            <EmptyResult title="Henüz satış yapan mağazanız yok."
                         style={{padding: '16px 0 8px 0'}}
                         icon={<ShopOutlined style={{color: 'orange', fontSize: 48}}/>}
            />
        )

    return mostSellerStores.map((item, index) => {
        const {id, address, totalPrice, purchaseQuantity} = item;
        return (
            <ListItem
                key={index}
                title={getAddressText(address)}
                lastItem
                onClick={() => pushToStoreProfile(id)}
                description={
                    <div style={{...appStyles.row}}>
                        <PurchasedProductQuantity count={purchaseQuantity} style={{marginRight: 2}}/>
                        <DescriptionIcon
                            icon={<DollarCircleOutlined style={{fontSize: 24, color: 'orange', marginBottom: -2}}/>}
                            description={`${totalPrice}₺`}
                        />
                    </div>
                }
            />
        )
    });
}

export default Dashboard;
