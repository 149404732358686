import React from "react";
import appStyles from "../../utils/styles";
import {generatedColorFromString} from "../../utils/functions";
import {Button, Popover} from "antd";
import Show from "../DisplayConditions/Show";
import {CloseOutlined, MoreOutlined} from '@ant-design/icons'
import {SwatchesPicker} from 'react-color'

const Tag = props => {
    const {color: _color, description, onColorChange, generatedColor, type: _type, textStyle, style, onClick, onTextClick, onClear} = props
    const type = _type || "outlined";

    const color = _color || (generatedColor ? generatedColorFromString(description) : "#ccc")
    const textColor = type === "filled" ? 'white' : color;
    const backgroundColor = type === "filled" ? color : 'white'
    return (
        <div style={{
            padding: 4,
            border: `1px solid ${color}`,
            borderRadius: 8,
            backgroundColor,
            ...appStyles.center, ...(style || {})
        }} onClick={onClick}>
            <Show condition={onColorChange}>
                <Popover content={
                    <SwatchesPicker onChange={onColorChange}/>
                } title="Renk">
                    <Button icon={<MoreOutlined style={{fontSize: 11}}/>}
                            shape="circle"
                            style={{
                                border: 'none',
                                backgroundColor: '#eee',
                                width: 20,
                                minWidth: 20,
                                height: 20,
                                lineHeight: "1px",
                                marginRight: 4
                            }}
                    />
                </Popover>

            </Show>
            <p className={onTextClick ? "clickable" : ""} style={{color: textColor, margin: 0, ...(textStyle || {})}}
               onClick={onTextClick}>
                {description}
            </p>
            <Show condition={onClear}>
                <Button icon={<CloseOutlined style={{fontSize: 11}}/>}
                        shape="circle"
                        style={{
                            border: 'none',
                            backgroundColor: '#eee',
                            width: 20,
                            minWidth: 20,
                            height: 20,
                            lineHeight: "1px",
                            marginLeft: 4
                        }}
                        onClick={onClear}

                />
            </Show>
        </div>
    )
}

export default Tag;
