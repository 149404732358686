import React from 'react'
import ListItem from "../../components/ListItem";
import AttachmentImage from "../../components/AttachmentImage";
import {Input} from 'antd';
import {getAddressText, getFirstLetters} from "../../utils/functions";
import constants from "../../utils/constants";
import EmptyResult from "../../components/EmptyResult";
import Shimmer from "../../components/Shimmer";
import {PhoneOutlined, ShopOutlined} from '@ant-design/icons'
import DescriptionIcon from "../../components/DescriptionIcon";
import useHistory from "../../hooks/useHistory";
import InfiniteScrollView from "../../components/InfiniteScroll";
import PostCard from "../../components/PostCard";
import Show from "../../components/DisplayConditions/Show";

const SearchScreen = props => {
    const {match: {params}} = props;
    const {search: _search} = params || {};
    const searchParameter = _search || "";
    const history = useHistory();

    const onSearch = (_search) => {
        history.push(`/search/${_search}`)
    }

    const pushToStoreScreen = (id) => {
        history.push(`/stores/${id}`)
    }

    const apiOptions = {
        method: "POST",
        params: {
            search: searchParameter
        }
    }

    return (
        <div style={{flex: 1}}>
            <div>
                <div style={{margin: 16}}>
                    <Input.Search placeholder='Firma, İl, İlçe ara...'
                                  defaultValue={searchParameter}
                                  size="large"
                                  style={{padding: 16, borderRadius: 20}}
                                  onSearch={onSearch}
                    />
                </div>
                <PostCard style={{margin: 16}}>
                    <InfiniteScrollView
                        style={{padding: 0}}
                        apiOptions={apiOptions}
                        endpoint={`/companies/search/address`}
                        shimmer={({...e}) => <div  {...e}>
                            <Shimmer isLoading style={{marginTop: 8}}/>
                        </div>}
                        render={(item, index) => {
                            const {id, company, address, phoneNumber} = item || {}
                            const {image, name} = company || {}
                            const {description: addressDescription} = address || {}
                            return (

                                <ListItem
                                    key={index}
                                    avatar={<AttachmentImage id={(image || {}).id}
                                                             placeholder={getFirstLetters(name)}
                                                             size={40}/>}
                                    onClick={() => pushToStoreScreen(id)}
                                    title={name}
                                    titleStyle={{fontSize: 16, fontWeight: 'bold'}}
                                    style={{padding: 4}}
                                    lastItem
                                    subtitleStyle={{fontSize: 10}}
                                    subtitle={getAddressText(address)}
                                    description={
                                        <div style={{minWidth: 100}}>
                                            <DescriptionIcon description={phoneNumber}
                                                             titleStyle={{fontSize: 10}}
                                                             icon={<PhoneOutlined
                                                                 style={{
                                                                     fontSize: 16,
                                                                     color: constants.mainColor
                                                                 }}
                                                             />}
                                            />
                                        </div>
                                    }
                                >
                                    <Show condition={addressDescription}>
                                        <p style={{marginBottom: 0, marginLeft: 48}}>{addressDescription}</p>
                                    </Show>
                                </ListItem>
                            )
                        }}
                        empty={
                            <EmptyResult
                                icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                title={"Arama kriterinize göre Firma ya da Mağaza bulamadık.\n Lütfen kontrol edip tekrar deneyin!"}
                                style={{marginTop: 16}}
                            />
                        }
                    />
                </PostCard>
            </div>
        </div>
    )
}

export default SearchScreen
