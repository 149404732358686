import React, {useCallback, useEffect, useState} from 'react';
import appStyles from "../../../utils/styles";
import ListItem from "../../../components/ListItem";
import AttachmentImage from "../../../components/AttachmentImage";
import Button from "../../../components/Button";
import useApi from "../../../hooks/useApi";
import {getAddressText, JSONToArray, sum} from "../../../utils/functions";
import PostCard from "../../../components/PostCard";
import {Modal, notification, Radio} from "antd";
import HeaderShimmer from "../../../components/Shimmer";
import PostCardShimmer from "../../../components/Shimmer";
import Shimmer from "../../../components/Shimmer";
import PurchaseProductListItem from "../../../components/ListItem/PurchaseProductListItem";
import useHistory from "../../../hooks/useHistory";
import Show from "../../../components/DisplayConditions/Show";
import constants from "../../../utils/constants";
import Tag from "../../../components/Tag";
import {CreditCardOutlined} from "@ant-design/icons";
import {CreditCardForm} from "../../CreditCard/Edit";
import QuantityField from "../../../components/QuantityField";

const CustomerPaymentSummaryScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const {purchase_id} = params || {}
    const [couponQuantities, setCouponQuantities] = useState({});
    const [showUserCardsSelectionModal, setShowUserCardsSelectionModal] = useState(false);
    const paymentSummaryApi = useApi()
    const {data: _data} = paymentSummaryApi.response;
    const {load: paymentSummaryLoad} = paymentSummaryApi;
    const data = _data || {};

    const {response: paymentStatusResponse, load: paymentStatusLoad, fetched: paymentStatusFetched} = useApi();

    const onCancelSuccess = () => {
        notification.info({
            title: "İptal",
            message: "İşlem iptal edildi.",
            duration: 5
        })
        history.goBack();
    }

    const paymentCancelApi = useApi({
        onSuccess: onCancelSuccess
    })
    const cancel = () => {
        paymentCancelApi.load({
            endpoint: `/purchases/${purchase_id}`,
            method: "DELETE",
        })
    }

    const onCancel = () => {
        const title = "İptal etmek istediğinize emin misiniz?"
        Modal.confirm({
            title,
            okp: 'İptal',
            okType: 'danger',
            onOk: cancel
        })
    }

    const paymentApproveApi = useApi()

    useEffect(() => {
        if (purchase_id) {
            paymentStatusLoad({
                endpoint: `/purchases/${purchase_id}/status`,
                method: "GET",
            })
            paymentSummaryLoad({
                endpoint: `/purchases/${purchase_id}`,
                method: "GET"
            })
        }
    }, [paymentStatusLoad, paymentSummaryLoad, purchase_id])

    const onApprove = (values) => {
        const purchaseProducts = JSONToArray(couponQuantities || {}, "couponGroupId").map(i => {
                const result = {
                    couponGroupId: i.couponGroupId,
                    purchaseProductCoupons: JSONToArray(i.products || {}, "id", "quantity")
                }
                return result
            }
        )

        paymentApproveApi.load({
            endpoint: `/purchases/${purchase_id}`,
            method: "POST",
            onSuccess: () => {
                setShowUserCardsSelectionModal(false);
                history.push(`/payment/${purchase_id}/waiting`)
            },
            params: {
                paymentMethod: values ? 1 : 0,
                ...(values || {}),
                purchaseProducts
            }
        })
    }

    const onChangeStarCount = (couponGroupStatus, value) => {
        setCouponQuantities(oldData => {

            const products = {};
            let restValue = value;
            couponGroupStatus.purchaseProducts.forEach((purchaseProduct) => {
                const {purchaseQuantity: _purchaseQuantity, product} = purchaseProduct;
                const purchaseQuantity = _purchaseQuantity || 1
                const spendQuantity = Math.min(restValue, purchaseQuantity);
                products[product.id] = spendQuantity
                restValue -= spendQuantity;
            })

            return {
                ...oldData,
                [couponGroupStatus.couponGroup.id]: {
                    products
                }
            }
        })
    }

    const isMinusDisabled = (couponGroupStatus) => {
        const couponGroup = couponQuantities[couponGroupStatus.couponGroup.id] || {};
        const {products: _couponGroupProducts} = couponGroup;
        const couponGroupProducts = _couponGroupProducts || {}
        const couponGroupProductsArray = JSONToArray(couponGroupProducts, "id", "quantity")
        const couponQuantity = couponGroupProductsArray.length ?
            couponGroupProductsArray.length === 1 ?
                couponGroupProductsArray[0].quantity :
                sum(couponGroupProductsArray.map(i => i.quantity))
            : 0


        // console.log("couponGroup", couponGroup)
        // console.log("couponGroupProductsArray", couponGroupProductsArray)
        // console.log("couponQuantity", couponQuantity)


        return couponQuantity <= 0;
    }

    const isPlusDisabled = (couponGroupStatus) => {
        const {couponQuantity: couponGroupStatusQuantity} = couponGroupStatus || {};
        const couponGroup = couponQuantities[couponGroupStatus.couponGroup.id] || {};
        const {products: _couponGroupProducts} = couponGroup;
        const couponGroupProducts = _couponGroupProducts || {}
        const couponGroupProductsArray = JSONToArray(couponGroupProducts, "id", "quantity")
        const couponQuantity = couponGroupProductsArray.length ?
            couponGroupProductsArray.length === 1 ?
                couponGroupProductsArray[0].quantity :
                sum(couponGroupProductsArray.map(i => i.quantity)) : 0

        if (couponQuantity >= (couponGroupStatusQuantity || 0)) {
            return true
        }

        const totalSize = (couponGroupStatus.purchaseProducts || []).length ?
            (couponGroupStatus.purchaseProducts || []).length === 1 ?
                couponGroupStatus.purchaseProducts[0].purchaseQuantity
                :
                sum(couponGroupStatus.purchaseProducts.map(i => i.purchaseQuantity))
            : 0;

        // console.log("couponGroup", couponGroup)
        // console.log("couponGroupProductsArray", couponGroupProductsArray)
        // console.log("couponQuantity", couponQuantity)
        // console.log("couponGroupStatusQuantity", couponGroupStatusQuantity)
        // console.log("totalSize", totalSize)

        return couponQuantity >= totalSize
    }

    const getResultPrice = () => {
        const couponGroupStatuses = data.couponGroupStatuses || [];
        return sum(couponGroupStatuses.map(couponGroupStatus => {
            const purchaseProducts = couponGroupStatus.purchaseProducts || []
            return sum(purchaseProducts.map(i => {
                const couponQuantity = ((couponQuantities[couponGroupStatus.couponGroup.id] || {}).products || {})[i.product.id] || 0
                return i.product.price * (i.purchaseQuantity - couponQuantity)
            }))
        })) || 0
    }

    const getTotalPrice = () => {
        const couponGroupStatuses = data.couponGroupStatuses || [];
        return sum(couponGroupStatuses.map(couponGroupStatus => {
            const purchaseProducts = couponGroupStatus.purchaseProducts || []
            return sum(purchaseProducts.map(i => i.product.price * i.purchaseQuantity))
        })) || 0
    }

    const onShowUserCardsSelectionModal = () => {
        setShowUserCardsSelectionModal(true)
    }

    const onOnlinePaymentSuccess = (values) => {
        onApprove(values);
    }

    const isLoading = !paymentSummaryApi.fetched;
    if (isLoading || !data.store) {
        return (
            <div style={{flex: 1}}>
                <div style={{backgroundColor: '#eee', ...appStyles.defaultShadow, padding: 16}}>
                    <HeaderShimmer avatar title subtitle description/>
                </div>
                <div style={{flex: 1, margin: 16}}>
                    <PostCardShimmer style={{marginTop: 16}} headerDescription={false} headerAvatar={false}
                                     headerSubtitle={false} contentSubtitle={true}/>
                </div>
            </div>
        )
    }

    const totalPrice = getTotalPrice().toFixed(2);
    const resultPrice = getResultPrice().toFixed(2);

    const purchaseStatus = (paymentStatusResponse.data || {}).purchaseStatus
    const canApprove = purchaseStatus === constants.PurchaseStatus.NotApproved;
    const isPaid = purchaseStatus === constants.PurchaseStatus.Paid;

    const {company, store, couponGroupStatuses} = data;
    const {address} = store || {};
    const {image, name} = company || {}

    return (
        <div style={{flex: 1}}>
            <UserCardsSelectionModal visible={showUserCardsSelectionModal}
                                     onClose={() => setShowUserCardsSelectionModal(false)}
                                     onSuccess={onOnlinePaymentSuccess}
            />

            <div style={{backgroundColor: 'white', ...appStyles.defaultShadow, padding: 16}}>
                <ListItem
                    containerStyle={{paddingTop: 0}}
                    avatar={<AttachmentImage id={(image || {}).id} size={40}/>}
                    title={name || ""}
                    titleStyle={{fontWeight: 'bold'}}
                    description={
                        <div style={{minWidth: 100}}>
                            <p style={{
                                margin: 0,
                                fontWeight: 'bold',
                                pAlign: 'center',
                                fontSize: 12,
                                textDecoration: totalPrice !== resultPrice ? 'line-through' : undefined
                            }}>Toplam: {totalPrice}₺</p>
                            <Show condition={totalPrice !== resultPrice}>
                                <p style={{
                                    margin: 0,
                                    fontWeight: 'bold',
                                    pAlign: 'center',
                                    fontSize: 12,
                                    marginTop: 4,
                                }}>Sonuç: {resultPrice}₺</p>
                            </Show>
                            <Show condition={isPaid}>
                                <Tag description={"Ödendi"} color="green"/>
                            </Show>
                        </div>
                    }
                    subtitle={getAddressText(address)}
                />
            </div>
            <div>
                <div style={{flex: 1, padding: 16}}>

                    {
                        (couponGroupStatuses || []).map((couponGroupStatus, index) => {
                                const couponGroup = couponQuantities[couponGroupStatus.couponGroup.id] || {};
                                const {products: _couponGroupProducts} = couponGroup;
                                const couponGroupProducts = _couponGroupProducts || {}
                                const couponGroupProductsArray = JSONToArray(couponGroupProducts, "id", "quantity")
                                const couponQuantity = couponGroupProductsArray.length ? couponGroupProductsArray.length === 1 ? couponGroupProductsArray[0].quantity : sum(couponGroupProductsArray.map(i => i.quantity)) : 0

                                return (
                                    <PostCard
                                        key={index}
                                        title={couponGroupStatus.couponGroup.name}
                                        //descriptionFlex={2}
                                        description={
                                            couponGroupStatus.couponQuantity > 0 ?
                                                <QuantityField
                                                    size={32}
                                                    style={{justifyContent: 'flex-end', width: '100%'}}
                                                    minusDisabled={isMinusDisabled(couponGroupStatus)}
                                                    plusDisabled={isPlusDisabled(couponGroupStatus)}
                                                    onChange={(value) => onChangeStarCount(couponGroupStatus, value)}
                                                    total={couponGroupStatus.couponQuantity}
                                                    value={couponQuantity}/>
                                                : undefined
                                        }>
                                        {
                                            (couponGroupStatus.purchaseProducts || []).map((purchaseProduct, ppindex) => {
                                                const couponGroupProductQuantity = couponGroupProducts[purchaseProduct.product.id] || 0;
                                                return (
                                                    <PurchaseProductListItem
                                                        key={ppindex}
                                                        purchaseProduct={{
                                                            ...purchaseProduct,
                                                            couponQuantity: couponGroupProductQuantity
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </PostCard>
                                )
                            }
                        )
                    }
                </div>
            </div>
            <Show condition={canApprove}>
                <div style={{...appStyles.spreadHorizontally}}>
                    <Button type="danger" onClick={onCancel} style={{margin: 8, flex: 1}}>İptal</Button>
                    <Button type="primary" onClick={() => onApprove()} style={{margin: 8, flex: 1}}>Onayla</Button>
                    {/*<Button type="primary" onClick={onShowUserCardsSelectionModal} style={{margin: 8, flex: 1}}>*/}
                    {/*    Online Öde*/}
                    {/*</Button>*/}
                </div>
            </Show>
        </div>

    )
}

const UserCardsSelectionModal = props => {
    const {visible, onClose, onSuccess} = props;
    const {fetched, response, load} = useApi();
    const [cardId, setCardId] = useState();
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);

    const reload = useCallback(() => {
        load({
            endpoint: '/users/credit-cards'
        })
    }, [load])

    useEffect(() => {
        reload()
    }, [reload])

    const onChange = (e) => {
        setCardId(e.target.value);
    }

    const onOk = () => {
        onSuccess({creditCardId: cardId});
    }

    const onShowCreditCardForm = () => {
        setShowCreditCardForm(true)
    }

    const onNewCreditCardModalSuccess = (values) => {
        if (values.success) {
            reload();
        } else {
            onSuccess({
                paymentCardModel: values
            })
        }
    }

    const data = response.data || [];
    const hasData = data.length;
    return (
        <Modal visible={visible}
               onCancel={onClose}
               onOk={onOk}
               okText="Öde"
               title="Kartlarım"
               cancelText="Vazgeç"
        >
            <CreditCardFormModal visible={showCreditCardForm}
                                 onClose={() => setShowCreditCardForm(false)}
                                 onSuccess={onNewCreditCardModalSuccess}
            />
            <Show condition={!fetched}>
                <Shimmer/>
            </Show>
            <Show condition={!hasData}>
                <div style={{...appStyles.center, flexDirection: 'column'}}>
                    <CreditCardOutlined style={{color: constants.mainColor, fontSize: 64}}/>
                    <p style={{marginTop: 16}}>Online ödeme işlemi için kart tanımlamanız gerekmektedir.</p>
                </div>
            </Show>
            <Show condition={fetched && hasData}>
                <Radio.Group onChange={onChange} value={cardId} style={{width: '100%'}}>
                    {
                        data.map((card, index) => {
                            return (
                                <Radio value={card.id} key={index}>
                                    <ListItem style={{display: 'inline-block'}} title={card.cardAlias}
                                              subtitle={card.binNumber}/>
                                </Radio>
                            )
                        })
                    }
                </Radio.Group>
            </Show>
            <Button type="link" onClick={onShowCreditCardForm}>
                Başka bir kart ile öde
            </Button>
        </Modal>
    )
}

const CreditCardFormModal = props => {
    const {visible, onClose, onSuccess} = props;

    return (
        <Modal title="Yeni Kart"
               visible={visible}
               footer={null}
        >
            <CreditCardForm onSuccess={onSuccess}
                            onCancel={onClose}
            />
        </Modal>
    )
}

export default CustomerPaymentSummaryScreen;
