import React from 'react';
import {StarFilled} from '@ant-design/icons'
import DescriptionIcon from "../DescriptionIcon";

const UsedCouponQuantity = props => {
    const {count, size: _size, ...rest} = props;
    const size = _size || 20
    return (
        <DescriptionIcon icon={<StarFilled
            style={{fontSize: size, color: "orange", marginBottom: 2}}
        />}
                         description={`${count} adet`}
                         {...rest}
        />
    )
}

export default UsedCouponQuantity;
