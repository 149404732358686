import {
    AppstoreOutlined,
    BookOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    QrcodeOutlined,
    SearchOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    UnorderedListOutlined,
    UserOutlined
} from '@ant-design/icons'
import Locales from "../locales";
import {getLocale} from "../utils/functions";

export const CustomerRoutes = [
    {
        path: '/',
        icon: DashboardOutlined,
        name: getLocale({name: Locales.Home}),
        component: 'Dashboard/Customer',
    },
    {
        path: '/search',
        icon: SearchOutlined,
        name: getLocale({name: Locales.Search}),
        component: 'Search',
        routes: [
            {
                path: '/search/:search',
                name: getLocale({name: Locales.Search}),
                component: 'Search',
            },
        ]
    },
    {
        path: '/payment',
        icon: QrcodeOutlined,
        name: getLocale({name: Locales.Payment}),
        component: 'Payment/Customer',
        routes: [
            {
                path: '/payment/:purchase_id',
                name: getLocale({name: Locales.Payment}),
                component: 'Payment/Customer/PaymentSummary',
                routes: [
                    {
                        path: '/payment/:purchase_id/waiting',
                        name: getLocale({name: Locales.Waiting}),
                        component: 'Payment/Customer/PaymentApproveWaiting',
                    }
                ]
            }
        ]
    },
    {
        path: '/purchases',
        icon: ShoppingCartOutlined,
        name: getLocale({name: Locales.Purchases}),
        component: 'Purchases/Customer',
    },
    {
        path: '/profile',
        icon: UserOutlined,
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Customer',
        routes: [
            {
                path: '/profile/edit',
                name: getLocale({name: Locales.Edit}),
                component: 'Profile/Customer/Edit',
            },
            {
                path: '/profile/credit-cards/new',
                name: getLocale({name: Locales.Edit}),
                component: 'CreditCard/Edit',
            },
            {
                path: '/profile/credit-cards/:credit_card_id',
                name: getLocale({name: Locales.Edit}),
                component: 'CreditCard/Edit',
            }
        ]
    },
    {
        path: '/stores',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/stores/new',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.New}),
                component: 'Stores/Edit',
            },
            {
                path: '/stores/:store_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Store',
                routes: [
                    {
                        path: '/stores/:store_id/edit',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores/Edit',
                    },
                    {
                        path: '/stores/:store_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                ]
            },
        ]
    },
    {
        path: '/companies',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/companies/new',
                name: getLocale({name: Locales.New}),
                component: 'Companies/Edit',
            },
            {
                path: '/companies/:company_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Company',
                routes: [
                    {
                        path: '/companies/:company_id/edit',
                        name: getLocale({name: Locales.New}),
                        component: 'Companies/Edit',
                    },
                    {
                        path: '/companies/:company_id/stores',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                    {
                        path: '/companies/:company_id/menu',
                        icon: ShopOutlined,
                        name: getLocale({name: Locales.Menu}),
                        component: 'Menu',
                    },
                    {
                        path: '/companies/:company_id/menu/coupon-groups/:coupon_group_id',
                        icon: ShopOutlined,
                        name: getLocale({name: Locales.Menu}),
                        component: 'Menu/CouponGroups/View',
                    },
                ]
            },
        ]
    }
]

export const CompanyRoutes = [
    {
        path: '/',
        icon: DashboardOutlined,
        name: getLocale({name: Locales.Home}),
        component: 'Dashboard/Company',
    },
    {
        path: '/purchases',
        icon: ShoppingCartOutlined,
        name: getLocale({name: Locales.Sales}),
        component: 'Purchases/Company',
    },
    {
        path: '/users/:username',
        component: 'Profile/Customer'
    },
    {
        path: '/users/:username/edit',
        component: 'Profile/Customer/Edit'
    },
    {
        path: '/menu',
        icon: BookOutlined,
        name: getLocale({name: Locales.Menu}),
        component: 'Menu',
        routes: [
            {
                path: '/menu/categories/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Edit',
            },
            {
                path: '/menu/categories/:category_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Edit',
            },
            {
                path: '/menu/categories/:category_id/move',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Move',
            },
            {
                path: '/menu/coupon-groups/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Edit',
            },

            {
                path: '/menu/categories/:category_id/products/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/categories/:category_id/products/:product_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/move',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Move',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/products/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/products/:product_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
        ]
    },
    {
        path: '/profile',
        icon: UserOutlined,
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Company',
        routes: [
            {
                path: '/profile/edit',
                name: getLocale({name: Locales.Edit}),
                component: 'Companies/Edit',
            }
        ]
    },
    {
        path: '/stores',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/stores/new',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.New}),
                component: 'Stores/Edit',
            },
            {
                path: '/stores/:store_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Store',
                routes: [
                    {
                        path: '/stores/:store_id/dashboard',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Dashboard/Store',
                    },
                    {
                        path: '/stores/:store_id/edit',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores/Edit',
                    },
                    {
                        path: '/stores/:store_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                ]
            },
        ]
    },
    {
        path: '/companies',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/companies/new',
                name: getLocale({name: Locales.New}),
                component: 'Companies/Edit',
            },
            {
                path: '/companies/:company_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Company',
                routes: [
                    {
                        path: '/companies/:company_id/edit',
                        name: getLocale({name: Locales.New}),
                        component: 'Companies/Edit',
                    },
                    {
                        path: '/companies/:company_id/stores',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                    {
                        path: '/companies/:company_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Menu',
                    },
                ]
            },
        ]
    },
    {
        path: '/collaborators/:username',
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Customer',
    }
]

export const StoreSelfServiceRoutes = [
    {
        path: '/',
        icon: DashboardOutlined,
        name: getLocale({name: Locales.Home}),
        component: 'Dashboard/Store/SelfService',
    },
    {
        path: '/payment',
        icon: CreditCardOutlined,
        name: getLocale({name: Locales.Payment}),
        component: 'Tables/Orders/Edit',
        routes: [
            {
                path: '/payment/:purchase_id',
                component: 'Payment/Store'
            }
        ]
    },
    {
        path: '/menu',
        icon: BookOutlined,
        name: getLocale({name: Locales.Menu}),
        component: 'Menu',
        routes: [
            {
                path: '/menu/categories/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Edit',
            },
            {
                path: '/menu/categories/:category_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Edit',
            },

            {
                path: '/menu/coupon-groups/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Edit',
            },

            {
                path: '/menu/categories/:category_id/products/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/categories/:category_id/products/:product_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },

            {
                path: '/menu/coupon-groups/:coupon_group_id/products/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/products/:product_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
        ]
    },
    {
        path: '/users/:username',
        component: 'Profile/Customer'
    },
    {
        path: '/purchases',
        icon: ShoppingCartOutlined,
        name: getLocale({name: Locales.Sales}),
        component: 'Purchases/Store',
    },
    {
        path: '/profile',
        icon: UserOutlined,
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Customer',
        routes: [
            {
                path: '/profile/edit',
                name: getLocale({name: Locales.Edit}),
                component: 'Profile/Customer/Edit',
            }
        ]
    },
    {
        path: '/stores',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/stores/new',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.New}),
                component: 'Stores/Edit',
            },
            {
                path: '/stores/:store_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Store',
                routes: [
                    {
                        path: '/stores/:store_id/edit',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores/Edit',
                    },
                    {
                        path: '/stores/:store_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                ]
            },
        ]
    },
    {
        path: '/companies',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/companies/new',
                name: getLocale({name: Locales.New}),
                component: 'Companies/Edit',
            },
            {
                path: '/companies/:company_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Company',
                routes: [
                    {
                        path: '/companies/:company_id/edit',
                        name: getLocale({name: Locales.New}),
                        component: 'Companies/Edit',
                    },
                    {
                        path: '/companies/:company_id/stores',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                    {
                        path: '/companies/:company_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Menu',
                    },
                ]
            },
        ]
    },
    {
        path: '/collaborators/:username',
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Customer',
    }
]

export const StoreRestaurantRoutes = [
    {
        path: '/',
        icon: DashboardOutlined,
        name: getLocale({name: Locales.Home}),
        component: 'Dashboard/Store/Restaurant',
    },
    {
        path: '/users/:username',
        component: 'Profile/Customer'
    },
    {
        path: '/menu',
        icon: BookOutlined,
        name: getLocale({name: Locales.Menu}),
        component: 'Menu',
        routes: [
            {
                path: '/menu/categories/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Edit',
            },
            {
                path: '/menu/categories/:category_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Categories/Edit',
            },

            {
                path: '/menu/coupon-groups/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/CouponGroups/Edit',
            },

            {
                path: '/menu/categories/:category_id/products/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/categories/:category_id/products/:product_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },

            {
                path: '/menu/coupon-groups/:coupon_group_id/products/new',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
            {
                path: '/menu/coupon-groups/:coupon_group_id/products/:product_id/edit',
                name: getLocale({name: Locales.Menu}),
                component: 'Menu/Products/Edit',
            },
        ]
    },
    {
        path: '/purchases',
        icon: ShoppingCartOutlined,
        name: getLocale({name: Locales.Sales}),
        component: 'Purchases/Store',
    },
    {
        path: '/orders',
        icon: UnorderedListOutlined,
        name: getLocale({name: Locales.Orders}),
        component: 'Orders',
    },
    {
        path: '/tables',
        icon: AppstoreOutlined,
        name: getLocale({name: Locales.Tables}),
        component: 'Tables',
        routes: [
            {
                path: '/tables/new',
                name: getLocale({name: Locales.New}),
                component: 'Tables/Edit',
            },
            {
                path: '/tables/:table_id',
                name: getLocale({name: Locales.Orders}),
                component: 'Tables/Orders',
            },
            {
                path: '/tables/:table_id/new',
                name: getLocale({name: Locales.Orders}),
                component: 'Tables/Orders/Edit',
            },
            {
                path: '/tables/:table_id/edit',
                name: getLocale({name: Locales.Edit}),
                component: 'Tables/Edit',
            },
            {
                path: '/tables/:table_id/:purchase_id',
                name: getLocale({name: Locales.Orders}),
                component: 'Payment/Store',
            },
        ]
    },
    {
        path: '/profile',
        icon: UserOutlined,
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Customer',
        routes: [
            {
                path: '/profile/edit',
                name: getLocale({name: Locales.Edit}),
                component: 'Profile/Customer/Edit',
            }
        ]
    },
    {
        path: '/collaborators/:username',
        name: getLocale({name: Locales.Profile}),
        component: 'Profile/Customer'
    },
    {
        path: '/stores',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/stores/new',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.New}),
                component: 'Stores/Edit',
            },
            {
                path: '/stores/:store_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Store',
                routes: [
                    {
                        path: '/stores/:store_id/edit',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores/Edit',
                    },
                    {
                        path: '/stores/:store_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                ]
            },
        ]
    },
    {
        path: '/companies',
        name: getLocale({name: Locales.Stores}),
        component: 'Stores',
        routes: [
            {
                path: '/companies/new',
                name: getLocale({name: Locales.New}),
                component: 'Companies/Edit',
            },
            {
                path: '/companies/:company_id',
                icon: DashboardOutlined,
                name: getLocale({name: Locales.Stores}),
                component: 'Profile/Company',
                routes: [
                    {
                        path: '/companies/:company_id/edit',
                        name: getLocale({name: Locales.New}),
                        component: 'Companies/Edit',
                    },
                    {
                        path: '/companies/:company_id/stores',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Stores',
                    },
                    {
                        path: '/companies/:company_id/menu',
                        icon: DashboardOutlined,
                        name: getLocale({name: Locales.Stores}),
                        component: 'Menu',
                    },
                ]
            },
        ]
    },

]

