const Locales = {
    Stores: "Stores",
    Search: "Search",
    Profile: "Profile",
    Tables: "Tables",
    Purchases: "Purchases",
    Sales: "Sales",
    Payment: "Payment",
    Waiting: "Waiting",
    Orders: "Orders",
    Home: "Home",
    Menu: "Menu",
    New: "New",
    Edit: "Edit",
    Delete: "Delete",
    Save: "Save",
    Decline: "Decline",
    Back: "Back",
}

export default Locales;
