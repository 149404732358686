import React, {useEffect, useState} from 'react';
import useStore from "../../../hooks/useStore";
import useApi from "../../../hooks/useApi";
import PostCardShimmer from "../../../components/Shimmer";
import PostCard from "../../../components/PostCard";
import {CancelQuantity, OrderProductListItem} from "../../../components/ListItem/PurchaseProductListItem";
import {Button, Modal, PageHeader} from "antd";
import constants from "../../../utils/constants";
import appStyles from "../../../utils/styles";
import EmptyResult from "../../../components/EmptyResult";
import AttachmentImage from "../../../components/AttachmentImage";
import DateDescription from "../../../components/DateDescription";
import Show from "../../../components/DisplayConditions/Show";
import {JSONToArray, sum} from "../../../utils/functions";
import ListItem from "../../../components/ListItem";
import useHistory from "../../../hooks/useHistory";
import {DeleteOutlined, PlusOutlined, ScanOutlined, ShopOutlined} from '@ant-design/icons'
import PurchaseModal from "../../../components/Modals/PurchaseModal";
import QuantityField from "../../../components/QuantityField";

const TableOrdersScreen = (props) => {
    const {match: {params}} = props;
    const history = useHistory();
    const store = useStore();
    const {id: storeId} = store || {}
    const {table_id} = params || {}
    const [purchaseModalOptions, setPurchaseModalOptions] = useState({visible: false, data: []});
    const {fetched, fetching, response, load} = useApi();
    const tableGetApi = useApi();

    useEffect(() => {
        if (table_id) {
            tableGetApi.load({
                endpoint: `/stores/${storeId}/tables/${table_id}`,
                method: "GET"
            })
        }
    }, [table_id])

    const tableName = (tableGetApi.response.data || {}).name;

    const [basket, setBasket] = useState({});
    const [removeBasket, setRemoveBasket] = useState({})
    const [mode, setMode] = useState("order");

    const pushToTableEdit = () => {
    }

    const reload = () => {
        setRemoveBasket({})
        setBasket({})
        load({
            endpoint: `/stores/${storeId}/tables/${table_id}/orders`,
            method: "GET"
        })
    }

    const {load: loadCancelProducts} = useApi({
        onSuccess: reload

    });

    const onCancelProducts = () => {
        const canceledProducts = JSONToArray(removeBasket, "orderProductId").map(i => ({
            orderProductId: i.orderProductId,
            quantity: i.quantity
        }))

        loadCancelProducts({
            endpoint: `/stores/${storeId}/tables/${table_id}/orders`,
            method: "DELETE",
            params: {
                canceledProducts
            }
        })
    }

    const getCancelModalContent = () => {
        const canceledProducts = JSONToArray(removeBasket, "orderProductId")
        return (
            <div style={{maxHeight: 300}}>
                <div>
                    <PostCard
                        style={{margin: 16}}
                        childrenContainerStyle={{
                            ...appStyles.defaultShadow,
                            ...appStyles.card,
                            marginHorizontal: 0,
                            paddingHorizontal: 16,
                            backgroundColor: 'white'
                        }}
                        title={"Silinecek Ürünler"}>
                        {
                            (canceledProducts || []).map((orderProduct, ppindex) => {
                                const {product, quantity} = orderProduct
                                const {name, image, price} = product;
                                return (
                                    <ListItem
                                        title={name}
                                        subtitle={price + "₺"}
                                        titleStyle={{fontWeight: 'bold'}}
                                        avatar={<AttachmentImage id={(image || {}).id} size={40}/>}
                                        lastItem
                                        description={
                                            <div style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end'
                                            }}>
                                                <Show condition={quantity}>
                                                    <CancelQuantity count={quantity} style={{marginRight: 4}}/>
                                                </Show>
                                            </div>
                                        }
                                    />
                                )
                            })
                        }
                    </PostCard>
                </div>
            </div>
        )
    }

    const showCancelPrompt = () => {
        Modal.confirm({
            title: "Aşağıdaki ürünleri çıkarmak istediğinize emin misiniz?",
            content: getCancelModalContent(),
            onOk: onCancelProducts
        })
    }

    useEffect(() => {
        setBasket({})
        setRemoveBasket({})
        reload()
    }, [storeId, table_id])

    const pushToNewOrder = () => {
        history.push(`/tables/${table_id}/new`)
    }


    const onBasketChange = (orderProduct, value) => {
        const {id: orderProductId} = orderProduct

        setBasket(oldData => {
            const newQuantity = value
            if (newQuantity < 0) return oldData;
            return {
                ...oldData,
                [orderProductId]: {
                    ...orderProduct,
                    quantity: newQuantity
                }
            }
        })
    }

    const onRemoveBasketChange = (orderProduct, value) => {
        const {id: orderProductId} = orderProduct
        setRemoveBasket(oldData => {
            const newQuantity = value
            if (newQuantity < 0) return oldData;
            return {
                ...oldData,
                [orderProductId]: {
                    ...orderProduct,
                    quantity: newQuantity
                }
            }
        })
    }

    const isMinusDisabled = (orderProductId) => {
        return (basket[orderProductId] || {}).quantity === 0
    }

    const isPlusDisabled = (orderProduct) => {
        const {id: orderProductId, activeOrderQuantity} = orderProduct;
        return (basket[orderProductId] || {}).quantity === activeOrderQuantity
    }

    const isRemoveMinusDisabled = (orderProductId) => {
        return (removeBasket[orderProductId] || {}).quantity === 0
    }

    const isRemovePlusDisabled = (orderProduct) => {
        const {id: orderProductId, activeOrderQuantity} = orderProduct;
        return (removeBasket[orderProductId] || {}).quantity === activeOrderQuantity
    }

    const pushToQRScan = () => {
        const _data = JSONToArray(basket, 'orderProductId').filter(i => i.quantity > 0)
        setPurchaseModalOptions({
            visible: true,
            data: _data
        })
    }

    const changeModeTo = (newMode) => {
        setMode(newMode)
    }

    const onPurchase = (response) => {
        setPurchaseModalOptions({visible: false, data: []})
        history.push(`/tables/${table_id}/${response.data.id}`)
    }

    const data = response.data || [];
    const hasData = data.length;
    const hasProductData = JSONToArray(basket, 'orderProductId').filter(i => i.quantity > 0).length;
    const hasRemoveBasketData = JSONToArray(removeBasket, 'orderProductId').filter(i => i.quantity > 0).length;

    const onSelectAll = () => {
        data.forEach(order => {
            order.orderProducts.forEach(orderProduct => {
                if (mode === "check") {
                    onBasketChange(orderProduct, orderProduct.activeOrderQuantity)
                } else if (mode === "cancel") {
                    onRemoveBasketChange(orderProduct, orderProduct.activeOrderQuantity)
                }
            })
        })
    }

    const onDeselectAll = () => {
        if (mode === "check") {
            setBasket({})
        } else if (mode === "cancel") {
            setRemoveBasket({})
        }
    }

    const getSelectedOrderProductsPrice = () => {
        if (mode === "check") {
            const selectedOrderProducts = JSONToArray(basket, "orderProductId")
            return selectedOrderProducts.map(i => i.restPrice * i.quantity)
        } else if (mode === "cancel") {
            const selectedOrderProducts = JSONToArray(removeBasket, "orderProductId")
            return selectedOrderProducts.map(i => i.restPrice * i.quantity)
        }
    }

    const onPurchaseModalClose = () => {
        setPurchaseModalOptions({visible: false, data: []})
    }

    const onPaid = () => {
        reload()
        onPurchaseModalClose()
    }

    return (
        <div style={{flex: 1}}>
            <PurchaseModal data={purchaseModalOptions.data}
                           visible={purchaseModalOptions.visible}
                           tableId={table_id}
                           onPurchase={onPurchase}
                           onPaid={onPaid}
                           onClose={onPurchaseModalClose}/>

            <PageHeader title={`${tableName || ""} Siparişler`} extra={
                <div style={{...appStyles.spreadHorizontally}}>
                    <Button type={mode !== "order" ? "tag" : "primary"}
                            style={{flex: 1, marginRight: 4}}
                            icon={<PlusOutlined
                                style={{color: mode !== "order" ? constants.mainColor : "white"}}
                            />}
                            title={""}
                            onClick={() => changeModeTo("order")}
                    >
                        Sipariş Al
                    </Button>

                    <Show condition={hasData}>
                        <Button type={mode !== "cancel" ? "tag" : "primary"}
                                style={{flex: 1, marginRight: 4}}
                                icon={<DeleteOutlined
                                    style={{color: mode !== "cancel" ? constants.mainColor : "white"}}/>}

                                onClick={() => changeModeTo("cancel")}
                        >
                            Ürün Çıkar
                        </Button>
                    </Show>

                    <Show condition={hasData}>
                        <Button type={mode !== "check" ? "tag" : "primary"}
                                style={{flex: 1, marginLeft: 4}}
                                icon={<ScanOutlined
                                    style={{color: mode !== "check" ? constants.mainColor : "white"}}
                                />}

                                onClick={() => changeModeTo("check")}
                        >
                            Hesap Öde
                        </Button>
                    </Show>

                </div>

            }/>

            <Show condition={hasData}>
                <div style={{
                    backgroundColor: '#eee',
                    padding: 16,
                    ...appStyles.defaultShadow,
                    ...appStyles.spreadHorizontally,
                    maxHeight: 100
                }}>
                    <div style={{
                        justifyContent: 'space-around',
                        height: mode === "check" || mode === "cancel" ? '100%' : undefined,
                        alignItems: 'flex-start'
                    }}>
                        <p style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            margin: 0
                        }}>
                            Toplam Hesap: {sum(data.map(order => order.restPrice)).toFixed(2)}₺
                        </p>
                        <Show condition={mode === "check" || mode === "cancel"}>
                            <p style={{
                                fontWeight: 'bold',
                                marginTop: 8,
                                marginBottom: 0
                            }}>
                                Seçili Hesap: {sum(getSelectedOrderProductsPrice()).toFixed(2)}₺
                            </p>
                        </Show>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <Show condition={mode === "check" || mode === "cancel"}>
                            <div>
                                <Button type="primary" onClick={onSelectAll} style={{marginBottom: 8}}>Tümünü
                                    Seç</Button>
                            </div>
                        </Show>
                        <Show condition={mode === "check" || mode === "cancel"}>
                            <div>
                                <Button type="primary" onClick={onDeselectAll}>Tümünü Bırak</Button>
                            </div>
                        </Show>
                    </div>
                </div>
            </Show>

            <Show condition={mode === "check"}>
                <Button disabled={!hasProductData}
                        type="primary"
                        icon={<ScanOutlined style={{color: 'white', fontSize: 32}}/>}
                        style={{
                            borderRadius: 32,
                            width: 64,
                            height: 64,
                            position: 'fixed',
                            bottom: 64,
                            right: 16,
                            zIndex: 3,
                        }}
                        onClick={() => pushToQRScan()}
                />
            </Show>

            <Show condition={mode === "order"}>
                <Button type="primary"
                        icon={<PlusOutlined style={{color: 'white', fontSize: 32}}/>}
                        style={{
                            borderRadius: 32,
                            width: 64,
                            height: 64,
                            position: 'fixed',
                            bottom: 64,
                            right: 16,
                            zIndex: 3,
                        }}
                        onClick={() => pushToNewOrder()}
                />
            </Show>

            <Show condition={mode === "cancel"}>
                <Button disabled={!hasRemoveBasketData}
                        type="primary"
                        icon={<DeleteOutlined style={{color: 'white', fontSize: 32}}/>}
                        style={{
                            borderRadius: 32,
                            width: 64,
                            height: 64,
                            position: 'fixed',
                            bottom: 64,
                            right: 16,
                            zIndex: 3,
                        }}
                        onClick={() => showCancelPrompt()}
                />
            </Show>

            <div>
                <div style={{flex: 1, padding: 16, paddingBottom: 80}}>
                    {
                        !fetched && fetching ?
                            <PostCardShimmer isLoading style={{marginTop: 8}}/>
                            :
                            hasData ?
                                data.map((order, index) => {
                                        return (
                                            <div key={index}>
                                                <PostCard
                                                    key={index}
                                                    avatar={<AttachmentImage id={(order.seller.image || {}).id} size={40}/>}
                                                    description={<DateDescription date={order.createdDate}/>}
                                                    title={order.seller.fullName}
                                                    descriptionFlex={2}>
                                                    {
                                                        (order.orderProducts || []).map((orderProduct, ppindex) => {
                                                            const basketItem = basket[orderProduct.id] || {}
                                                            const removeBasketItem = removeBasket[orderProduct.id] || {}
                                                            return (
                                                                <OrderProductListItem
                                                                    key={ppindex}
                                                                    index={ppindex + index}
                                                                    orderProduct={{
                                                                        ...orderProduct,
                                                                    }}
                                                                    description={
                                                                        mode === "check" ?
                                                                            <QuantityField
                                                                                size={32}
                                                                                minusDisabled={isMinusDisabled(orderProduct.id)}
                                                                                plusDisabled={isPlusDisabled(orderProduct)}
                                                                                onChange={(value) => onBasketChange(orderProduct, value)}
                                                                                total={orderProduct.activeOrderQuantity}
                                                                                value={basketItem.quantity || 0}/>
                                                                            : mode === "cancel" ?
                                                                            <QuantityField
                                                                                size={32}
                                                                                minusDisabled={isRemoveMinusDisabled(orderProduct.id)}
                                                                                plusDisabled={isRemovePlusDisabled(orderProduct)}
                                                                                onChange={(value) => onRemoveBasketChange(orderProduct, value)}
                                                                                value={removeBasketItem.quantity || 0}
                                                                                total={orderProduct.activeOrderQuantity}
                                                                            />
                                                                            : null
                                                                    }
                                                                />
                                                            )
                                                        })
                                                    }
                                                </PostCard>
                                            </div>
                                        )
                                    }
                                )
                                :
                                <EmptyResult
                                    icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                    title={"Sipariş bulunmamaktadır."}
                                />
                    }
                </div>
            </div>
        </div>
    );
}

export default TableOrdersScreen;
