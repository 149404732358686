import React from 'react';
import {Button} from "antd";
import {LogoutOutlined} from '@ant-design/icons'
import useLocalStorage from "../../hooks/useLocalStorage";
import useHistory from "../../hooks/useHistory";
import useCompany from "../../hooks/useCompany";
import useStore from "../../hooks/useStore";
import useAuth from "../../hooks/useAuth";

const LogoutButton = (props) => {
    const {style, className} = props;
    const history = useHistory();
    const {id: companyId, logout: logoutCompany} = useCompany()
    const {id: storeId, logout: logoutStore} = useStore()
    const {logout} = useAuth()
    const {setItem: setLocalCompany} = useLocalStorage("company");
    const {setItem: setLocalStore} = useLocalStorage("store");

    const onLogout = () => {
        if (storeId) {
            setLocalStore({})
            logoutStore()
            setLocalCompany({})
            logoutCompany()
            history.push("/")
        } else if (companyId) {
            setLocalCompany({})
            logoutCompany()
            history.push("/")
        } else {
            logout()
            history.push("/sign")
        }
    }

    return (
        <Button icon={<LogoutOutlined style={{fontSize: 16}}/>}
                type="link"
                className={className}
                style={{paddingLeft: 0, ...(style || {})}}
                onClick={onLogout}>
            Çıkış Yap
        </Button>
    )
}

export default LogoutButton
