import {useCallback, useContext} from 'react';
import {StoreContext} from "../store";
import useLocalStorage from "./useLocalStorage";

const useCompany = () => {
    const [state, dispatch] = useContext(StoreContext);
    const {setItem: setLocalCompany} = useLocalStorage("company");
    const {setItem: setLocalStore} = useLocalStorage("store");

    const {auth: company} = state || {};

    const setCompany = useCallback((data) => {
        dispatch({type: 'set-company', payload: {data}})
        setLocalCompany(data);
        dispatch({type: 'deactivate-store', payload: {}})
        setLocalStore({})
    }, [dispatch, setLocalCompany, setLocalStore])

    const logout = useCallback((data) => {
        dispatch({type: 'deactivate-company'})
        setLocalCompany({})
        dispatch({type: 'deactivate-store'})
        setLocalStore({})
    }, [dispatch, setLocalCompany, setLocalStore])

    return { logout, setCompany}
}

export default useCompany;
