import React, {useEffect} from 'react';
import Button from "../../../components/Button";
import useApi from "../../../hooks/useApi";
import useHistory from "../../../hooks/useHistory";
import {Form, Input, Modal, PageHeader} from "antd";
import Show from "../../../components/DisplayConditions/Show";
import Shimmer from "../../../components/Shimmer";
import PostCard from "../../../components/PostCard";
import {FormLayout} from "../../../utils/constants";
import {StarFilled} from '@ant-design/icons'
import useAuth from "../../../hooks/useAuth";

const CouponGroupEditScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const {coupon_group_id} = params || {}
    const company = useAuth();

    const couponGroupGetApi = useApi()

    const couponGroupDeleteApi = useApi({onSuccess: history.goBack});

    const couponGroupUpdateApi = useApi()

    useEffect(() => {
        if (coupon_group_id) {
            couponGroupGetApi.load({
                endpoint: `/coupongroups/${coupon_group_id}`,
                method: "GET"
            })
        }
    }, [coupon_group_id])

    const onSave = (data) => {
        couponGroupUpdateApi.load({
            endpoint: `/coupongroups`,
            method: "POST",
            params: data,
            onSuccess: history.goBack
        })
    }

    const onDeletePressed = () => {
        const {productsCount} = couponGroupGetApi.response.data || {}

        Modal.confirm({
            title: ` Silmek istediğinize emin misiniz?`,
            content: productsCount && `Kategorinin altındaki ${productsCount} tane ürün "Kategorisiz" grubunun altına taşınacak. Silmek istediğinize emin misiniz?`,
            onOk: onDelete,
        })
    }

    const onDelete = () => {
        couponGroupDeleteApi.load({
            endpoint: `/coupongroups/${coupon_group_id}`,
            method: "DELETE",
        })
    }

    const isLoading = coupon_group_id && company && !couponGroupGetApi.fetched

    return (
        <div style={{margin: 16}}>
            <Show condition={isLoading}>
                <Shimmer/>
            </Show>
            <Show condition={!isLoading}>
                <PageHeader title="Kupon Grup Form" onBack={history.goBack}/>
                <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                    <CouponGroupForm initialValues={couponGroupGetApi.response.data}
                                     onSave={onSave}
                                     onDeletePressed={onDeletePressed}
                                     coupon_group_id={coupon_group_id}/>
                </PostCard>
            </Show>
        </div>
    )
}

const CouponGroupForm = props => {
    const {initialValues, coupon_group_id, onDeletePressed, onSave} = props;
    return (
        <Form {...FormLayout}
              onFinish={onSave}
              initialValues={initialValues}>
            <Form.Item name="name" label="Kupon Grubu Adı">
                <Input/>
            </Form.Item>
            <Form.Item name="star" label="Miktar">
                <Input addonBefore={<StarFilled style={{color: 'orange'}}/>}/>
            </Form.Item>
            <Button type={'primary'}
                    htmlType="submit">
                Kaydet
            </Button>
            <Show condition={coupon_group_id}>
                <Button type={'danger'}
                        onClick={onDeletePressed}>
                    Sil
                </Button>
            </Show>
        </Form>
    )
}


export default CouponGroupEditScreen
