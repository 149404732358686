import React, {useEffect} from 'react';
import Button from "../../../components/Button";
import constants from "../../../utils/constants";
import useApi from "../../../hooks/useApi";
import EmptyResult from "../../../components/EmptyResult";
import Header from "../../../components/Header";

import MenuCategoryShimmer from "../../../components/Shimmer";
import StarCount from "../../../components/StarCount";
import ProductGridList from "../../../components/ProductGridList";
import Authorized from "../../../components/Authorized";
import useCompany from "../../../hooks/useCompany";

import {PlusOutlined, ShopOutlined} from '@ant-design/icons'
import {Row} from "antd";
import useAuthorize from "../../../hooks/useAuthorize";
import useStore from "../../../hooks/useStore";
import useAuth from "../../../hooks/useAuth";

const CouponGroupsScreen = props => {
    const {history, match: {params}} = props;
    const {company_id: propsId} = params || {}
    const company = useAuth();
    const {id: stateCompanyId} = company
    const {company: storeCompany} = useStore()
    const stateId = stateCompanyId || (storeCompany || {}).id || {}
    const companyId = propsId || stateId;
    const {canEditCompanyProfile} = useAuthorize();

    const {fetched, load, firstTimeFetched, response} = useApi()

    useEffect(() => {
        if (companyId)
            load({
                endpoint: `/companies/${companyId}/coupongroups`,
            })
    }, [load, companyId])

    const onCouponGroupAdd = (couponGroup) => {
        if (couponGroup) {
            history.push(`/menu/coupon-groups/${couponGroup.id}/edit`)
        } else {
            history.push(`/menu/coupon-groups/new`)
        }
    }

    const onMenuAdd = ({id: couponGroupId}) => {
        if (couponGroupId) {
            history.push(`/menu/coupon-groups/${couponGroupId}/products/new`)
        }
    }

    const pushToCouponGroupMoveScreen = ({id: couponGroupId}) => {
        history.push(`/menu/coupon-groups/${couponGroupId}/move`)
    }

    const hideEmptyCateagoriesFromCustomers = (category) => {
        return canEditCompanyProfile || category.productsCount
    }

    const data = (response.data || []).filter(hideEmptyCateagoriesFromCustomers);

    return (
        <div style={{flex: 1}}>
            <Authorized type="company-profile" params={{company: {...company, ...storeCompany}}}>
                <Button icon={<PlusOutlined style={{color: 'white', fontSize: 32}}/>}
                        shape="circle"
                        style={{
                            borderRadius: 32,
                            width: 64,
                            height: 64,
                            backgroundColor: constants.mainColor,
                            position: 'fixed',
                            bottom: 64,
                            right: 16,
                            zIndex: 3,
                        }}
                        onClick={() => onCouponGroupAdd()}
                />
            </Authorized>
            <div>
                <div style={{marginTop: 16, marginHorizontal: 16, paddingBottom: 80}}>
                    {
                        !firstTimeFetched ?
                            <MenuCategoryShimmer isLoading={true}/>
                            : !data.length ?
                            <EmptyResult icon={<ShopOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                         title={"Henüz kupon grubunuz bulunmamaktadır.\n Kupon grubu eklemek için sağ alttaki \"+\" işaretine dokunun!"}
                            /> :
                            data.map((item, index) => {
                                const {id, name, star} = item;
                                return (
                                    <div key={index} style={{flex: 1}}>
                                        <Header titleRenderer={
                                            <Header title={name}
                                                    titleStyle={{width: '100%', flex: undefined}}
                                                    extra={<StarCount quantity={star}/>}
                                            />
                                        }
                                                extra={
                                                    <Authorized type="company-profile" params={{company: {...company, ...storeCompany}}}>
                                                        <Row>
                                                            <Button type="link"
                                                                    onClick={() => onMenuAdd(item)}>
                                                                Yeni
                                                            </Button>
                                                            {
                                                                id === ({...company, ...storeCompany}).unCategorizedCategoryId ?
                                                                    null :
                                                                    <Button type="link"
                                                                            onClick={() => onCouponGroupAdd(item)}>
                                                                        Düzenle
                                                                    </Button>
                                                            }
                                                            <Button type="link"
                                                                    onClick={() => pushToCouponGroupMoveScreen(item)}>
                                                                Ekle
                                                            </Button>
                                                        </Row>
                                                    </Authorized>
                                                }
                                        />
                                        <ProductGridList parentId={id}
                                                         type="coupon-group"
                                                         history={history}
                                                         refresh={fetched}
                                                         company={{...company, ...storeCompany}}
                                        />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>

        </div>
    )
}

export default CouponGroupsScreen
