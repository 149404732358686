import React from 'react';
import AttachmentImage from "../AttachmentImage";
import ListItem from "../ListItem";
import appStyles from "../../utils/styles";
import Show from "../DisplayConditions/Show";
import {Button} from "antd";
import {CheckCircleOutlined, DeleteOutlined, ShoppingCartOutlined, StarFilled} from '@ant-design/icons'
import {getFirstLetters} from "../../utils/functions";

export const OrderProductListItem = props => {
    const {orderProduct, removable, description, children} = props;
    const {product, activeOrderQuantity, paidQuantity, canceledQuantity} = orderProduct || {}
    const {name, price, image} = product || {};

    return (
        <>
            <ListItem
                title={name}
                subtitle={price + "₺"}
                titleStyle={{fontWeight: 'bold'}}
                avatar={<AttachmentImage id={(image || {}).id} size={40} placeholder={getFirstLetters(product.name)}/>}
                lastItem
                description={
                    description ?
                        description :
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <Show condition={paidQuantity}>
                                <PaidQuantity count={paidQuantity} style={{marginRight: 4}}/>
                            </Show>
                            <Show condition={canceledQuantity}>
                                <CancelQuantity count={canceledQuantity} style={{marginRight: 4}}/>
                            </Show>
                            <Show condition={activeOrderQuantity > 0}>
                                <PurchasedProductQuantity count={activeOrderQuantity}/>
                            </Show>
                        </div>
                }
            >
                {children}
            </ListItem>
            <Show condition={removable}>
                <div style={{...appStyles.center}}>
                    <Button type="danger" title={"sil"} style={{marginLeft: 8, width: 100}}/>
                </div>
            </Show>
        </>
    )
}

export const PaidQuantity = props => {
    const {count, size: _size, ...rest} = props;
    const size = _size || 20
    return (
        <DescriptionIcon icon={
            <CheckCircleOutlined style={{fontSize: size, color: "orange"}}
            />
        } description={`${count} adet`}
                         {...rest}
        />
    )
}

export const CancelQuantity = props => {
    const {count, size: _size, ...rest} = props;
    const size = _size || 20
    return (
        <DescriptionIcon icon={
            <DeleteOutlined style={{color: 'orange', fontSize: size}}/>
        } description={`${count} adet`}
                         {...rest}
        />
    )
}


const PurchaseProductListItem = props => {
    const {purchaseProduct, hideQuantity} = props;
    const {product, couponQuantity, purchaseQuantity} = purchaseProduct || {}
    const {name, price, image} = product || {};

    return (
        <ListItem
            title={name}
            subtitle={price + "₺"}
            titleStyle={{fontWeight: 'bold'}}
            avatar={<AttachmentImage id={(image || {}).id} size={40} placeholder={getFirstLetters(product.name)}/>}
            lastItem
            description={
                <div style={{...appStyles.row, alignItems: 'center', justifyContent: 'flex-end'}}>
                    {
                        couponQuantity ?
                            <UsedCouponQuantity count={couponQuantity} style={{marginRight: 4}}/>
                            : null
                    }
                    <Show condition={!hideQuantity}>
                        <PurchasedProductQuantity count={purchaseQuantity}/>
                    </Show>
                </div>
            }
        />
    )
}


export const UsedCouponQuantity = props => {
    const {count, size: _size, ...rest} = props;
    const size = _size || 20
    return (
        <DescriptionIcon icon={<StarFilled style={{marginBottom: 2, fontSize: size, color: 'orange'}}
        />}
                         description={`${count} adet`}
                         {...rest}
        />
    )
}

export const PurchasedProductQuantity = props => {
    const {count, size: _size, ...rest} = props;
    const size = _size || 24
    return (
        <DescriptionIcon icon={<ShoppingCartOutlined style={{fontSize: size, color: 'orange', marginBottom: -2}}/>}
                         description={`${count} adet`}
                         {...rest}
        />
    )
}

export const DescriptionIcon = props => {
    const {style, titleStyle, icon, description} = props;
    return (
        <div className="center" style={{
            flexDirection: 'column',
            ...(style || {})
        }}>
            {icon}
            <div style={{
                paddingHorizontal: 8,
                paddingVertical: 2,
                marginTop: 4,
                backgroundColor: '#eee',
                borderRadius: 10
            }}>
                <p style={{
                    margin: 0,
                    whiteSpace: 'nowrap',
                    fontSize: 12,
                    padding: '2px 4px',
                    color: '#4285F4',
                    fontWeight: 'bold', ...(titleStyle || {})
                }}>{description}</p>
            </div>
        </div>
    )
}

export default PurchaseProductListItem;
