import React, {useEffect} from 'react';
import useApi from "../../hooks/useApi";
import Button from "../../components/Button";
import useStore from "../../hooks/useStore";
import Shimmer from "../../components/Shimmer";
import appStyles from "../../utils/styles";
import constants from "../../utils/constants";
import EmptyResult from "../../components/EmptyResult";
import Tag from "../../components/Tag";
import {AppstoreOutlined, PlusOutlined} from '@ant-design/icons'
import useHistory from "../../hooks/useHistory";
import {Col, Row} from "antd";

const TablesScreen = (props) => {
    const store = useStore();
    const history = useHistory();
    const {fetched, fetching, response, firstTimeFetched, load} = useApi();

    const onTableAdd = (table) => {
        if (table) {
            history.push(`/tables/${table.id}/edit`)
        } else {
            history.push(`/tables/new`)
        }
    }

    const pushToOrders = (table) => {
        if (table) {
            history.push(`/tables/${table.id}`)
        }
    }

    const onClickNewOrder = (table) => {
        if (table) {
            history.push(`/tables/${table.id}/new`)
        }
    }

    const reload = () => {
        load({
            endpoint: `/stores/${store.id}/tables`,
            method: "GET"
        })
    }

    useEffect(() => {
        reload()
    }, [store.id])

    const data = response.data || []
    const hasData = data.length

    return (
        <div style={{flex: 1, margin: 16}}>
            <Button type="primary"
                    icon={<PlusOutlined style={{color: 'white', fontSize: 32}}/>}
                    shape="circle"
                    style={{
                        borderRadius: 32,
                        width: 64,
                        height: 64,
                        position: 'fixed',
                        bottom: 64,
                        right: 16,
                        zIndex: 3,
                    }}
                    onClick={() => onTableAdd()}
            />
            <div>
                <div style={{marginTop: 8, marginHorizontal: 8, paddingBottom: 80}}>
                    {
                        !fetched && fetching && !firstTimeFetched?
                            <Row gutter={8}>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{marginTop: 8}}>
                                    <Shimmer style={{width: '100%'}}/>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{marginTop: 8}}>
                                    <Shimmer style={{width: '100%'}}/>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{marginTop: 8}}>
                                    <Shimmer style={{width: '100%'}}/>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{marginTop: 8}}>
                                    <Shimmer style={{width: '100%'}}/>
                                </Col>
                            </Row>
                            :
                            hasData ?
                                <Row gutter={8}>
                                    <TableList items={data} onClickView={pushToOrders} onClickNewOrder={onClickNewOrder}
                                               onClickEdit={onTableAdd}/>
                                </Row>
                                :

                                <EmptyResult
                                    icon={<AppstoreOutlined style={{color: constants.mainColor, fontSize: 48}}/>}
                                    title={"Henüz masanız bulunmamaktadır.\n Masa eklemek için sağ alttaki \"+\" işaretine dokunun!"}
                                />
                    }
                </div>
            </div>
        </div>
    );
}

const TableList = props => {
    const {items, onClickView, onClickNewOrder, onClickEdit} = props;
    return (
        <div style={{...appStyles.grid, flex: 1}}>
            {
                items.map((item, index) => <TableRenderer table={item} key={index} index={index}
                                                          onClickView={onClickView}
                                                          onClickNewOrder={onClickNewOrder}
                                                          onClickEdit={onClickEdit}/>)
            }
        </div>
    )
}

const TableRenderer = props => {
    const {table, onClickView, onClickNewOrder, onClickEdit} = props;
    const {name, isActive} = table;
    return (
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{marginTop: 8}}>
            <div style={{...appStyles.card, ...appStyles.defaultShadow}}>
                <div style={{...appStyles.center, flex: 1, backgroundColor: '#eee', height: 100}}>
                    <p>{name}</p>
                </div>
                <div style={{...appStyles.row, ...appStyles.center}}>
                    <Button type="link" onClick={() => onClickEdit(table)}>
                        Düzenle
                    </Button>
                    <Button type="link" onClick={() => onClickView(table)}>
                        Siparişler
                    </Button>
                    <Button type="link" onClick={() => onClickNewOrder(table)}>
                        Yeni Sipariş
                    </Button>
                </div>
                <div style={{padding: 8}}>
                    <Tag color={isActive ? "red" : "green"} description={isActive ? "Dolu" : "Musait"}/>
                </div>
            </div>
        </Col>
    )
}

export default TablesScreen;
