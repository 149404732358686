import React, {useEffect} from 'react';
import {Button, Col, Row} from "antd";
import UserCard from "../../../components/UserCard";
import Header from "../../../components/Header";
import useApi from "../../../hooks/useApi";
import Authorized from "../../../components/Authorized";
import Companies from "./Companies";
import useHistory from "../../../hooks/useHistory";
import Shimmer from "../../../components/Shimmer";
import {CreditCardOutlined} from '@ant-design/icons'
import constants from "../../../utils/constants";
import PostCard from "../../../components/PostCard";
import appStyles from "../../../utils/styles";
import ListItem from "../../../components/ListItem";

const Profile = (props) => {
    const {username} = props;
    const {load, response, firstTimeFetched} = useApi({
        endpoint: `/users/${username}`,
    });

    const history = useHistory();

    useEffect(() => {
        load()
    }, [username, load])

    const pushToEditProfile = () => {
        history.push("/profile/edit")
    }

    const pushToCreditCardEdit = () => {
        history.push("/profile/credit-cards/new")
    }

    return (
        <div style={{flex: 1}}>
            <Row gutter={8}>
                <Col xs={24} sm={24} md={24}>
                    <Header title="Profil" extra={
                        <Authorized type="customer-profile" params={{username}}>
                            <Button type="link" onClick={pushToEditProfile}>Düzenle</Button>
                        </Authorized>
                    }/>
                    <ProfileCard user={response.data} isLoading={!firstTimeFetched}/>
                    {/*<Authorized type="customer-profile" params={{username}}>*/}
                    {/*    <Header title="Kartlarım" extra={*/}
                    {/*        <Button type="link" onClick={pushToCreditCardEdit}>Yeni</Button>*/}
                    {/*    }/>*/}
                    {/*    <UserCreditCards/>*/}
                    {/*</Authorized>*/}
                </Col>
            </Row>
        </div>
    )
}

const UserCreditCards = props => {
    const {response, fetched, load} = useApi();

    useEffect(() => {
        load({
            endpoint: '/users/credit-cards'
        })
    }, [load])

    if (!fetched)
        return <div>
            <Shimmer/>
        </div>

    const data = response.data || [];
    const hasData = data.length;
    if (!hasData)
        return (
            <div style={{...appStyles.center, flexDirection: 'column'}}>
                <CreditCardOutlined style={{color: constants.mainColor, fontSize: 64}}/>
                <p style={{marginTop: 16}}>Online ödeme işlemi için kart tanımlamanız gerekmektedir.</p>
            </div>
        )
    return (
        <div>
            {
                data.map((card, index) => {
                    return (
                        <CreditCardListItem key={index} card={card}/>
                    )
                })
            }
        </div>
    )
}

export const CreditCardListItem = props => {
    const {card} = props;
    const {cardAlias, binNumber} = card;
    return (
        <PostCard childrenContainerStyle={{padding: "16px 0", marginBottom: 8}}>
            <ListItem title={cardAlias}
                      titleContainerStyle={{margin: 0, padding: 0}}
                      style={{padding: 0}}
                      titleStyle={{margin: 0, fontSize: 18, fontWeight: '500'}}
            />
            <div style={{width: '100%'}}>
                <p style={{margin: 0}}>{binNumber + "**** **** ****"}</p>
            </div>
        </PostCard>
    )
}

const StoreSection = props => {
    const {username} = props;
    return <Companies username={username}/>
}

const ProfileCard = props => {
    const {user, isLoading} = props;
    return <UserCard user={user} isLoading={isLoading}/>


}
export default Profile
