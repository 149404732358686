import React, {useEffect} from 'react';
import Button from "../../components/Button";
import useApi from "../../hooks/useApi";
import useStore from "../../hooks/useStore";
import useHistory from "../../hooks/useHistory";
import Show from "../../components/DisplayConditions/Show";
import Shimmer from "../../components/Shimmer";
import {Form, Input, Modal, PageHeader} from "antd";
import PostCard from "../../components/PostCard";
import {FormLayout} from "../../utils/constants";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const TableEditScreen = (props) => {
    const {match: {params}} = props;
    const store = useStore();
    const history = useHistory();
    const {table_id} = params || {}
    const {id: storeId} = store || {};
    const {load} = useApi({
        onSuccess: history.goBack
    });

    const tableGetApi = useApi();
    const tableDeleteApi = useApi({onSuccess: history.goBack});


    useEffect(() => {
        if (table_id) {
            tableGetApi.load({
                endpoint: `/stores/${storeId}/tables/${table_id}`,
                method: "GET"
            })
        }
    }, [table_id])

    const onSave = (values) => {
        const endpoint = table_id ? `/stores/${storeId}/tables/${table_id}` : `/stores/${storeId}/tables`
        load({
            endpoint,
            method: "POST",
            params: {
                name: values.name
            }
        })
    }

    const onDeletePressed = () => {
        const {orders: _orders} = table_id.response.data || {}
        const orders = _orders || []

        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            title: `Silmek istediğinize emin misiniz?`,
            content: orders && orders.length && `Masanın altındaki ${orders.length} tane sipariş de silinecektir. Silmek istediğinize emin misiniz?`,
            onOk: onDelete,
        });
    }

    const onDelete = () => {
        tableDeleteApi.load({
            endpoint: `/stores/${storeId}/tables/${table_id}`,
            method: "DELETE",
        })
    }

    const isLoading = table_id && storeId && !tableGetApi.fetched

    return (
        <div style={{margin: 16}}>
            <Show condition={isLoading}>
                <Shimmer/>
            </Show>
            <Show condition={!isLoading}>
                <PageHeader title="Masa Form" onBack={history.goBack}/>
                <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                    <TableForm initialValues={tableGetApi.response.data} onSave={onSave}
                               onDeletePressed={onDeletePressed}
                               table_id={table_id}/>
                </PostCard>
            </Show>
        </div>
    );
}

const TableForm = props => {
    const {initialValues, table_id, onDeletePressed, onSave} = props;
    return (
        <Form {...FormLayout}
              onFinish={onSave}
              initialValues={initialValues}>
            <Form.Item name={"name"} label="Masa Adı">
                <Input/>
            </Form.Item>
            <Show condition={table_id}>
                <Button type="danger"
                        onClick={onDeletePressed}>
                    Sil
                </Button>
            </Show>
            <Button type="primary"
                    htmlType="submit">
                Kaydet
            </Button>
        </Form>
    )
}

export default TableEditScreen;
