import React, {useEffect} from 'react';
import useApi from "../../hooks/useApi";
import {Button, Col, Form, Input, Modal, PageHeader, Row} from "antd";
import QuerySelect from "../../components/QuerySelect";
import Authorized from "../../components/Authorized";
import useHistory from "../../hooks/useHistory";
import useStore from "../../hooks/useStore";
import PostCard from "../../components/PostCard";
import {FormLayout} from "../../utils/constants";
import {useForm} from "antd/es/form/Form";
import Show from "../../components/DisplayConditions/Show";
import useAuth from "../../hooks/useAuth";
import Shimmer from "../../components/Shimmer";

const StoreEditScreen = props => {
    const {match: {params}} = props;

    const store = useStore();
    const company = useAuth();
    const {company_id: _company_id} = (params || {});
    const company_id = (company || {}).id || ((store || {}).company || {}).id || _company_id;

    const {id: stateStoreId, isDeleted} = store || {}
    const {store_id: paramsStoreId} = params || {};

    const storeId = paramsStoreId || stateStoreId;

    const history = useHistory();


    const storesGetApi = useApi()
    const {load: storeGetLoad, response} = storesGetApi;

    useEffect(() => {
        if (storeId) {
            storeGetLoad({
                endpoint: `/stores/${storeId}`
            })
        }
    }, [storeId, storeGetLoad])

    const onSuccess = ({data}) => {
        if (!(store || {}).id) {
            store.setStore(data)
            //dispatch({type: 'switch', payload: {type: 'store'}})
        }
        history.goBack()
    }

    const storesApi = useApi({
        method: "POST",
        onSuccess
    })

    const onSave = (values) => {
        storesApi.load({
            endpoint: `/companies/stores`,
            params: {
                id: storeId,
                ...values
            },
        });
    }

    const isLoading = (store || {}).id && !storesGetApi.fetched;
    return (
        <div style={{padding: 16}}>
            <PageHeader title="Profil Düzenle" onBack={history.goBack}/>
            <PostCard childrenContainerStyle={{padding: 16, margin: 0}}>
                <Show condition={!storeId || response.data}>
                    <StoreForm store={response.data} onSave={onSave}/>
                </Show>
                <Show condition={storeId && isLoading}>
                    <Shimmer/>
                </Show>
            </PostCard>
        </div>
    )
}

const StoreForm = props => {
    const {store, onSave} = props;
    const [form] = useForm();
    const history = useHistory();
    const {isDeleted, id: storeId} = store || {}
    const storeDeleteApi = useApi({
        onSuccess: history.goBack
    })

    const storeRecoverApi = useApi({
        onSuccess: ({data}) => {
            if (!(store || {}).id) {
                store.setStore(data)
                //dispatch({type: 'switch', payload: {type: 'store'}})
            }
            history.goBack()
        }
    })

    const onRecover = () => {
        storeRecoverApi.load({
            endpoint: `/stores/${storeId}/recover`,
            method: "POST",
        })
    }

    const showRecoverPrompt = () => {
        Modal.confirm({
            title: "Mağazayı yeniden açmak istediğinize emin misiniz?",
            okText: "Aç",
            onOk: onRecover
        })
    }

    const showPrompt = () => {
        Modal.confirm({
            title: "Mağazayı kapatmak istediğinize emin misiniz?",
            okText: "Kaldır",
            okType: 'danger',
            onOk: onConfirm
        })
    }

    const onConfirm = () => {
        storeDeleteApi.load({
            endpoint: `/stores/${storeId}`,
            method: "DELETE",
        })
    }

    const getDistrictEndpoint = () => {
        const {getFieldValue} = form
        const provinceId = getFieldValue(['address', 'province', 'id'])
        if (provinceId) {
            return `/provinces/${provinceId}/districts`
        }
        return null
    }

    return (
        <Form {...FormLayout}
              form={form}
              initialValues={store}
              onFinish={onSave}>
            <Row gutter={8}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item name={['address', 'latitude']} label='Enlem'>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item name={['address', 'longitude']} label='Boylam'>
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name='phoneNumber' label='Telefon'>
                <Input/>
            </Form.Item>
            <Form.Item name={['address', 'province', 'id']} label="İl">
                <QuerySelect
                    title="İl"
                    valueField="id"
                    descriptionField="name"
                    url={"/provinces"}
                />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {() =>
                    <Form.Item name={['address', 'district', 'id']} label="İlçe">
                        <QuerySelect
                            title="İlçe"
                            valueField="id"
                            descriptionField="name"
                            url={getDistrictEndpoint()}
                        />
                    </Form.Item>
                }
            </Form.Item>
            <Form.Item name={['address', 'description']} label='Adres Tarifi'>
                <Input.TextArea rows={5}/>
            </Form.Item>
            <Button onClick={history.goBack}>
                Vazgeç
            </Button>
            <Authorized type="store-profile" params={{store}}>
                <Button
                    type={"primary"}
                    htmlType='submit'
                    style={{marginVertical: 8}}>
                    {(store || {}).id ? "Güncelle" : "Oluştur"}
                </Button>
            </Authorized>

            <Show condition={storeId}>
                <Authorized type="store-profile" params={{store}}>
                    <Show condition={isDeleted}>
                        <Button type="success"
                                onClick={showRecoverPrompt}>
                            Yeniden Aç
                        </Button>
                    </Show>
                    <Show condition={!isDeleted}>
                        <Button type="danger"
                                onClick={showPrompt}>
                            Kaldır
                        </Button>
                    </Show>
                </Authorized>
            </Show>
        </Form>
    )
}

export default StoreEditScreen
