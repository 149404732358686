import React from 'react';

const Stores = (props) => {
    return (
        <div>
            Stores
        </div>
    );
}

export default Stores;
