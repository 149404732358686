import React from "react";
import useHistory from "../../hooks/useHistory";
import {Button, Form, Input, notification} from "antd";
import PostCard from "../../components/PostCard";
import useApi from "../../hooks/useApi";
import {authEndpoints} from "../../utils/constants";

const ResetPasswordCard = props => {
    const {match: {params}} = props;
    const {activation_key} = params || {}
    const history = useHistory();
    const {fetching, load} = useApi();

    const pushToSignIn = () => {
        history.push("/sign/")
    }

    const onResetPassword = (values) => {
        const {password, passwordConfirm} = values;

        if (password !== passwordConfirm) {
            notification.error({
                duration: 5,
                message: "Şifreler aynı değil."
            })
            return;
        }

        load({
            endpoint: authEndpoints.resetPassword,
            method: "POST",
            params: {
                activationCode: activation_key,
                password,
            },
            onSuccess: pushToSignIn
        })
    }

    return (
        <PostCard style={{
            width: 400,
        }} childrenContainerStyle={{padding: 32}}>
            <h1 style={{margin: 0}}>Yeni Parola</h1>
            <Form onFinish={onResetPassword} style={{marginTop: 16}}>
                <Form.Item label="Şifre" name="password">
                    <Input.Password placeholder="Şifre"/>
                </Form.Item>
                <Form.Item label="Şifre Tekrar"
                           name="passwordConfirm">
                    <Input.Password placeholder="Şifre"/>
                </Form.Item>
                <div style={{marginTop: 16}}>
                    <div className="spread-horizontally">
                        <Button type="link"
                                onClick={pushToSignIn}
                                style={{width: 'calc(50% - 8px)'}}>
                            Geri
                        </Button>
                        <Button type="primary"
                                loading={fetching}
                                htmlType="submit"
                                style={{width: 'calc(50% - 8px)'}}>
                            Parola Güncelle
                        </Button>
                    </div>
                </div>
            </Form>
        </PostCard>
    )
}

export default ResetPasswordCard;
