import React, {useCallback, useEffect} from 'react';
import useApi from "../../../hooks/useApi";
import Loading from "../../../components/Loading";
import constants from "../../../utils/constants";
import useSocket from "../../../hooks/useSocket";
import {notification} from "antd";
import useHistory from "../../../hooks/useHistory";

const PaymentApplyWaitingScreen = props => {
    const {match: {params}} = props;
    const history = useHistory();
    const {purchase_id} = params || {}

    const goBackToQRCodeScreen = useCallback(() => {
        history.push(`/payment`)
    }, [history])

    const onSuccess = useCallback((response) => {
        if ((response.data || {}).purchaseStatus !== constants.PurchaseStatus.Paid) {
            //onError()
            return;
        }

        notification.success({
            title: "Satın alma başarılı!",
            message: "İşleminiz başarıyla gerçekleştirilmiştir!",
            duration: 5
        })
        goBackToQRCodeScreen()
    }, [goBackToQRCodeScreen])

    const {load} = useApi();

    const onError = useCallback((response) => {
        notification.error({
            title: "Hata!",
            message: "Ödemi işlemi sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz!",
            duration: 5
        })
        goBackToQRCodeScreen()
    }, [goBackToQRCodeScreen])

    const loadPaymentStatus = useCallback(() => {
        load({
            endpoint: `/purchases/${purchase_id}/status`,
            onSuccess,
            onError
        })
    }, [load, onError, onSuccess, purchase_id])


    useEffect(() => {
        loadPaymentStatus();
    }, [purchase_id, loadPaymentStatus])

    const onmessage = useCallback((message) => {
        if (message === "deleted") {
            notification.info({
                title: "İptal",
                message: "İşlem iptal edildi.",
                duration: 5
            })
            goBackToQRCodeScreen()
        } else {
            load()
        }
    }, [goBackToQRCodeScreen, load])

    useSocket({onmessage});

    return <div className="center" style={{height: '100%'}}>
        <Loading tip="Yetkili onayı bekleniyor..."/>
    </div>;

}

export default PaymentApplyWaitingScreen
