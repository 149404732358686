import React from "react";
import useAuth from "../../hooks/useAuth";
import useStore from "../../hooks/useStore";
import useApi from "../../hooks/useApi";
import {Button, Form, Input, Modal, notification} from "antd";
import constants, {FormLayout} from "../../utils/constants";

const PurchaseModal = props => {
    const {visible, data, tableId, onClose, onPurchase, onPaid} = props;
    const {username} = useAuth();
    const {id: storeId} = useStore();
    const {load} = useApi();

    const onSend = (values) => {
        const {customerUsername} = values;
        const purchaseProducts = (data || []).map(i => ({
            orderProductId: i.id,
            productId: i.product.id,
            quantity: i.quantity
        }));

        const endpoint = `/stores/${storeId}/tables/${tableId}/purchases/${username}/${customerUsername}`

        load({
            endpoint,
            method: "POST",
            onSuccess: onPurchase,
            onError: onClose,
            params: {
                purchaseProducts
            }
        })
    }

    const onSetPaid = () => {
        const purchaseProducts = (data || []).map(i => ({
            orderProductId: i.id,
            productId: i.product.id,
            quantity: i.quantity
        }));

        const endpoint = `/stores/${storeId}/tables/${tableId}/purchases/${username}/paid`

        load({
            endpoint,
            method: "POST",
            onSuccess: (response) => {
                if (onPaid) {
                    onPaid(response)
                }
                notification.success({
                    duration: 3,
                    message: "Ürünler ödendi olarak kaydedildi!"
                })
            },
            onError: onClose,
            params: {
                purchaseProducts
            }
        })
    }

    return (
        <Modal
            title="Satış"
            centered
            visible={visible}
            bodyStyle={{padding: 0}}
            footer={null}>
            <Form {...FormLayout}
                  onFinish={onSend}>
                <div style={{padding: 24, paddingBottom: 8}}>
                    <Form.Item name="customerUsername"
                               rules={[{required: true}]}
                               label="Kullanıcı adı">
                        <Input placeholder="Müşteri kullanıcı adı..."/>
                    </Form.Item>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    borderTop: '1px solid #eee',
                    padding: 8
                }}>
                    <Button onClick={onClose}>
                        Vazgeç
                    </Button>
                    <Button onClick={onSetPaid}
                            style={{marginLeft: 8, color: constants.successColor}}>
                        Ödendi
                    </Button>
                    <Button type="primary" htmlType="submit" style={{marginLeft: 8}}>
                        Gönder
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default PurchaseModal;
