import React from 'react';
import appStyles from "../../utils/styles";
import AttachmentImage from "../AttachmentImage";
import DescriptionRow from "../DescriptionRow";
import Shimmer from "../Shimmer";
import constants from "../../utils/constants";
import Authorized from "../Authorized";
import {Button, Col, Row} from "antd";
import {getFirstLetters} from "../../utils/functions";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import useHistory from "../../hooks/useHistory";
import useCompany from "../../hooks/useCompany";
import ListItem from "../ListItem";

const CompanyCard = props => {
    const {company, isLoading, style} = props;
    const {image, name, email, phoneNumber, isDeleted} = company || {}
    const {setCompany} = useCompany();
    const {owner} = company || {}
    const {username} = owner || {}
    const {id: stateCompanyId} = useCompany();
    const history = useHistory();


    const activateCompany = () => {
        setCompany(company)
    }

    const pushToEditCompany = ({id}) => {
        history.push(`/companies/${id}/edit`)
    }

    if (!company || !!isLoading)
        return <div style={{...appStyles.card, padding: 16}}><Shimmer/></div>

    const pushToMenu = () => {
        history.push(`/companies/${company.id}/menu`)
    }

    const pushToProfile = () => {
        history.push(`/companies/${company.id}`)
    }

    const shouldShowProfileButton = () => {
        const {location: {pathname}} = history
        const url = `/companies/${company.id}`;
        return stateCompanyId !== company.id && pathname !== url;
    }

    return (
        <Row style={{...appStyles.card, padding: 16, ...(style || {})}}>
            <Col xs={24} sm={24} md={8}>
                <div className="center" style={{height: '100%'}}>
                    <AttachmentImage id={(image || {}).id}
                                     placeholder={getFirstLetters(company.name)}
                                     size={120}
                                     style={{
                                         backgroundColor: '#eee',
                                         overflow: 'hidden',
                                         resizeMode: 'cover',
                                     }}
                    />
                </div>
            </Col>

            <Col xs={24} sm={24} md={16} className="center" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <div style={{marginLeft: 8, width: '100%'}}>
                    <ListItem title={name}
                              titleContainerStyle={{margin: 0, padding: 0}}
                              style={{padding: 0}}
                              titleStyle={{margin: 0, fontSize: 18, fontWeight: '500'}}
                              description={
                                  <Authorized type="customer-profile" params={{username}}>
                                      <Button type="link"
                                              onClick={() => pushToEditCompany(company)}>Düzenle</Button>
                                  </Authorized>
                              }/>
                    <div style={{width: '100%'}}>
                        <DescriptionRow titleSpan={4} title={
                            <MailOutlined/>
                        } style={{marginTop: 8}}
                                        description={email}/>
                        <DescriptionRow titleSpan={4} title={
                            <PhoneOutlined/>
                        } style={{marginTop: 8}}
                                        description={phoneNumber}/>
                    </div>
                    {
                        isDeleted ?
                            <div style={{
                                borderRadius: 10,
                                width: '100%',
                                backgroundColor: constants.mainDangerColor, ...appStyles.center,
                                padding: 12,
                                marginTop: 8
                            }}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'white'}}>KAPALI</p>
                            </div>
                            : null
                    }
                    <div style={{...appStyles.grid, marginTop: 8}}>
                        <Button type="link" onClick={pushToMenu} style={{paddingLeft: 0}}
                                className="grid-item">Menü</Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default CompanyCard
